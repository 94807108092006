import
{
    GET_COMPANY_LOADING,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAILURE
}
from "redux/types";

const initialState = {
    companyLoading: false,
    company: null,
    errors: null
}

export const getCompanyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_COMPANY_LOADING :
            return {...state, companyLoading: action.payload};
        case GET_COMPANY_SUCCESS :
            return {...state, company: action.payload};
        case GET_COMPANY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}