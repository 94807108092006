import
{
    ADD_TRANSACTION_LOADING,
    ADD_TRANSACTION_SUCCESS,
    ADD_TRANSACTION_FAILURE
}   
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const addTransactionAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ADD_TRANSACTION_LOADING, payload: true });
    const response = await createNewItemService(
      `/api/PaymentStatus/AddPaymentTransaction`,
      data,
      "",
      "json"
    );
    dispatch({ type: ADD_TRANSACTION_SUCCESS, payload: response });
  } catch (err) {
    dispatch({ type: ADD_TRANSACTION_FAILURE, payload: err });
  } finally {
    dispatch({ type: ADD_TRANSACTION_LOADING, payload: false });
  }
}