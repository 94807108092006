import { Outlet } from "react-router-dom";
import UserProtectedRoutes from "pages/UserProtectedRoutes/UserProtectedRoutes";

const UserProtectedParent = () => {
  return (
    <UserProtectedRoutes>
      <Outlet />
    </UserProtectedRoutes>
  );
};

export default UserProtectedParent;