import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useParams } from "react-router-dom";
import { JobDetailsAction } from "redux/actions/getJobById/getJobByIdActions";

const useGetJobById = () => {
  const dispatch: any = useDispatch();

  const { Id } = useParams();

  const { jobDetails, jobDetailsLoading } = useSelector(
    (state: RootState) => state.JobDetailsReducer
  );

  useEffect(() => {
    dispatch(JobDetailsAction(Id));
  }, [Id]);

  return {
    jobDetails,
    jobDetailsLoading,
  };
};

export { useGetJobById };
