import
{
    VIEW_APPLICATION_LOADING,
    VIEW_APPLICATION_SUCCESS,
    VIEW_APPLICATION_FAILURE
}
from "redux/types";

const initialState = {
    applicationLoading: false,
    application: null,
    errors: null
}

export const listSingleApplicationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case VIEW_APPLICATION_LOADING :
            return {...state, applicationLoading: action.payload};
        case VIEW_APPLICATION_SUCCESS :
            return {...state, application: action.payload};
        case VIEW_APPLICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}