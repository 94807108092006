import
{
    CREATE_VACANCY_LOADING,
    CREATE_VACANCY_SUCCESS,
    CREATE_VACANCY_FAILURE
}
from "redux/types";

const initialState = {
    createVacancyLoading: false,
    success: null,
    errors: null
}

export const createVacancyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_VACANCY_LOADING :
            return {...state, createVacancyLoading: action.payload};
        case CREATE_VACANCY_SUCCESS :
            return {...state, success: action.payload};
        case CREATE_VACANCY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}