import {
  GET_AREA_SEARCH_LOADING,
  GET_AREA_SEARCH_SUCCESS,
  GET_AREA_SEARCH_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listAreaAction = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_AREA_SEARCH_LOADING, payload: true });
    const response = await listItemsService(
      `api/Areas/GetAreaByCityId`,
      `cityId=${id}`
    );
    dispatch({ type: GET_AREA_SEARCH_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_AREA_SEARCH_FAILURE, payload: err });
  } finally {
    dispatch({ type: GET_AREA_SEARCH_LOADING, payload: false });
  }
};
