import {
  VALIDATE_OTP_RESET_PASSWORD_LOADING,
  VALIDATE_OTP_RESET_PASSWORD_SUCCESS,
  VALIDATE_OTP_RESET_PASSWORD_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const validateOtpResetPasswordActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: VALIDATE_OTP_RESET_PASSWORD_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/validate-otp",
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: "Code is correct",
        });
        callback();
      } else {
        notification.error({
          message: "Something went wrong",
        });
      }

      dispatch({
        type: VALIDATE_OTP_RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
    } catch (err: any) {
      notification.error({
        message: `${err.message}`,
      });
      dispatch({ type: VALIDATE_OTP_RESET_PASSWORD_FAILURE, payload: err });
    } finally {
      dispatch({ type: VALIDATE_OTP_RESET_PASSWORD_LOADING, payload: false });
    }
  };
