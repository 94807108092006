import
{
    REGISTER_USER_INFO_LOADING,
    REGISTER_USER_INFO_SUCCESS,
    REGISTER_USER_INFO_FAILURE
}
from "redux/types";

const initialState = {
    registerLoading: false,
    userData: null,
    errors: null
}

export const registerUserInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REGISTER_USER_INFO_LOADING :
            return {...state, registerLoading: action.payload};
        case REGISTER_USER_INFO_SUCCESS :
            return {...state, userData: action.payload};
        case REGISTER_USER_INFO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}