import
{
    ADD_COMPANY_MEDIA_LOADING,
    ADD_COMPANY_MEDIA_SUCCESS,
    ADD_COMPANY_MEDIA_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const addCompaniesMediaAction = (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: ADD_COMPANY_MEDIA_LOADING, payload: true});
        const response = await createNewItemService(`api/Companies/AddMediaLinks?userId=${data?.userId}&companyId=${data?.companyId}`, data, "", "json");
        dispatch({type: ADD_COMPANY_MEDIA_SUCCESS, payload: response.data});
        callback();
    }
    catch (err) {
        dispatch({type: ADD_COMPANY_MEDIA_FAILURE, payload: err});
    }
    finally {
        dispatch({type: ADD_COMPANY_MEDIA_LOADING, payload: false});
    }
}