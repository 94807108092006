import {
  GET_COMPANY_ID_LOADING,
  GET_COMPANY_ID_SUCCESS,
  GET_COMPANY_ID_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import store from "redux/store";

import { listItemsService } from "services/listItemsService";

export const getCompanyIdFromUserIdAction =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_COMPANY_ID_LOADING, payload: true });
      const response = await listItemsService(
        `api/Companies/GetUCompanyByUserId`,
        `userId=${store.getState()?.signInReducer?.userId}`
      );
      dispatch({ type: GET_COMPANY_ID_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: GET_COMPANY_ID_FAILURE, payload: err });
    } finally {
      dispatch({ type: GET_COMPANY_ID_LOADING, payload: false });
    }
  };
