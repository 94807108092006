import { DropdownIcon, LogoIcon, erroricon } from "assets/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMajorsAction } from "redux/actions/majorsActions/listMajorsAction";
import { RootState } from "redux/rootReducer";
import { addMajorsAction } from "redux/actions/userRegistrationActions/addMajorsAction";
import { Spin } from "antd";

interface UserFieldsOfWorkProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<string>>;
}

const UserFieldsOfWork = ({
  activePop,
  setActivePop,
}: UserFieldsOfWorkProps) => {
  const [selectedMajorIds, setSelectedMajorIds] = useState<number[]>([]);
  const dispatch: any = useDispatch();

  /////////////////////////////////   getting user id
  const { userId } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  /////////////////////////////////   getting the majors
  useEffect(() => {
    dispatch(listMajorsAction());
  }, []);

  const { majorsLoading, majors } = useSelector(
    (state: RootState) => state.listMajorsReducer
  );

  /////////////////////////////////   handlign submit  loading
  const { addMajorsLoading } = useSelector(
    (state: RootState) => state.addMajorsReducer
  );

  /////////////////////////////////   handling form submit
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (selectedMajorIds.length > 0) {
      await dispatch(
        addMajorsAction(
          {
            majorIds: selectedMajorIds,
          },
          () => {
            setActivePop("userSkills");
          }
        )
      );
    }
  };

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            // onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            {/* <img src={CloseIcon} alt="logo" /> */}
          </div>
        </div>

        {/* What's Your Fields Of Work ? */}
        <div className="font-Avenir_bold text-[20px] text-white flex items-center justify-center">
          What's Your Fields Of Work?
        </div>

        {/**************** Fields Of Work section  *****************/}
        <Spin spinning={addMajorsLoading}>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-[15px] text-white"
          >
            {/* fields ****************************/}
            <Spin spinning={majorsLoading}>
              <div className="w-full max-h-[300px] overflow-auto scroll flex flex-col gap-y-3">
                {majors?.map((item: any, i: number) => {
                  return (
                    <div key={i} className="w-full flex flex-col gap-4">
                      <p className="font-Avenir_regular text-[14px]">
                        {item?.Field}
                      </p>

                      {/* all options ************************/}
                      <div className="flex items-center flex-wrap gap-3">
                        {/* single option  */}
                        {item.Position?.map((option: any, i: number) => {
                          return (
                            <div
                              key={i}
                              className={`flex items-center justify-center px-[10px] h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi ${
                                selectedMajorIds?.includes(option?.majorId)
                                  ? "bg-pro_text_light text-pro_text_dark"
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  selectedMajorIds?.includes(option?.majorId)
                                ) {
                                  setSelectedMajorIds(
                                    selectedMajorIds?.filter(
                                      (num: number) => num !== option?.majorId
                                    )
                                  );
                                } else {
                                  setSelectedMajorIds((prev) => [
                                    ...prev,
                                    option?.majorId,
                                  ]);
                                }
                              }}
                            >
                              {option?.majorNameEn}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>{" "}
            </Spin>

            {/* next button *************************************/}

            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Next
            </button>

            {selectedMajorIds.length === 0 && (
              <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                <img className="w-[22px]" src={erroricon} alt="" />
                Please choose at least one fields of work
              </p>
            )}
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserFieldsOfWork;
