import { useFormik } from "formik";
import * as yup from "yup";
import CompanyRegistrationTwoModel from "models/CompanyRegistrationTwoModel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { addCompanyInfoAction } from "redux/actions/companyActions/addCompanyInfoAction";

const useCompanyRegistrationTwoFormik = (setState?: any) => {
    const { userId } = useSelector((state: RootState) => state.googleLoginReducer);
    const { companyId } = useSelector((state: RootState) => state.addSubscriptionPlanReducer);
    const { companyData } = useSelector((state: RootState) => state.getCompanyIdFromUserIdReducer);

    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
          bio: '',
          foundation_year: '',
          number_of_employees: '',
          majorId: 0,
          countryId: 0,
          cityId: 0
        },
        validationSchema: yup.object({
            bio: yup.string().required('please enter this field'),
            foundation_year: yup.string().required('please enter this field'),
            number_of_employees: yup.string().required('please enter this field'),
            majorId: yup.string().required('please enter this field'),
            countryId: yup.string().required('please enter this field'),
            cityId: yup.string().required('please enter this field'),
        }),
        onSubmit: async (values: CompanyRegistrationTwoModel) => {
          await dispatch(addCompanyInfoAction({
            userId: userId,
            companyId: companyId === null ? companyData?.companyId : companyId,
            bio: values.bio,
            foundationYear: values.foundation_year,
            countOfEmployees: values.number_of_employees,
            majorId: values.majorId,
            countryId: values.countryId,
            cityId: values.cityId
          }, () => {
            setState("companyThree");
          }))
        },
      });
  
  return formik;
}

export {
  useCompanyRegistrationTwoFormik
}