import
{
    MAJORS_DROPDOWN_LOADING, 
    MAJORS_DROPDOWN_SUCCESS,
    MAJORS_DROPDOWN_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listMajorsDropdownAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: MAJORS_DROPDOWN_LOADING, payload: true });
        const response = await listItemsService(`api/Majors/GetAllMajors`);
        dispatch({ type: MAJORS_DROPDOWN_SUCCESS, payload: response.data});
      } catch (err) {
        dispatch({ type: MAJORS_DROPDOWN_FAILURE, payload: err });
      } finally {
        dispatch({ type: MAJORS_DROPDOWN_LOADING, payload: false });
      }
}