import {
  LIST_PROFILE_DATA_LOADING,
  LIST_PROFILE_DATA_SUCCESS,
  LIST_PROFILE_DATA_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";
import store from "redux/store";

export const listProfileDataAction =
  (callback?: any, userId?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: LIST_PROFILE_DATA_LOADING, payload: true });
      const responseOne = await listItemsService(
        "api/UserProfiles/GetUserProfileByUserId",
        `userId=${userId ? userId : store.getState()?.signInReducer?.userId}`
      );
      const response = await listItemsService(
        `api/UserProfiles/GetUserProfileBy${responseOne?.data?.userProfileId}Included`,
        ""
      );
      dispatch({ type: LIST_PROFILE_DATA_SUCCESS, payload: response.data });
      callback && callback(response.data);
    } catch (err) {
      dispatch({ type: LIST_PROFILE_DATA_FAILURE, payload: err });
    } finally {
      dispatch({ type: LIST_PROFILE_DATA_LOADING, payload: false });
    }
  };
