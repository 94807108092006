import { CloseIcon, LogoIcon, DefaultUserImage, erroricon } from "assets/icons";
import { Flex, Input, Spin, Typography, notification } from "antd";
import type { OTPProps } from "antd/es/input/OTP";
import type { GetProp } from "antd";
import { useEffect, useState } from "react";
import { useUserPersonalInfoFormik } from "forms/JoinUsForms/SginUpForms/userPersonalInfoFormik";
import { useDispatch, useSelector } from "react-redux";
import { generateEmailOtpActions } from "redux/actions/JoinUsAction/SignUp/generateEmailOtpActions";
import { validateEmailOtpActions } from "redux/actions/JoinUsAction/SignUp/validateEmailOtpActions";
import { RootState } from "~/redux/rootReducer";

interface UserPersonalInfoProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserPersonalInfo = ({
  activePop,
  setActivePop,
}: UserPersonalInfoProps) => {
  const [profileImage, setProfileImage] = useState<any>(null);
  const [disableSendCode, setDisableSendCode] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [showOtpValidation, setShowOtpValidation] = useState(false);
  const [showSendCode, setShowSendCode] = useState(true);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [otpCode, setOtpCode] = useState("");

  ///////////////////////////////// formik
  const formik = useUserPersonalInfoFormik(setActivePop);
  const dispatch: any = useDispatch();

  ///////////////////////////////// count down timer handle
  const [timer, setTimer] = useState(60);

  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    if (showOtpValidation) {
      const interval = setInterval(() => {
        setTimer((prev: any) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showOtpValidation]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  //////////////////////////////  otp values output
  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {
    if (text.length !== 4) {
      //   formik.setFieldError("otp", "please fill this field");
    } else {
      setOtpCode(text);
    }
  };

  const sharedProps: OTPProps = {
    onChange,
  };

  ////////////////////////// handle loading status
  const { addUserPersonalInfoLoading } = useSelector(
    (state: RootState) => state.addUserPersonalInfoReducer
  );

  const { generateEmailOtpLoading } = useSelector(
    (state: RootState) => state.generateEmailOtpReducer
  );

  const { validateEmailOtpLoading } = useSelector(
    (state: RootState) => state.validateEmailOtpReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            // onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            {/* <img src={CloseIcon} alt="logo" /> */}
          </div>
        </div>

        {/* Fill Your Personal Information */}
        <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
          Fill Your Personal Information
        </div>

        {/**************** personal info section  ********************/}
        <form
          onSubmit={formik?.handleSubmit}
          className="max-h-[560px] overflow-y-auto scroll flex flex-col gap-[15px] text-white"
        >
          {/*************** profile image section  *******************/}
          <div className="flex flex-col items-center justify-center gap-[20px] text-white">
            <div className="flex justify-center">
              {!profileImage ? (
                <img src={DefaultUserImage} alt="profileImage" />
              ) : (
                <img
                  className="w-[120px] h-[120px] rounded-full object-cover"
                  src={URL.createObjectURL(profileImage)}
                  alt="profile"
                />
              )}
            </div>
            <div className="w-full flex justify-center font-semibold text-maincolor">
              <input
                type="file"
                id="custom-input"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    if (file?.size > 5 * 1000000) {
                      alert(
                        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${parseInt(
                          file?.size / 1000000 + "",
                          10
                        )}MB only`
                      );
                      setFileError(true);
                      return;
                    }
                    setFileError(false);
                    setProfileImage(file);
                    formik.setFieldValue("image", file);
                  }
                }}
                hidden
              />
              <label
                htmlFor={"custom-input"}
                className="font-Avenir_demi text-[16px] cursor-pointer"
              >
                Upload Profile Image
              </label>
            </div>
            {/* errors section  */}
            <div className="mt-[-10px] w-full flex items-center justify-center">
              {(formik.errors.image || fileError) && (
                <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                  <img className="w-[22px]" src={erroricon} alt="" />{" "}
                  {formik.errors.image ?? "Filesize too large"}
                </p>
              )}
            </div>
          </div>

          {/******************** first Name  **************************/}
          <div className="flex flex-col items-center gap-3">
            <div className="w-full">
              {" "}
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={formik.handleChange}
                className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
              />
            </div>

            {/* errors section  */}
            <div className="mt-[-10px] w-full flex items-center">
              {formik.errors.firstName && (
                <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                  <img className="w-[22px]" src={erroricon} alt="" />{" "}
                  {formik.errors.firstName}
                </p>
              )}
            </div>
          </div>

          {/******************** last name  **************************/}
          <div className="flex flex-col items-center gap-3">
            <div className="w-full">
              {" "}
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={formik.handleChange}
                className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
              />
            </div>

            {/* errors section  */}
            <div className="mt-[-10px] w-full flex items-center">
              {formik.errors.lastName && (
                <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                  <img className="w-[22px]" src={erroricon} alt="" />{" "}
                  {formik.errors.lastName}
                </p>
              )}
            </div>
          </div>

          {/******************** adress  **************************/}
          <div className="flex flex-col items-center gap-3">
            <input
              type="text"
              placeholder="address"
              name="address"
              onChange={formik.handleChange}
              className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
            />

            {/* errors section  */}
            <div className="mt-[-10px] w-full flex items-center">
              {formik.errors.address && (
                <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                  <img className="w-[22px]" src={erroricon} alt="" />{" "}
                  {formik.errors.address}
                </p>
              )}
            </div>
          </div>

          {/******************** Email  **************************/}
          <div className="flex flex-col items-center gap-3">
            <div className="w-full flex items-center gap-3">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                readOnly={emailReadOnly}
              />

              {/* send code button  */}
              {showSendCode && (
                <Spin spinning={generateEmailOtpLoading}>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(
                        generateEmailOtpActions(
                          {
                            email: formik.values.email,
                          },
                          () => {
                            setDisableSendCode(true);
                            setShowOtpValidation(true);
                          }
                        )
                      );
                    }}
                    className={
                      disableSendCode
                        ? "w-[100px] h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[12px] font-Avenir_demi rounded-[15px] flex items-center justify-center "
                        : "w-[100px] h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[12px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300 flex items-center justify-center "
                    }
                    // disabled={disableSendCode}
                  >
                    Send code
                  </button>
                </Spin>
              )}
            </div>

            {/* errors section  */}
            <div className="mt-[-10px] w-full flex items-center">
              {formik.errors.email && (
                <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                  <img className="w-[22px]" src={erroricon} alt="" />{" "}
                  {formik.errors.email}
                </p>
              )}
            </div>
          </div>

          {/******************** otp section  **************************/}
          {showOtpValidation && (
            <div
              data-aos="fade-up"
              data-aos-duration="400"
              className="flex flex-col gap-4"
            >
              <div className="flex items-center justify-center">
                <Flex gap="middle" align="flex-start" vertical>
                  <Input.OTP length={4} {...sharedProps} />
                </Flex>
              </div>

              {/* resend the verification code */}
              <div className="font-Avenir_regular text-[14px] text-white text-center border-t-[1px] pt-[10px]">
                You can resend the verification code after
                <span className="font-Avenir_bold ml-[10px]">
                  {formatTime(timer)}
                </span>
              </div>

              {/* confirm otp button  */}
              <div className="flex items-center justify-center">
                <Spin spinning={validateEmailOtpLoading}>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(
                        validateEmailOtpActions(
                          {
                            email: formik.values.email,
                            otp: otpCode,
                          },
                          () => {
                            setShowOtpValidation(false);
                            setDisableNextButton(false);
                            setShowSendCode(false);
                            setEmailReadOnly(true);
                          }
                        )
                      );
                    }}
                    className="w-[80px] h-[38px] bg-[#DCDEE3] text-pro_text_dark text-[12px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300 flex items-center justify-center "
                  >
                    Confirm
                  </button>
                </Spin>
              </div>

              {/* Resend the code */}
              {timer === 0 && (
                <div
                  onClick={() =>
                    dispatch(
                      generateEmailOtpActions(
                        {
                          email: formik.values.email,
                        },
                        () => {
                          setTimer(60);
                        }
                      )
                    )
                  }
                  className="font-Avenir_bold text-[14px] text-white flex items-center justify-center underline cursor-pointer hover:text-pro_text_dark duration-300"
                >
                  Resend the code
                </div>
              )}
            </div>
          )}

          {/**************** Next button ***********************/}
          <Spin spinning={addUserPersonalInfoLoading}>
            {!disableNextButton && (
              <button
                disabled={disableNextButton}
                type="submit"
                className="w-full min-h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
              >
                Next
              </button>
            )}
          </Spin>
        </form>
      </div>
    </section>
  );
};

export default UserPersonalInfo;
