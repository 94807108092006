import {
  VALIDATE_EMAIL_OTP_LOADING,
  VALIDATE_EMAIL_OTP_SUCCESS,
  VALIDATE_EMAIL_OTP_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const validateEmailOtpActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: VALIDATE_EMAIL_OTP_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/validate-otp-register",
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: "Code is correct!",
        });
        callback();
      } else {
        notification.error({
          message: "Something went wrong",
        });
      }

      dispatch({ type: VALIDATE_EMAIL_OTP_SUCCESS, payload: response });
    } catch (err: any) {
      dispatch({ type: VALIDATE_EMAIL_OTP_FAILURE, payload: err });
      // console.log(err);
      notification.error({
        message: `${
          err.message === undefined ||
          err.message === null ||
          err.message === "" ||
          err === null
            ? "Something went wrong"
            : err.message
        }`,
      });
    } finally {
      dispatch({ type: VALIDATE_EMAIL_OTP_LOADING, payload: false });
    }
  };
