import { notification, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listMajorsAction } from "redux/actions/majorsActions/listMajorsAction";
import {} from "forms/updateUserProfileFormik";
import { erroricon } from "assets/icons/index";

interface FieldsOfWorkProps {
  formik: any;
}

function FieldsOfWork({ formik }: FieldsOfWorkProps) {
  const dispatch: any = useDispatch();

  const { majorsLoading, majors } = useSelector(
    (state: RootState) => state.listMajorsReducer
  );
  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const [positions, setPositions] = useState<any[]>(
    profileData?.user?.userMajors
  );

  useEffect(() => {
    dispatch(listMajorsAction());
    setPositions(profileData?.user?.userMajors);
  }, [profileData]);

  return (
    <Spin className="w-full" spinning={majorsLoading}>
      <div
        data-aos="fade-up"
        data-aos-duration="900"
        className={`w-full bg-white p-[20px] font-Avenir_regular text-[14px] flex flex-col gap-[20px] shadow-[0px_0px_20px_-10px_black] rounded-[15px] gap-y-3 px-5 py-5 text-pro_text_dark
        `}
      >
        {/* errors section  */}
        <div className="mt-[-10px] w-full flex items-center">
          {formik.errors.MajorIds && (
            <p className="text-[#ff0000] font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
              {/* <img className="w-[22px]" src={erroricon} alt="" />{" "} */}
              {formik.errors.MajorIds}
            </p>
          )}
        </div>

        {formik.errors.MajorIds &&
          notification.error({
            message: `${formik.errors.MajorIds}`,
          })}

        <p className="font-Avenir_demi text-[20px]">Fields Of Work</p>

        <div className="w-full max-h-[440px] overflow-auto scroll">
          {majors?.map((item: any, i: number) => {
            return (
              <div key={i} className="w-full flex flex-col items-start gap-y-3">
                <p className="">{item?.Field}</p>
                <div className="w-full flex flex-row items-center flex-wrap gap-y-2 gap-x-3">
                  {item?.Position?.map((position: any, i: number) => {
                    const included = positions?.some(
                      (pos) => pos.majorId === position.majorId
                    );
                    return (
                      <button
                        key={i}
                        type="button"
                        className={`border-2 border-[#142664] px-3 h-[48px] rounded-[15px] ${
                          included ? "bg-pro_text_light" : "bg-white"
                        }`}
                        onClick={() => {
                          const included = positions?.some(
                            (pos) => pos.majorId === position.majorId
                          );
                          if (included) {
                            formik.setFieldValue(
                              "MajorIds",
                              positions?.filter(
                                (val: any) => val.majorId !== position?.majorId
                              )
                            );
                            setPositions(
                              positions?.filter(
                                (val: any) => val.majorId !== position?.majorId
                              )
                            );
                          } else {
                            setPositions((prev) => {
                              formik.setFieldValue("MajorIds", [
                                ...prev,
                                position,
                              ]);
                              return [...prev, position];
                            });
                          }
                        }}
                      >
                        <p className="text-black">{position?.majorNameEn}</p>
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Spin>
  );
}

export default FieldsOfWork;
