import React, { ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface workplaceSearchElements {
  title: string;
  workplaces: {}[];
  formik?: any;
  name?: string;
}

function WorkplaceSearch({
  title,
  workplaces,
  formik,
  name,
}: workplaceSearchElements) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [boxesChecked, setBoxesChecked] = useState<boolean[]>(
    workplaces?.map((item: any) => false)
  );
  const [allChecked, setAllChecked] = useState(false);

  return (
    <div className="py-[15px] w-full bg-white flex flex-col gap-[10px]  text-pro_text_dark">
      {/* header  */}
      <p className="font-Avenir_demi text-[20px] ">{title}</p>
      <form className="flex flex-col gap-[10px]">
        {/* checkboxs  */}
        {workplaces.map((Workplace: any, i: number) => {
          return (
            <div key={i}>
              <label
                className="relative flex items-center"
                htmlFor={Workplace?.name}
              >
                {/* checkbox input  **************************/}
                <input
                  className={`w-[20px] h-[20px] mr-[15px] appearance-none border-2 border-black rounded-[5px]`}
                  name={name}
                  id={Workplace?.name}
                  value={Workplace?.id}
                  checked={boxesChecked.at(i)}
                  type="checkbox"
                  onChange={() => {
                    // setBoxesChecked(workplaces.map((item: any) => false));
                    const newChecked = boxesChecked.map(
                      (item: boolean, idx: number) => (i === idx ? !item : item)
                    );
                    setBoxesChecked(newChecked);

                    if (!boxesChecked?.at(i)) {
                      if (searchParams.get("vacancyWorkPlaceId") === null) {
                        searchParams.set("vacancyWorkPlaceId", Workplace?.id);
                      } else {
                        searchParams.append(
                          "vacancyWorkPlaceId",
                          Workplace?.id
                        );
                      }
                    } else {
                      searchParams.delete("vacancyWorkPlaceId");
                    }

                    setSearchParams(searchParams);
                  }}
                />
                {Workplace?.name}
                <div
                  className={`w-[20px] h-[20px] bg-green-500 absolute top-[8%] left-0 ${
                    boxesChecked.at(i) ? "opacity-100" : "opacity-0"
                  } duration-300 rounded-[15px]`}
                >
                  <img src="images/checkbox.png" alt="selected" />
                </div>
              </label>
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default WorkplaceSearch;
