import Header from "components/Header";
import Footer from "components/Footer";
import Application from "components/Applications/Application";
import { useEffect, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMyApplicationsAction } from "redux/actions/applicationsActions/listMyApplicationsAction";
import { RootState } from "redux/rootReducer";
import { Empty, Spin } from "antd";

function Applications() {
  const dispatch: any = useDispatch();

  const { myApplicationsLoading, myApplications } = useSelector(
    (state: RootState) => state.listMyApplicationsReducer
  );

  const { userId } = useSelector((state: RootState) => state.signInReducer);

  useEffect(() => {
    dispatch(listMyApplicationsAction(userId));
  }, []);

  return (
    <>
      <Header />

      <main className="px-[20px] 2xl:px-[144px] min-h-[70%]">
        {/* the main header section */}
        <section className="pt-[120px]">
          <p className="font-Avenir_demi text-[20px] text-pro_text_dark">
            My Applications ({myApplications?.length})
          </p>
        </section>

        {/* the jobs grid  */}
        <section className="mt-[50px] w-full flex items-center justify-center">
          <Spin
            wrapperClassName="spin-full-width spin-full-height"
            spinning={myApplicationsLoading}
          >
            {myApplications?.length === 0 && (
              <div className="w-full flex items-center justify-center">
                <Empty description="No Applications Yet" />
              </div>
            )}
            <div className="w-full p-[10px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-[30px] justify-items-center">
              {myApplications?.map((item: any, i: number) => {
                return (
                  <Application
                    key={i}
                    vacancyId={item?.vacancyId}
                    applicationStatusId={item?.applicationStatusId}
                    type={item?.vacancy?.vacancyLevelId}
                    title={item?.vacancy?.title}
                    company={item?.vacancy?.company}
                    location={
                      item?.vacancy?.country?.countryNameEn +
                      ", " +
                      item?.vacancy?.city?.cityNameEn
                    }
                    vacancyWorkPlaceId={item?.vacancy?.vacancyWorkPlaceId}
                  />
                );
              })}
            </div>
          </Spin>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Applications;
