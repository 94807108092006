import
{
    UPDATE_APPLICATION_STATUS_LOADING,
    UPDATE_APPLICATION_STATUS_SUCCESS,
    UPDATE_APPLICATION_STATUS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const updateApplicationStatusAction = (id: string, statusId: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_APPLICATION_STATUS_LOADING, payload: true});
        const response = await createNewItemService("api/applications/UpdateApplicationStatus", {}, `?applicationId=${id}&newStatus=${statusId}`);
        dispatch({type: UPDATE_APPLICATION_STATUS_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: UPDATE_APPLICATION_STATUS_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_APPLICATION_STATUS_LOADING, payload: false});
    }
}