import {
  GET_AREA_SEARCH_LOADING,
  GET_AREA_SEARCH_SUCCESS,
  GET_AREA_SEARCH_FAILURE,
} from "redux/types";

const initialState = {
  areasLoading: false,
  areas: null,
  errors: null,
};

export const listAreaSearchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_AREA_SEARCH_LOADING:
      return { ...state, areasLoading: action.payload };
    case GET_AREA_SEARCH_SUCCESS:
      return { ...state, areas: action.payload };
    case GET_AREA_SEARCH_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
