import
{
    ADD_EDUCATION_INFO_LOADING,
    ADD_EDUCATION_INFO_SUCCESS,
    ADD_EDUCATION_INFO_FAILURE
}
from "redux/types";

const initialState = {
    addEducationInfoLoading: false,
    success: null,
    errors: null
};

export const addEducationInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_EDUCATION_INFO_LOADING :
            return {...state, addEducationInfoLoading: action.payload};
        case ADD_EDUCATION_INFO_SUCCESS :
            return {...state, success: action.payload};
        case ADD_EDUCATION_INFO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}