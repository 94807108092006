import
{
    UPDATE_VACANCY_LOADING,
    UPDATE_VACANCY_SUCCESS,
    UPDATE_VACANCY_FAILURE
}
from "redux/types";

const initialState = {
    updateVacancyLoading: false,
    success: null,
    errors: null
}

export const updateVacancyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_VACANCY_LOADING :
            return {...state, updateVacancyLoading: action.payload};
        case UPDATE_VACANCY_SUCCESS :
            return {...state, success: action.payload};
        case UPDATE_VACANCY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}