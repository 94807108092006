import
{
    APPLICATIONS_BY_ID_LOADING,
    APPLICATIONS_BY_ID_SUCCESS,
    APPLICATIONS_BY_ID_FAILURE
}
from "redux/types";

const initialState = {
    applicationsByIdLoading: false,
    applicationsById: null,
    errors: null
}

export const getApplicationsByIdReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case APPLICATIONS_BY_ID_LOADING :
            return {...state, applicationsByIdLoading: action.payload};
        case APPLICATIONS_BY_ID_SUCCESS :
            return {...state, applicationsById: action.payload};
        case APPLICATIONS_BY_ID_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}