import {
  GET_COUNTRIES_SEARCH_LOADING,
  GET_COUNTRIES_SEARCH_SUCCESS,
  GET_COUNTRIES_SEARCH_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listCountriesSearchAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_COUNTRIES_SEARCH_LOADING, payload: true });
    const response = await listItemsService("api/Countries/GetAllCountries");

    dispatch({ type: GET_COUNTRIES_SEARCH_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_COUNTRIES_SEARCH_FAILURE, payload: err });
  } finally {
    dispatch({ type: GET_COUNTRIES_SEARCH_LOADING, payload: false });
  }
};
