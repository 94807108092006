import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { MATCHED_INTERNSHIPS_PATH } from "Routes/paths";
import { signInActions } from "redux/actions/JoinUsAction/SignIn/signInActions";

interface userLoginTypes {
  phone: string;
  password: string;
}

export const useUserLoginFormik = (setActivePop: any) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: yup.object({
      phone: yup.string().required("This field is required"),
      password: yup.string().required("This field is required"),
    }),

    onSubmit: async (values: userLoginTypes) => {
      await dispatch(
        signInActions(
          {
            phoneNumber: values.phone.toString(),
            password: values.password,
          },
          setActivePop,
          () => {
            navigate(`${MATCHED_INTERNSHIPS_PATH}`);
          }
        )
      );
    },
  });

  return formik;
};
