import {
  ADD_USER_PERSONAL_INFO_LOADING,
  ADD_USER_PERSONAL_INFO_SUCCESS,
  ADD_USER_PERSONAL_INFO_FAILURE,
} from "redux/types";

const initialState = {
  addUserPersonalInfoLoading: false,
  addUserPersonalInfo: null,
  errors: null,
};

export const addUserPersonalInfoReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case ADD_USER_PERSONAL_INFO_LOADING:
      return { ...state, addUserPersonalInfoLoading: action.payload };
    case ADD_USER_PERSONAL_INFO_SUCCESS:
      return { ...state, addUserPersonalInfo: action.payload };
    case ADD_USER_PERSONAL_INFO_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
