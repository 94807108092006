import React from "react";

import { Link } from "react-router-dom";
import { LogoBlueIcon } from "assets/icons";

function Footer() {
  return (
    <footer className="mt-[50px] w-full bg-pro_green px-[30px] xl:px-[144px] md:py-[10px] flex flex-col items-center gap-[30px] md:gap-0 md:items-start text-pro_text_dark">
      {/* logo section */}
      <div className="my-[20px]">
        <img className="w-[98px] h-[33px]" src={LogoBlueIcon} alt="logo" />
      </div>

      <div className="w-full flex flex-col items-center justify-center md:justify-between md:flex-row gap-[40px] xl:gap-[200px] pb-[20px] md:px-0 border-[#A597A4] border-b-[1px]">
        {/* left section */}
        <div className="flex items-center">
          <div className=" self-start flex justify-center md:justify-start  md:text-left">
            <p className="w-[270px] font-Avenir_regular text-[16px] text-center md:text-left">
              Explore a world of possibilities with our internship jobs website.
              Connect with dynamic opportunities, gain hands-on experience, and
              propel your career forward.
            </p>
          </div>
        </div>

        {/* middle section */}
        <div className="flex items-center">
          <div className=" self-start text-center md:text-left">
            <p className="font-[700] font-Avenir_demi text-[16px]">
              Important Links
            </p>
            <div>
              <p
                onClick={() => {
                  window.open("https://companies.intrn.app/", "_blank");
                }}
                className="cursor-pointer"
              >
                Employer Registeration
              </p>
            </div>
            <div>
              <Link to={""}>Blog</Link>
            </div>
          </div>
        </div>

        {/* right section */}
        <div className="flex items-center">
          <div className=" flex flex-col items-center md:items-start gap-[10px] self-start">
            <p className="font-[700] font-Avenir_demi text-[16px]">
              Download Intrn Application Soon
            </p>
            <div className="flex flex-col  md:flex-row gap-[30px]">
              <div>
                <img
                  className="w-[172px] h-[48px]"
                  src="images/google-play-dark.png"
                  alt="logo"
                />
              </div>
              <div>
                <img
                  className="w-[172px] h-[48px]"
                  src="images/app-store-dark.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* copy right section */}
      <div className="pb-[20px] w-full mt-[20px] flex items-center justify-center">
        &copy; Intrn 2024
      </div>
    </footer>
  );
}

export default Footer;
