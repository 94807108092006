import {
  JOB_DETAILS_LOADING,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";
import store from "redux/store";

export const JobDetailsAction =
  (jobId?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: JOB_DETAILS_LOADING, payload: true });
      const userId= store.getState()?.listProfileDataReducer?.profileData?.userId
      const response = await listSingleItemService(
        `api/Vacancies/${jobId}/matchmaking`,
        `?userId=${userId}`
      );
      dispatch({ type: JOB_DETAILS_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: JOB_DETAILS_FAILURE, payload: err });
    } finally {
      dispatch({ type: JOB_DETAILS_LOADING, payload: false });
    }
  };
