import
{
    LIST_UNIVERSITIES_LOADING,
    LIST_UNIVERSITIES_SUCCESS,
    LIST_UNIVERSITIES_FAILURE
}
from "redux/types";

const initialState = {
    universitiesLoading: false,
    universities: null,
    errors: null
}

export const listUniversitiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_UNIVERSITIES_LOADING :
            return {...state, universitiesLoading: action.payload};
        case LIST_UNIVERSITIES_SUCCESS :
            return {...state, universities: action.payload};
        case LIST_UNIVERSITIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}