import React, { useEffect, useState } from "react";
import { CloseIcon, erroricon } from "assets/icons";
import { Flex, Input, Spin, Typography } from "antd";
import type { GetProp } from "antd";
import type { OTPProps } from "antd/es/input/OTP";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useValidateOtpFormik } from "forms/JoinUsForms/ResetPassword/validateOtpFormik";
import { generateOtpResetPassword } from "redux/actions/JoinUsAction/ResetPasswordActions/generateOtpResetPasswordActions";

interface ValidateOtpResetPasswordProps {
  resetPop: string;
  email: string;
  phoneNum: string;
  resetPasswordMethod: any;
  setResetPop: React.Dispatch<React.SetStateAction<string>>;
}

const ValidateOtpResetPassword = ({
  resetPop,
  setResetPop,
  resetPasswordMethod,
  email,
  phoneNum,
}: ValidateOtpResetPasswordProps) => {
  const dispatch: any = useDispatch();
  const formik = useValidateOtpFormik(
    setResetPop,
    resetPasswordMethod,
    phoneNum,
    email
  );

  const { resetEmail } = useSelector(
    (state: RootState) => state.setResetPasswordEmail
  );

  const { validateOtpLoading } = useSelector(
    (state: RootState) => state.validateOtpResetPasswordReducer
  );

  // count down timer handle
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev: any) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  // otp values output
  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {
    if (text.length !== 4) {
      formik.setFieldError("otp", "please fill this field");
    } else {
      formik.setFieldValue("otp", text);
    }
  };

  const sharedProps: OTPProps = {
    onChange,
  };

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/75 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] text-pro_text_dark">
        {/*********************** Reset Password section  ************/}
        <div className="relative flex items-center justify-center">
          {/* Reset Password */}
          <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
            Reset Password
          </div>

          {/* close button */}
          {/* <div
            // onClick={() => setShowLogin(!showLogin)}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div> */}
          <div></div>
        </div>

        {/*********************** instructions ************/}
        <div className="font-Avenir_regular text-[14px] text-white flex items-center justify-center">
          Please enter the code we sent to your email
        </div>

        {/*********************** Phone number section  ************/}
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-[20px] text-white"
        >
          {/* otp code values  */}
          <Spin spinning={validateOtpLoading}>
            <div className="flex items-center justify-center">
              <Flex gap="middle" align="flex-start" vertical>
                <Input.OTP length={4} {...sharedProps} />
              </Flex>
            </div>
          </Spin>

          {/* errors section  */}
          <div className="w-full flex items-center justify-center">
            {formik.errors.otp && (
              <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                <img className="w-[22px]" src={erroricon} alt="" />{" "}
                {formik.errors.otp}
              </p>
            )}
          </div>

          {/* resend the verification code */}
          <div className="font-Avenir_regular text-[14px] text-white text-center border-t-[1px] pt-[10px]">
            You can resend the verification code after
            <span className="font-Avenir_bold ml-[10px]">
              {formatTime(timer)}
            </span>
          </div>

          {/* Resend the code */}
          {timer === 0 && (
            <Spin spinning={validateOtpLoading}>
              <div
                onClick={() =>
                  dispatch(
                    generateOtpResetPassword(
                      {
                        phoneNumber: phoneNum,
                        email: email,
                        forgotTypeId: resetPasswordMethod,
                      },
                      () => {
                        setTimer(60);
                      }
                    )
                  )
                }
                className="font-Avenir_bold text-[14px] text-white flex items-center justify-center underline cursor-pointer"
              >
                Resend the code
              </div>
            </Spin>
          )}

          {/* Send Code button  */}
          <Spin spinning={validateOtpLoading}>
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Verify
            </button>
          </Spin>
        </form>
      </div>
    </section>
  );
};

export default ValidateOtpResetPassword;
