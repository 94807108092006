import
{
    COMPANY_VACANCIES_LOADING,
    COMPANY_VACANCIES_SUCCESS,
    COMPANY_VACANCIES_FAILURE
}
from "redux/types";

const initialState = {
    companyVacanciesLoading: false,
    vacancies: null,
    errors: null
}

export const listCompanyVacanciesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPANY_VACANCIES_LOADING :
            return {...state, companyVacanciesLoading: action.payload};
        case COMPANY_VACANCIES_SUCCESS :
            return {...state, vacancies: action.payload};
        case COMPANY_VACANCIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}