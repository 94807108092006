import {
  VALIDATE_PHONE_OTP_LOADING,
  VALIDATE_PHONE_OTP_SUCCESS,
  VALIDATE_PHONE_OTP_FAILURE,
} from "redux/types";

const initialState = {
  validatePhoneOtpLoading: false,
  validatePhoneOtp: null,
  errors: null,
};

export const validatePhoneOtpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case VALIDATE_PHONE_OTP_LOADING:
      return { ...state, validatePhoneOtpLoading: action.payload };
    case VALIDATE_PHONE_OTP_SUCCESS:
      return { ...state, validatePhoneOtp: action.payload };
    case VALIDATE_PHONE_OTP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
