import { SEARCH_LOADING, SEARCH_SUCCESS, SEARCH_FAILURE } from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const searchAcitons = (data: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: SEARCH_LOADING, payload: true });
    const response = await createNewItemService(
      `api/Vacancies/SearchVacanciesByMatchedJobs?minMatchPercentage=0&maxMatchPercentage=100`,
      data,
      "",
      "json"
    );

    dispatch({ type: SEARCH_SUCCESS, payload: response.data });
  } catch (err: any) {
    dispatch({ type: SEARCH_FAILURE, payload: err });
    notification.error({
      message: `Error: ${err.message}`,
    });
  } finally {
    dispatch({ type: SEARCH_LOADING, payload: false });
  }
};
