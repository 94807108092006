import
{
    UPDATE_VACANCY_LOADING,
    UPDATE_VACANCY_SUCCESS,
    UPDATE_VACANCY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateVacancyAction = (id: string, data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_VACANCY_LOADING, payload: true});
        const response = await updateItemService(`api/Vacancies/EditVacancyBy${id}`, "", data, "json");
        dispatch({type: UPDATE_VACANCY_SUCCESS, payload: response.data});
        callback();
    } catch (err) {
        dispatch({type: UPDATE_VACANCY_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_VACANCY_LOADING, payload: false});
    }
}