import
{
    GET_COMPANY_PROFILE_LOADING,
    GET_COMPANY_PROFILE_SUCCESS,
    GET_COMPANY_PROFILE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const getCompanyProfileAction = (id: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GET_COMPANY_PROFILE_LOADING, payload: true});
        const response = await listSingleItemService(`api/Companies/GetCompanyBy${id}`, "");
        dispatch({type: GET_COMPANY_PROFILE_SUCCESS, payload: response.data});
        callback && callback(response.data);
    } catch (err) {
        dispatch({type: GET_COMPANY_PROFILE_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_COMPANY_PROFILE_LOADING, payload: false});
    }
}