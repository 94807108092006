import React from "react";
import { CloseIcon } from "assets/icons";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";

interface ResetPasswordMethodProps {
  resetPop: string;
  setResetPop: React.Dispatch<React.SetStateAction<string>>;
  resetPasswordMethod: number;
  setResetPasswordMethod: React.Dispatch<React.SetStateAction<number>>;
}

const ResetPasswordMethod = ({
  resetPop,
  setResetPop,
  resetPasswordMethod,
  setResetPasswordMethod,
}: ResetPasswordMethodProps) => {
  const { generateOtpLoading } = useSelector(
    (state: RootState) => state.generateOtpWithEmailReducer
  );
  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/75 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] text-pro_text_dark">
        {/*************************** top title section  **************/}
        <div className="relative flex items-center justify-between">
          <div></div>
          {/* Reset Password */}
          <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
            Reset Password
          </div>

          {/* close button */}
          <div className="cursor-pointer" onClick={() => setResetPop("")}>
            <img src={CloseIcon} alt="logo" />
          </div>
        </div>

        {/*************************** instructions ***************************/}
        <div className="font-Avenir_demi text-[18px] text-white flex items-center justify-center">
          How do you want to reset your password?
        </div>

        {/*************************** options section  **************/}
        <div className="flex items-center gap-10">
          {/* by phone  */}
          <div className="w-full flex items-center justify-center">
            <button
              onClick={() => {
                setResetPop("generateOtpResetPassword");
                setResetPasswordMethod(1);
              }}
              className="w-[120px] h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Phone
            </button>
          </div>

          {/* by email  */}
          <div className="w-full flex items-center justify-center">
            <button
              onClick={() => {
                setResetPop("generateOtpResetPassword");
                setResetPasswordMethod(2);
              }}
              className="w-[120px] h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Email
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordMethod;
