import
{
    UPDATE_COMPANY_PROFILE_LOADING,
    UPDATE_COMPANY_PROFILE_SUCCESS,
    UPDATE_COMPANY_PROFILE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateCompanyProfileAction = (id: string, data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_COMPANY_PROFILE_LOADING, payload: true});
        const response = await updateItemService(`api/Companies/EditCompanyBy`, id, data, "formData");
        dispatch({type: UPDATE_COMPANY_PROFILE_SUCCESS, payload: response.data});
        callback();
    }
    catch (err) {
        dispatch({type: UPDATE_COMPANY_PROFILE_FAILURE, payload: err});
    }
    finally {
        dispatch({type: UPDATE_COMPANY_PROFILE_LOADING, payload: false});
    }
}