import
{
    ADD_COMPANY_INFO_LOADING, 
    ADD_COMPANY_INFO_SUCCESS, 
    ADD_COMPANY_INFO_FAILURE
}
from "redux/types";

const initialState = {
    addCompanyInfoLoading: false,
    success: null,
    errors: null
}

export const addCompaniesInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_COMPANY_INFO_LOADING :
            return {...state, addCompanyInfoLoading: action.payload};
        case ADD_COMPANY_INFO_SUCCESS :
            return {...state, success: action.payload};
        case ADD_COMPANY_INFO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}