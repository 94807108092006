import React, { useState } from "react";
import BurgerMenu from "./BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useLoginFormik } from "forms/loginFormik";
import { useCompaniesLoginFormik } from "forms/companiesLoginFormik";
import { usePersonalInfoFormik } from "forms/personalInfoFormik";
import { useAcademicInfoFormik } from "forms/academicInfoFormik";
import { useExperienceFormik } from "forms/experienceFormik";
import { useWorkLocationFormik } from "forms/workLocationFormik";
import { useCompanyRegistrationOneFormik } from "forms/companyRegistrationOneFormik";
import { useCompanyRegistrationTwoFormik } from "forms/companyRegistrationTwoFormik";
import { useCompanyRegistrationThreeFormik } from "forms/companyRegistrationThreeFormik";


interface LoginTypes {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  formik: any;
}

function MobileMenu({ showMenu, setShowMenu,formik }: LoginTypes) {
  // const [activePop, setActivePop] = useState("");

  // const navigate = useNavigate();

  // const loginFormik = useLoginFormik(setActivePop);
  // const companiesLoginFormik = useCompaniesLoginFormik(setActivePop);
  // const personalInfoFormik = usePersonalInfoFormik(setActivePop);
  // const academicInfoFormik = useAcademicInfoFormik(setActivePop);
  // const experienceFormik = useExperienceFormik(setActivePop);
  // const workLocationFormik = useWorkLocationFormik(
  //   loginFormik.values,
  //   personalInfoFormik.values,
  //   academicInfoFormik.values,
  //   experienceFormik.values,
  //   setActivePop
  // );

  // const companyRegistrationOneFormik =
  //   useCompanyRegistrationOneFormik(setActivePop);

  // const companyRegistrationTwoFormik =
  //   useCompanyRegistrationTwoFormik(setActivePop);

  // const companyRegistrationThreeFormik =
  //   useCompanyRegistrationThreeFormik(setActivePop);

  return (
    <div>
      {/* burger menu icon */}
      <div className="block md:hidden text-center text-[40px] font-Avenir_bold hover:cursor-pointer">
        <i onClick={() => setShowMenu(!showMenu)} className="ri-menu-fill"></i>
      </div>

      {/* burger menu section  */}
      <BurgerMenu showMenu={showMenu} setShowMenu={setShowMenu} formik={formik}/>
    </div>
  );
}

export default MobileMenu;
