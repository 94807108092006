import { useEffect, useState } from "react";

const useResponsive = () => {
    const mobileWidth = 860;
    const max940 = 940;
    const max715 = 715;
    const max751 = 751;
    const max1110 = 1110;

    const [isMobile, setIsMobile] = useState(false);
    const [isMax940, setIsMax940] = useState(false);
    const [isMax715, setIsMax715] = useState(false);
    const [isMax751, setIsMax751] = useState(false);
    const [isMax1110, setIsMax1110] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > mobileWidth) {
                setIsMobile(false);
            }
            else {
                setIsMobile(true);
            }

            if (window.innerWidth > max940) {
                setIsMax940(false);
            }
            else {
                setIsMax940(true);
            }

            if (window.innerWidth > max715) {
                setIsMax715(false);
            }
            else {
                setIsMax715(true);
            }

            if (window.innerWidth > max1110) {
                setIsMax1110(false);
            } 
            else {
                setIsMax1110(true);
            }

            if (window.innerWidth > max751) {
                setIsMax751(true);
            }
            else {
                setIsMax751(false);
            }
        }

        handleResize()

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return {
        isMobile,
        isMax940,
        isMax715,
        isMax751,
        isMax1110
    }
}

export {
    useResponsive
}