import Header from "components/Header";
import Footer from "components/Footer";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { updatePaymentStatusAction } from "redux/actions/paymentActions/updatePaymentStatusAction";
import { Spin } from "antd";
import CompanyRegistrationOne from "components/Companies/CompanyRegistrationOne";
import CompanyRegistrationTwo from "components/Companies/CompanyRegistrationTwo";
import CompanyRegistrationThree from "components/Companies/CompanyRegistrationThree";
import { useCompanyRegistrationOneFormik } from "forms/companyRegistrationOneFormik";
import { useCompanyRegistrationTwoFormik } from "forms/companyRegistrationTwoFormik";
import { useCompanyRegistrationThreeFormik } from "forms/companyRegistrationThreeFormik";
import { addTransactionAction } from "redux/actions/paymentActions/addTransactionAction";

const Subscription = () => {
  const dispatch: any = useDispatch();

  const { updateLoading } = useSelector(
    (state: RootState) => state.updatePaymentStatusReducer
  );
  const { transactionLoading } = useSelector(
    (state: RootState) => state.addTransactionReducer
  );
  const { userId } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );
  const { companyId } = useSelector(
    (state: RootState) => state.addSubscriptionPlanReducer
  );

  const [activePop, setActivePop] = useState("");

  const companyRegistrationOneFormik =
    useCompanyRegistrationOneFormik(setActivePop);

  const companyRegistrationTwoFormik =
    useCompanyRegistrationTwoFormik(setActivePop);

  const companyRegistrationThreeFormik =
    useCompanyRegistrationThreeFormik(setActivePop);

  const [searchParams, setSearchParams] = useSearchParams();

  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    setSuccess(searchParams.get("success") === "true" ? true : false);
    setMessage(searchParams.get("msg") ?? "");
    setOrderId(searchParams.get("merchantOrderId") ?? "");
  }, []);

  useEffect(() => {
    dispatch(
      updatePaymentStatusAction({
        userId: userId,
        paymentStatus: searchParams.get("success"),
      })
    );
    dispatch(
      addTransactionAction({
        userId: userId,
        companyId: companyId,
        merchantOrderId: searchParams.get("merchantOrderId"),
        token: searchParams.get("token"),
        orderId: searchParams.get("orderid"),
        paymentToken: searchParams.get("paymentToken"),
        msg: searchParams.get("msg"),
        success: searchParams.get("success") === "true" ? true : false,
        refunded: searchParams.get("refunded") === "true" ? true : false,
        status: Number(searchParams.get("status")),
      })
    );
  }, []);

  return (
    <div className="size-full">
      <Header />
      {activePop === "companyOne" && (
        <CompanyRegistrationOne
          formik={companyRegistrationOneFormik}
          setActivePop={setActivePop}
        />
      )}

      {activePop === "companyTwo" && (
        <CompanyRegistrationTwo
          formik={companyRegistrationTwoFormik}
          setActivePop={setActivePop}
        />
      )}

      {activePop === "companyThree" && (
        <CompanyRegistrationThree
          formik={companyRegistrationThreeFormik}
          setActivePop={setActivePop}
        />
      )}
      <main className="min-h-[70vh] w-full pt-[100px] flex flex-col items-center gap-y-6">
        <Spin
          wrapperClassName="spin-full-width"
          spinning={updateLoading || transactionLoading}
        >
          <div className="w-full flex flex-col items-center justify-start gap-y-6">
            <h1 className="text-pro_text_dark font-Avenir_bold text-4xl">
              Your Transaction Was Completed
            </h1>
            <p className="text-xl">{message}</p>
            {success && <p className="text-xl">Order Id: {orderId}</p>}
            {success && (
              <button
                onClick={() => {
                  if (searchParams.get("success") === "true") {
                    setActivePop("companyOne");
                  }
                }}
                className="text-xl text-pro_text_light bg-pro_text_dark rounded-lg p-3"
              >
                Continue Your Registration
              </button>
            )}
          </div>
        </Spin>
      </main>
      <Footer />
    </div>
  );
};

export default Subscription;
