import
{
    LIST_COMPANIES_LOADING,
    LIST_COMPANIES_SUCCESS,
    LIST_COMPANIES_FAILURE
}
from "redux/types";

const initialState = {
    companiesLoading: false,
    companies: null,
    errors: null
}

export const listCompaniesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_COMPANIES_LOADING :
            return {...state, companiesLoading: action.payload};
        case LIST_COMPANIES_SUCCESS :
            return {...state, companies: action.payload};
        case LIST_COMPANIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}