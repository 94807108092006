import {
  VALIDATE_EMAIL_OTP_LOADING,
  VALIDATE_EMAIL_OTP_SUCCESS,
  VALIDATE_EMAIL_OTP_FAILURE,
} from "redux/types";

const initialState = {
  validateEmailOtpLoading: false,
  validateEmailOtp: null,
  errors: null,
};

export const validateEmailOtpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case VALIDATE_EMAIL_OTP_LOADING:
      return { ...state, validateEmailOtpLoading: action.payload };
    case VALIDATE_EMAIL_OTP_SUCCESS:
      return { ...state, validateEmailOtp: action.payload };
    case VALIDATE_EMAIL_OTP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
