import { ReactNode } from "react";

interface buttonValue {
  px: number;
  py: number;
  textColor: string;
  bgColor: string;
  fontSize: number;
  text: string;
  children?: ReactNode;
}

function Skill({
  px,
  py,
  textColor,
  bgColor,
  fontSize,
  text,
  children,
}: buttonValue) {
  return (
    <div
      style={{
        paddingLeft: `${px}px`,
        paddingRight: `${px}px`,
        paddingTop: `${py}px`,
        paddingBottom: `${py}px`,
        color: `${textColor}`,
        backgroundColor: `${bgColor}`,
        fontSize: `${fontSize}px`,
      }}
      className="font-Avenir_demi text-[16px] rounded-[15px] flex items-center gap-[5px]"
    >
      {children}
      {text}
    </div>
  );
}

export default Skill;
