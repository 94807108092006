import { FC, useEffect, useState } from "react";
import { CompanyRegistrationModalProps } from "./CompanyRegistrationOne";
import { BackIcon, CloseIcon, DropdownIcon, LogoIcon } from "assets/icons";
import { Input, Select, Spin } from "antd";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { EMPLOYEE_COUNT_DROPDOWN } from "constants/constants";
import { useDropdown } from "hooks/useDropdown";
import { listCitiesAction } from "redux/actions/citiesActions/listCitiesAction";

const CompanyRegistrationTwo: FC<CompanyRegistrationModalProps> = ({
  setActivePop,
  formik,
}) => {
  const dispatch: any = useDispatch();

  const { addCompanyInfoLoading } = useSelector(
    (state: RootState) => state.addCompaniesInfoReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const [countryId, setCountryId] = useState(1);

  const {
    majorsLoading,
    majorsDropdown,
    countriesLoading,
    countryDropdown,
    yearsDropdown,
  } = useDropdown();

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.cityNameEn,
      value: item.cityId,
    };
  });

  useEffect(() => {
    dispatch(listCitiesAction(countryId.toString()));
  }, [countryId]);

  return (
    <section className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10">
      <div className="relative max-h-[80vh] lg:max-h-[100vh] lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] overflow-y-auto">
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          {/* <div
            onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div> */}

          <div
            className="absolute top-0 left-0 cursor-pointer"
          >
          </div>
        </div>

        <p className="text-white text-center font-Avenir_bold text-[25px]">
          Fill Company Information
        </p>

        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col gap-y-4"
        >
          <div className="w-full flex flex-col items-start">
            <textarea
              placeholder="Bio"
              name="bio"
              className="scroll rounded-[15px] w-full !bg-transparent min-h-[120px] border-white border-[2px] p-[15px] !text-white overflow-y-auto !caret-white placeholder:!text-gray-200 focus:outline-none resize-none"
              onChange={formik.handleChange}
            ></textarea>
            {formik.errors.bio && (
              <p className="text-[red]">{formik.errors.bio}</p>
            )}
          </div>
          <div className="w-full flex flex-col items-start">
            <Spin wrapperClassName="spin-full-width" spinning={majorsLoading}>
              <Select
                className="select-no-border select-white-text w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] focus:outline-none !placeholder-white/55"
                placeholder="Company Field"
                options={
                  !majorsDropdown ? [{ label: "", value: "" }] : majorsDropdown
                }
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                onChange={(value: any) => {
                  formik.setFieldValue("majorId", value);
                }}
              />
            </Spin>
            {formik.errors.majorId && (
              <p className="text-[red]">{formik.errors.majorId}</p>
            )}
          </div>
          <div className="w-full flex flex-col items-start">
            <Spin
              wrapperClassName="spin-full-width"
              spinning={countriesLoading}
            >
              <Select
                className="select-no-border select-white-text w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] focus:outline-none !placeholder-white/55"
                placeholder="Country"
                options={countryDropdown}
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                onChange={(value: any) => {
                  formik.setFieldValue("countryId", value);
                  setCountryId(value);
                }}
              />
            </Spin>
            {formik.errors.countryId && (
              <p className="text-[red]">{formik.errors.countryId}</p>
            )}
          </div>
          <div className="w-full flex flex-col items-start">
            <Spin wrapperClassName="spin-full-width" spinning={citiesLoading}>
              <Select
                className="select-no-border select-white-text w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] focus:outline-none !placeholder-white/55"
                placeholder="City"
                options={citiesDropdown}
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                onChange={(value: any) => {
                  formik.setFieldValue("cityId", value);
                }}
              />
            </Spin>
            {formik.errors.countryId && (
              <p className="text-[red]">{formik.errors.countryId}</p>
            )}
          </div>
          <div className="w-full flex flex-col items-start">
            <Select
              className="select-no-border w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] focus:outline-none placeholder-white/55"
              placeholder="Number of Employees"
              options={EMPLOYEE_COUNT_DROPDOWN}
              suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
              onChange={(value: any) => {
                formik.setFieldValue("number_of_employees", value);
              }}
            />
            {formik.errors.number_of_employees && (
              <p className="text-[red]">{formik.errors.number_of_employees}</p>
            )}
          </div>
          <div className="w-full flex flex-col items-start">
            <Select
              className="select-no-border w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] focus:outline-none placeholder-white/55"
              placeholder="Foundation Year"
              options={yearsDropdown}
              suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
              onChange={(value: any) => {
                formik.setFieldValue("foundation_year", value.toString());
              }}
            />
            {formik.errors.foundation_year && (
              <p className="text-[red]">{formik.errors.foundation_year}</p>
            )}
          </div>
          <Spin spinning={addCompanyInfoLoading}>
            <Button
              bgColor="#FFFFFF"
              className="w-full"
              fontSize={14}
              px={40}
              py={14}
              textColor="#142644"
              type="submit"
              text="Next"
            />
          </Spin>
        </form>
      </div>
    </section>
  );
};

export default CompanyRegistrationTwo;
