import
{
    ADD_SUB_PLAN_LOADING,
    ADD_SUB_PLAN_SUCCESS,
    ADD_SUB_PLAN_FAILURE
}
from "redux/types";

const initialState = {
    addSubPlanLoading: false,
    companyId: null,
    errors: null
}

export const addSubscriptionPlanReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_SUB_PLAN_LOADING :
            return {...state, addSubPlanLoading: action.payload};
        case ADD_SUB_PLAN_SUCCESS :
            return {...state, companyId: action.payload.companyId};
        case ADD_SUB_PLAN_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}