import
{
    UPDATE_APPLICATION_STATUS_LOADING,
    UPDATE_APPLICATION_STATUS_SUCCESS,
    UPDATE_APPLICATION_STATUS_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    success: null,
    errors: null
}

export const updateApplicationStatusReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_APPLICATION_STATUS_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_APPLICATION_STATUS_SUCCESS :
            return {...state, success: action.payload};
        case UPDATE_APPLICATION_STATUS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}