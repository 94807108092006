import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { generatePhoneOtpActions } from "redux/actions/JoinUsAction/SignUp/generatePhoneOtpActions";

interface userPhoneValidationTypes {
  phone: string;
}

export const useUserPhoneValidationFormik = (
  country: string,
  setDisapbleSendCode: any,
  setShowRestForm: any
) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: yup.object({
      phone:
        country === "eg"
          ? yup
              .string()
              .matches(
                /^(?:\+20)?(?:\+0)?(10|11|12|15)\d{8}$/,
                "Invalid phone number"
              )
              .required("This field is required")
          : country === "uae"
          ? yup
              .string()
              .matches(
                /^(?:\+971|0)?(50|55|56|52|58)\d{7}$/,
                "Invalid phone number"
              )
              .required("This field is required")
          : yup.string().required("This field is required"),
    }),

    onSubmit: async (values: userPhoneValidationTypes) => {
      //   await dispatch(
      //     generatePhoneOtpActions(
      //       {
      //         phoneNumber: formik.values.phone,
      //       },
      //       () => {
      //         setDisapbleSendCode(true);
      //         setShowRestForm(true);
      //       }
      //     )
      //   );
    },
  });

  return formik;
};
