import
{
    LIST_APPLICATIONS_LOADING,
    LIST_APPLICATIONS_SUCCESS,
    LIST_APPLICATIONS_FAILURE
}
from "redux/types";

const initialState = {
    applicationsLoading: false,
    applications: null,
    errors: null
}

export const listApplicationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_APPLICATIONS_LOADING :
            return {...state, applicationsLoading: action.payload};
        case LIST_APPLICATIONS_SUCCESS :
            return {...state, applications: action.payload};
        case LIST_APPLICATIONS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}