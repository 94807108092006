import { useFormik } from "formik";
import * as yup from "yup";
import CompanyRegistrationOneModel from "models/CompanyRegistrationOneModel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { addCompanyNameImageAction } from "redux/actions/companyActions/addCompanyNameImageAction";

const useCompanyRegistrationOneFormik = (setState?: any) => {
  const { userId } = useSelector((state: RootState) => state.googleLoginReducer);
  const { companyId } = useSelector((state: RootState) => state.addSubscriptionPlanReducer);

  const { companyData } = useSelector((state: RootState) => state.getCompanyIdFromUserIdReducer);

  const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            profile_image: null,  
            cover_image: null,
            company_name: '',
            password: '',
            confirm_password: ''
        },
        validationSchema: yup.object({
          company_name: yup.string().required('please enter this field'),
        }),
        onSubmit: async (values: CompanyRegistrationOneModel) => {
          await dispatch(addCompanyNameImageAction({
            userId: userId,
            companyId: companyId === null ? companyData?.companyId : companyId,
            CompanyName: values.company_name,
            ProfileLogo: values.profile_image,
            CoverLogo: values.cover_image,
          }, () => {
            setState("companyTwo");
          }));
        },
      });
  
  return formik;
}

export {
  useCompanyRegistrationOneFormik
}