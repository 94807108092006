import {
  PHONE_SIGN_UP_LOADING,
  PHONE_SIGN_UP_SUCCESS,
  PHONE_SIGN_UP_FAILURE,
} from "redux/types";

const initialState = {
  phoneSignUpLoading: false,
  phoneSignUpData: null,
  isCompleted: null,
  errors: null,
};

export const phoneSignUpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PHONE_SIGN_UP_LOADING:
      return { ...state, phoneSignUpLoading: action.payload };
    case PHONE_SIGN_UP_SUCCESS:
      return {
        ...state,
        phoneSignUpData: action.payload,
        isCompleted: action.payload.isCompleted,
      };
    case PHONE_SIGN_UP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
