import
{
    LIST_MAJORS_LOADING,
    LIST_MAJORS_SUCCESS,
    LIST_MAJORS_FAILURE
}
from "redux/types";

const initialState = {
    majorsLoading: false,
    majors: null,
    errors: null
}

export const listMajorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_MAJORS_LOADING :
            return {...state, majorsLoading: action.payload};
        case LIST_MAJORS_SUCCESS :
            return {...state, majors: action.payload};
        case LIST_MAJORS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}