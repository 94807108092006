import {
  GET_ALL_VACANCY_LOADING,
  GET_ALL_VACANCY_SUCCESS,
  GET_ALL_VACANCY_FAILURE,
} from "redux/types";

const initialState = {
  getAllVacanciesLoading: false,
  getAllVacancies: null,
  errors: null,
};

export const listGetAllVacanciesReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_ALL_VACANCY_LOADING:
      return { ...state, getAllVacanciesLoading: action.payload };
    case GET_ALL_VACANCY_SUCCESS:
      return { ...state, getAllVacancies: action.payload };
    case GET_ALL_VACANCY_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
