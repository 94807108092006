import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { generateOtpResetPassword } from "redux/actions/JoinUsAction/ResetPasswordActions/generateOtpResetPasswordActions";

interface generateOtpTypes {
  phone: string;
  email: string;
}

export const useGenerateOtpResetPasswordFormik = (
  resetPasswordMethod: any,
  country: any,
  setResetPop: any,
  setPhoneNum: any,
  setEmail: any
) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
    },
    validationSchema: yup.object({
      phone:
        resetPasswordMethod === 1
          ? country === "eg"
            ? yup
                .string()
                .matches(
                  /^(?:\+20)?(?:\+0)?(10|11|12|15)\d{8}$/,
                  "Invalid phone number"
                )
                .required("This field is required")
            : country === "uae"
            ? yup
                .string()
                .matches(
                  /^(?:\+971|0)?(50|55|56|52|58)\d{7}$/,
                  "Invalid phone number"
                )
                .required("This field is required")
            : yup.string().required("This field is required")
          : country === "eg"
          ? yup
              .string()
              .matches(
                /^(?:\+20)?(?:\+0)?(10|11|12|15)\d{8}$/,
                "Invalid phone number"
              )
          : country === "uae"
          ? yup
              .string()
              .matches(
                /^(?:\+971|0)?(50|55|56|52|58)\d{7}$/,
                "Invalid phone number"
              )
              .required("This field is required")
          : yup.string(),

      email:
        resetPasswordMethod === 1
          ? yup.string().email()
          : yup.string().email().required("please enter this field"),
    }),
    onSubmit: async (values: generateOtpTypes) => {
      await dispatch(
        generateOtpResetPassword(
          {
            phoneNumber: country === "eg" ? `0${values.phone}` : values.phone,
            email: values.email,
            forgotTypeId: resetPasswordMethod,
          },
          () => {
            setResetPop("validateOtpResetPassword");
            setPhoneNum(country === "eg" ? `0${values.phone}` : values.phone);
            setEmail(values.email);
          }
        )
      );
    },
  });

  return formik;
};
