import { useSelector } from "react-redux";
import { SIDE_BAR_ITEMS, SidebarItemsProps } from "constants/constants";
import { RootState } from "redux/rootReducer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CompaniesHome = () => {
  const { companyData } = useSelector(
    (state: RootState) => state.getCompanyIdFromUserIdReducer
  );

  const [highlightedItem, setHighlightedItem] = useState("");

  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col justify-start items-center gap-y-5 pt-10">
      <h1 className="text-4xl">Welcome {companyData?.companyName}</h1>
      <div className="w-full flex flex-col items-center gap-y-3">
        {SIDE_BAR_ITEMS.map((item: SidebarItemsProps) => {
          return (
            <button
              onClick={() => {
                navigate(item.route);
              }}
              onMouseOver={() => {
                setHighlightedItem(item.name);
              }}
              onMouseLeave={() => {
                setHighlightedItem("");
              }}
              className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] h-[6rem] rounded-md bg-white flex items-center justify-center gap-x-5 hover:bg-dashboard_primary text-gray-400 hover:!text-white"
            >
              <img
                src={
                  highlightedItem === item.name
                    ? item.highlightedIcon
                    : item.selectedIcon
                }
                alt="icon"
                className="w-[2rem] h-[2rem]"
              />
              <p
                className={`text-xl ${
                  highlightedItem === item.name
                    ? "text-dashboard_secondary"
                    : "text-black"
                }`}
              >
                {item.name}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CompaniesHome;
