import {
  GET_ALL_VACANCY_BY_COMPANY_ID_LOADING,
  GET_ALL_VACANCY_BY_COMPANY_ID_SUCCESS,
  GET_ALL_VACANCY_BY_COMPANY_ID_FAILURE,
} from "redux/types";

const initialState = {
  companyVacancyLoading: null,
  companyVacancy: null,
  errors: null,
};

export const listGetAllVacanciesByCompanyIdReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_ALL_VACANCY_BY_COMPANY_ID_LOADING:
      return { ...state, companyVacancyLoading: action.payload };
    case GET_ALL_VACANCY_BY_COMPANY_ID_SUCCESS:
      return { ...state, companyVacancy: action.payload };
    case GET_ALL_VACANCY_BY_COMPANY_ID_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
