import { Link } from "react-router-dom";
import error from "../assets/icons/error.svg";
import { LogoBlueIcon } from "assets/icons";

const Error = () => {
  return (
    <main className="w-full h-screen flex items-center justify-center bg-home_4 bg-cover">
      <div className="flex flex-col items-center justify-center gap-[50px]">
        <div className="w-[40%] flex items-center justify-center">
          <img src={LogoBlueIcon} alt="error" />
        </div>
        <div className="w-[32%]">
          <img
            src={error}
            alt="error"
            className="animate-[moveDown_1.9s_ease-in-out_infinite]"
          />
        </div>
        <p className="font-Avenir_demi text-[25px] lg:text-[40px] text-center text-pro_text_dark">
          Sorry, The Page You're Looking For Could Not Be Found
        </p>
        <Link
          to={"/"}
          className="p-[10px] lg:p-[16px] rounded-[15px] bg-pro_text_dark text-pro_text_light text-[20px] lg:text-[28px] font-Avenir_demi hover hover:bg-pro_text_dark/90"
        >
          {" "}
          Go Back To Home Page
        </Link>
      </div>
    </main>
  );
};

export default Error;
