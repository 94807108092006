import
{
    LIST_AREAS_LOADING,
    LIST_AREAS_SUCCESS,
    LIST_AREAS_FAILURE
}
from "redux/types";

const initialState = {
    areasLoading: false,
    areas: null,
    errors: null
}

export const listAreasReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_AREAS_LOADING :
            return {...state, areasLoading: action.payload};
        case LIST_AREAS_SUCCESS :
            return {...state, areas: action.payload};
        case LIST_AREAS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}