import { useFormik } from "formik";
import * as yup from "yup";
import CompanyRegistrationThreeModel from "models/CompanyRegistrationThreeModel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { addCompaniesMediaAction } from "redux/actions/companyActions/addCompaniesMediaAction";
import { useNavigate } from "react-router-dom";
import { COMPANIES_HOME_PATH } from "Routes/paths";

const useCompanyRegistrationThreeFormik = (setState?: any) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { userId } = useSelector((state: RootState) => state.googleLoginReducer);
  const { companyId } = useSelector((state: RootState) => state.addSubscriptionPlanReducer);
  const { companyData } = useSelector((state: RootState) => state.getCompanyIdFromUserIdReducer);

    const formik = useFormik({
        initialValues: {
            facebook_link: '',
            instagram_link: '',
            website_link: '',
            linkedin_link: '',
            x_link: ''
        },
        validationSchema: yup.object({

        }),
        onSubmit: async (values: CompanyRegistrationThreeModel) => {
          await dispatch(addCompaniesMediaAction({
            userId: userId,
            companyId: companyId === null ? companyData?.companyId : companyId,
            webSite: values.website_link,
            facebookLink: values.facebook_link,
            instagramLink: values.instagram_link,
            xLink: values.x_link,
            linkedInLink: values.linkedin_link
          }, () => {
            navigate(COMPANIES_HOME_PATH);
          }));
        },
      });
  
  return formik;
}

export {
  useCompanyRegistrationThreeFormik
}