import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Home/Hero";
import JoinUs from "../components/Home/JoinUs";
import DownloadApp from "../components/Home/DownloadApp";
import CreateAccount from "../components/Home/CreateAccount";
import CatchIntern from "../components/Home/CatchIntern";

import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

function Home() {
  const { data } = useSelector((state: RootState) => state.signInReducer);

  return (
    <>
      <Header />

      {/* main section */}
      <main className="w-full text-pro_text_dark ">
        {/* hero section */}
        <Hero />

        <JoinUs />

        <DownloadApp />

        <CatchIntern />

        {data?.complition === 100 ? " " : <CreateAccount />}
      </main>

      <Footer />
    </>
  );
}

export default Home;
