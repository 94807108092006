import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface CompanyApplicationCardProps {
  id: number;
  applicationStatusId: number;
  applicationDate: string;
}

const CompanyApplicationCard: FC<CompanyApplicationCardProps> = ({
  id,
  applicationStatusId,
  applicationDate,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/dashboard/applications/${id}`)}
      className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] p-3 flex flex-col items-start gap-y-4 bg-white shadow-md hover:cursor-pointer"
    >
      <p className="text-black">Application</p>
      <div className="w-full flex flex-row gap-x-1">
        <div
          className={`rounded-bl-full rounded-tl-full w-[30%] px-6 py-1 flex items-center justify-center ${
            applicationStatusId === 4 ? "bg-[#5E85ED]" : "bg-[#9AADBF]"
          }`}
        >
          <p className="text-white">Viewed</p>
        </div>
        <div
          className={`px-6 py-1 flex items-center w-[30%] justify-center ${
            applicationStatusId === 5 ? "bg-[#F7C500]" : "bg-[#9AADBF]"
          }`}
        >
          <p className="text-white">Shortlisted</p>
        </div>
        <div
          className={`rounded-tr-full rounded-br-full px-6 py-1 w-[30%] flex items-center justify-center ${
            applicationStatusId === 2
              ? "bg-[#5BC657]"
              : applicationStatusId === 3
              ? "bg-[red]"
              : "bg-[#9AADBF]"
          }`}
        >
          <p className="text-white">
            {applicationStatusId === 2
              ? "Accepted"
              : applicationStatusId === 3
              ? "Rejected"
              : "Selection"}
          </p>
        </div>
      </div>
      <p className="inline">
        Applied Date:{" "}
        <p className="inline text-dashboard_primary">
          {applicationDate?.split("T")[0]}
        </p>
      </p>
    </div>
  );
};

export default CompanyApplicationCard;
