// Google Login Types
export const GOOGLE_LOGIN_LOADING = "GOOGLE_LOGIN_LOADING";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const SET_GOOGLE_DATA = "SET_GOOGLE_DATA";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";

// Set Navbar Background
export const SET_NAVBAR_BACKGROUND = "SET_NAVBAR_BACKGROUND";

// List Universities Types
export const LIST_UNIVERSITIES_LOADING = "LIST_UNIVERSITIES_LOADING";
export const LIST_UNIVERSITIES_SUCCESS = "LIST_UNIVERSITIES_SUCCESS";
export const LIST_UNIVERSITIES_FAILURE = "LIST_UNIVERSITIES_FAILURE";

// List Faculties Types
export const LIST_FACULTIES_LOADING = "LIST_FACULTIES_LOADING";
export const LIST_FACULTIES_SUCCESS = "LIST_FACULTIES_SUCCESS";
export const LIST_FACULTIES_FAILURE = "LIST_FACULTIES_FAILURE";

// List Countries Types
export const LIST_COUNTRIES_LOADING = "LIST_COUNTRIES_LOADING";
export const LIST_COUNTRIES_SUCCESS = "LIST_COUNTRIES_SUCCESS";
export const LIST_COUNTRIES_FAILURE = "LIST_COUNTRIES_FAILURE";

// List Cities Types
export const LIST_CITIES_LOADING = "LIST_CITIES_LOADING";
export const LIST_CITIES_SUCCESS = "LIST_CITIES_SUCCESS";
export const LIST_CITIES_FAILURE = "LIST_CITIES_FAILURE";

// Register User Info Types
export const REGISTER_USER_INFO_LOADING = "REGISTER_USER_INFO_LOADING";
export const REGISTER_USER_INFO_SUCCESS = "REGISTER_USER_INFO_SUCCESS";
export const REGISTER_USER_INFO_FAILURE = "REGISTER_USER_INFO_FAILURE";

// List Majors Types
export const LIST_MAJORS_LOADING = "LIST_MAJORS_LOADING";
export const LIST_MAJORS_SUCCESS = "LIST_MAJORS_SUCCESS";
export const LIST_MAJORS_FAILURE = "LIST_MAJORS_FAILURE";

// List Skills Types
export const LIST_SKILLS_LOADING = "LIST_SKILLS_LOADING";
export const LIST_SKILLS_SUCCESS = "LIST_SKILLS_SUCCESS";
export const LIST_SKILLS_FAILURE = "LIST_SKILLS_FAILURE";

// List matchedInterns Types
export const LIST_MATCHED_INTERNS_LOADING = "LIST_MATCHED_INTERNS_LOADING";
export const LIST_MATCHED_INTERNS_SUCCESS = "LIST_MATCHED_INTERNS_SUCCESS";
export const LIST_MATCHED_INTERNS_FAILURE = "LIST_MATCHED_INTERNS_FAILURE";

// List matchedInterns Types
export const JOB_DETAILS_LOADING = "JOB_DETAILS_LOADING";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_DETAILS_FAILURE = "JOB_DETAILS_FAILURE";

// Company Login Types
export const COMPANY_GOOGLE_LOGIN_LOADING = "COMPANY_GOOGLE_LOGIN_LOADING";
export const COMPANY_GOOGLE_LOGIN_SUCCESS = "COMPANY_GOOGLE_LOGIN_SUCCESS";
export const COMPANY_GOOGLE_LOGIN_FAILURE = "COMPANY_GOOGLE_LOGIN_FAILURE";

// Add Subscription Plan
export const ADD_SUB_PLAN_LOADING = "ADD_SUB_PLAN_LOADING";
export const ADD_SUB_PLAN_SUCCESS = "ADD_SUB_PLAN_SUCCESS";
export const ADD_SUB_PLAN_FAILURE = "ADD_SUB_PLAN_FAILURE";

// Add Company Name & Image
export const ADD_NAME_IMAGE_LOADING = "ADD_NAME_IMAGE_LOADING";
export const ADD_NAME_IMAGE_SUCCESS = "ADD_NAME_IMAGE_SUCCESS";
export const ADD_NAME_IMAGE_FAILURE = "ADD_NAME_IMAGE_FAILURE";

// Add Company Info
export const ADD_COMPANY_INFO_LOADING = "ADD_COMPANY_INFO_LOADING";
export const ADD_COMPANY_INFO_SUCCESS = "ADD_COMPANY_INFO_SUCCESS";
export const ADD_COMPANY_INFO_FAILURE = "ADD_COMPANY_INFO_FAILURE";

// Add Media Links
export const ADD_COMPANY_MEDIA_LOADING = "ADD_COMPANY_MEDIA_LOADING";
export const ADD_COMPANY_MEDIA_SUCCESS = "ADD_COMPANY_MEDIA_SUCCESS";
export const ADD_COMPANY_MEDIA_FAILURE = "ADD_COMPANY_MEDIA_FAILURE";

// Get Profile Completion
export const GET_PROFILE_COMPLETION_LOADING = "GET_PROFILE_COMPLETION_LOADING";
export const GET_PROFILE_COMPLETION_SUCCESS = "GET_PROFILE_COMPLETION_SUCCESS";
export const GET_PROFILE_COMPLETION_FAILURE = "GET_PROFILE_COMPLETION_FAILURE";

// Add User Info
export const ADD_USER_INFO_LOADING = "ADD_USER_INFO_LOADING";
export const ADD_USER_INFO_SUCCESS = "ADD_USER_INFO_SUCCESS";
export const ADD_USER_INFO_FAILURE = "ADD_USER_INFO_FAILURE";

// Add Education Info
export const ADD_EDUCATION_INFO_LOADING = "ADD_EDUCATION_INFO_LOADING";
export const ADD_EDUCATION_INFO_SUCCESS = "ADD_EDUCATION_INFO_SUCCESS";
export const ADD_EDUCATION_INFO_FAILURE = "ADD_EDUCATION_INFO_FAILURE";

// Add Experience Types
export const ADD_EXPERIENCE_LOADING = "ADD_EXPERIENCE_LOADING";
export const ADD_EXPERIENCE_SUCCESS = "ADD_EXPERIENCE_SUCCESS";
export const ADD_EXPERIENCE_FAILURE = "ADD_EXPERIENCE_FAILURE";

// Add Work Location Types
export const ADD_WORK_LOCATION_LOADING = "ADD_WORK_LOCATION_LOADING";
export const ADD_WORK_LOCATION_SUCCESS = "ADD_WORK_LOCATION_SUCCESS";
export const ADD_WORK_LOCATION_FAILURE = "ADD_WORK_LOCATION_FAILURE";

// Add Majors Types
export const ADD_MAJORS_LOADING = "ADD_MAJORS_LOADING";
export const ADD_MAJORS_SUCCESS = "ADD_MAJORS_SUCCESS";
export const ADD_MAJORS_FAILURE = "ADD_MAJORS_FAILURE";

// Add Skills Types
export const ADD_SKILLS_LOADING = "ADD_SKILLS_LOADING";
export const ADD_SKILLS_SUCCESS = "ADD_SKILLS_SUCCESS";
export const ADD_SKILLS_FAILURE = "ADD_SKILLS_FAILURE";

// Complete User Registration
export const COMPLETE_REGISTRATION_LOADING = "COMPLETE_REGISTRATION_LOADING";
export const COMPLETE_REGISTRATION_SUCCESS = "COMPLETE_REGISTRATION_SUCCESS";
export const COMPLETE_REGISTRATION_FAILURE = "COMPLETE_REGISTRATION_FAILURE";

// Create Vacancy Types
export const CREATE_VACANCY_LOADING = "CREATE_VACANCY_LOADING";
export const CREATE_VACANCY_SUCCESS = "CREATE_VACANCY_SUCCESS";
export const CREATE_VACANCY_FAILURE = "CREATE_VACANCY_FAILURE";

// Get Company Vacancies Types
export const COMPANY_VACANCIES_LOADING = "COMPANY_VACANCIES_LOADING";
export const COMPANY_VACANCIES_SUCCESS = "COMPANY_VACANCIES_SUCCESS";
export const COMPANY_VACANCIES_FAILURE = "COMPANY_VACANCIES_FAILURE";

// View Vacancy Types
export const GET_VACANCY_LOADING = "GET_VACANCY_LOADING";
export const GET_VACANCY_SUCCESS = "GET_VACANCY_SUCCESS";
export const GET_VACANCY_FAILURE = "GET_VACANCY_FAILURE";

// Update Vacancy Types
export const UPDATE_VACANCY_LOADING = "UPDATE_VACANCY_LOADING";
export const UPDATE_VACANCY_SUCCESS = "UPDATE_VACANCY_SUCCESS";
export const UPDATE_VACANCY_FAILURE = "UPDATE_VACANCY_FAILURE";

// Get Company Data Types
export const GET_COMPANY_LOADING = "GET_COMPANY_LOADING";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

// Araf Maloosh Lazma
export const GET_COMPANY_ID_LOADING = "GET_COMPANY_ID_LOADING";
export const GET_COMPANY_ID_SUCCESS = "GET_COMPANY_ID_SUCCESS";
export const GET_COMPANY_ID_FAILURE = "GET_COMPANY_ID_FAILURE";

// Get Company Profile Data Types
export const GET_COMPANY_PROFILE_LOADING = "GET_COMPANY_PROFILE_LOADING";
export const GET_COMPANY_PROFILE_SUCCESS = "GET_COMPANY_PROFILE_SUCCESS";
export const GET_COMPANY_PROFILE_FAILURE = "GET_COMPANY_PROFILE_FAILURE";

// Update Company Profile
export const UPDATE_COMPANY_PROFILE_LOADING = "UPDATE_COMPANY_PROFILE_LOADING";
export const UPDATE_COMPANY_PROFILE_SUCCESS = "UPDATE_COMPANY_PROFILE_SUCCESS";
export const UPDATE_COMPANY_PROFILE_FAILURE = "UPDATE_COMPANY_PROFILE_FAILURE";

// Update Company Profile
export const GET_COMPANY_JOBS_LOADING = "GET_COMPANY_JOBS_LOADING";
export const GET_COMPANY_JOBS_SUCCESS = "GET_COMPANY_JOBS_SUCCESS";
export const GET_COMPANY_JOBS_FAILURE = "GET_COMPANY_JOBS_FAILURE";

// Delete Vacancy Types
export const DELETE_VACANCY_LOADING = "DELETE_VACANCY_LOADING";
export const DELETE_VACANCY_SUCCESS = "DELETE_VACANCY_SUCCESS";
export const DELETE_VACANCY_FAILURE = "DELETE_VACANCY_FAILURE";

// List Application Types
export const LIST_APPLICATIONS_LOADING = "LIST_APPLICATIONS_LOADING";
export const LIST_APPLICATIONS_SUCCESS = "LIST_APPLICATIONS_SUCCESS";
export const LIST_APPLICATIONS_FAILURE = "LIST_APPLICATIONS_FAILURE";

// Apply To A Job Types
export const APPLY_TO_JOB_LOADING = "APPLY_TO_JOB_LOADING";
export const APPLY_TO_JOB_SUCCESS = "APPLY_TO_JOB_SUCCESS";
export const APPLY_TO_JOB_FAILURE = "APPLY_TO_JOB_FAILURE";

// List My Applications Types
export const LIST_MY_APPLICATIONS_LOADING = "LIST_MY_APPLICATIONS_LOADING";
export const LIST_MY_APPLICATIONS_SUCCESS = "LIST_MY_APPLICATIONS_SUCCESS";
export const LIST_MY_APPLICATIONS_FAILURE = "LIST_MY_APPLICATIONS_FAILURE";

// List Companies Types
export const LIST_COMPANIES_LOADING = "LIST_COMPANIES_LOADING";
export const LIST_COMPANIES_SUCCESS = "LIST_COMPANIES_SUCCESS";
export const LIST_COMPANIES_FAILURE = "LIST_COMPANIES_FAILURE";

// List Company Detail Types
export const LIST_COMPANY_DETAILS_LOADING = "LIST_COMPANY_DETAILS_LOADING";
export const LIST_COMPANY_DETAILS_SUCCESS = "LIST_COMPANY_DETAILS_SUCCESS";
export const LIST_COMPANY_DETAILS_FAILURE = "LIST_COMPANY_DETAILS_FAILURE";

// List Profile Data Types
export const LIST_PROFILE_DATA_LOADING = "LIST_PROFILE_DATA_LOADING";
export const LIST_PROFILE_DATA_SUCCESS = "LIST_PROFILE_DATA_SUCCESS";
export const LIST_PROFILE_DATA_FAILURE = "LIST_PROFILE_DATA_FAILURE";

// Update User Profile Data
export const UPDATE_USER_PROFILE_LOADING = "UPDATE_USER_PROFILE_LOADING";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

// View Application Types
export const VIEW_APPLICATION_LOADING = "VIEW_APPLICATION_LOADING";
export const VIEW_APPLICATION_SUCCESS = "VIEW_APPLICATION_SUCCESS";
export const VIEW_APPLICATION_FAILURE = "VIEW_APPLICATION_FAILURE";

// Update Application Status
export const UPDATE_APPLICATION_STATUS_LOADING =
  "UPDATE_APPLICATION_STATUS_LOADING";
export const UPDATE_APPLICATION_STATUS_SUCCESS =
  "UPDATE_APPLICATION_STATUS_SUCCESS";
export const UPDATE_APPLICATION_STATUS_FAILURE =
  "UPDATE_APPLICATION_STATUS_FAILURE";

// Search Vacancy Status
export const SEARCH_VACANCIES_LOADING = "SEARCH_VACANCIES_LOADING";
export const SEARCH_VACANCIES_SUCCESS = "SEARCH_VACANCIES_SUCCESS";
export const SEARCH_VACANCIES_FAILURE = "SEARCH_VACANCIES_FAILURE";

// Get Applications By Id
export const APPLICATIONS_BY_ID_LOADING = "APPLICATIONS_BY_ID_LOADING";
export const APPLICATIONS_BY_ID_SUCCESS = "APPLICATIONS_BY_ID_SUCCESS";
export const APPLICATIONS_BY_ID_FAILURE = "APPLICATIONS_BY_ID_FAILURE";

// search Types
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";

// List Majors Dropdown Types
export const MAJORS_DROPDOWN_LOADING = "MAJORS_DROPDOWN_LOADING";
export const MAJORS_DROPDOWN_SUCCESS = "MAJORS_DROPDOWN_SUCCESS";
export const MAJORS_DROPDOWN_FAILURE = "MAJORS_DROPDOWN_FAILURE";

// List Areas Types
export const LIST_AREAS_LOADING = "LIST_AREAS_LOADING";
export const LIST_AREAS_SUCCESS = "LIST_AREAS_SUCCESS";
export const LIST_AREAS_FAILURE = "LIST_AREAS_FAILURE";

// get All Vacancies Types
export const GET_ALL_VACANCY_LOADING = "GET_ALL_VACANCY_LOADING";
export const GET_ALL_VACANCY_SUCCESS = "GET_ALL_VACANCY_SUCCESS";
export const GET_ALL_VACANCY_FAILURE = "GET_ALL_VACANCY_FAILURE";

// get All Vacancy By Company Id Types
export const GET_ALL_VACANCY_BY_COMPANY_ID_LOADING =
  "GET_ALL_VACANCY_BY_COMPANY_ID_LOADING";
export const GET_ALL_VACANCY_BY_COMPANY_ID_SUCCESS =
  "GET_ALL_VACANCY_BY_COMPANY_ID_SUCCESS";
export const GET_ALL_VACANCY_BY_COMPANY_ID_FAILURE =
  "GET_ALL_VACANCY_BY_COMPANY_ID_FAILURE";

// Update Payment Status
export const UPDATE_PAYMENT_STATUS_LOADING = "UPDATE_PAYMENT_STATUS_LOADING";
export const UPDATE_PAYMENT_STATUS_SUCCESS = "UPDATE_PAYMENT_STATUS_SUCCESS";
export const UPDATE_PAYMENT_STATUS_FAILURE = "UPDATE_PAYMENT_STATUS_FAILURE";

// Add Payment Transaction
export const ADD_TRANSACTION_LOADING = "ADD_TRANSACTION_LOADING";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_FAILURE = "ADD_TRANSACTION_FAILURE";
// get user cv Types
export const GET_USER_CV_LOADING = "GET_USER_CV_LOADING";
export const GET_USER_CV_SUCCESS = "GET_USER_CV_SUCCESS";
export const GET_USER_CV_FAILURE = "GET_USER_CV_FAILURE";

// Upload CV Types
export const UPLOAD_CV_LOADING = "UPLOAD_CV_LOADING";
export const UPLOAD_CV_SUCCESS = "UPLOAD_CV_SUCCESS";
export const UPLOAD_CV_FAILURE = "UPLOAD_CV_FAILURE";

// Update CV Types
export const UPDATE_CV_LOADING = "UPDATE_CV_LOADING";
export const UPDATE_CV_SUCCESS = "UPDATE_CV_SUCCESS";
export const UPDATE_CV_FAILURE = "UPDATE_CV_FAILURE";

// get countries search  Types
export const GET_COUNTRIES_SEARCH_LOADING = "GET_COUNTRIES_SEARCH_LOADING";
export const GET_COUNTRIES_SEARCH_SUCCESS = "GET_COUNTRIES_SEARCH_SUCCESS";
export const GET_COUNTRIES_SEARCH_FAILURE = "GET_COUNTRIES_SEARCH_FAILURE";

// get area search  Types
export const GET_AREA_SEARCH_LOADING = "GET_AREA_SEARCH_LOADING";
export const GET_AREA_SEARCH_SUCCESS = "GET_AREA_SEARCH_SUCCESS";
export const GET_AREA_SEARCH_FAILURE = "GET_AREA_SEARCH_FAILURE";

// password types/////////////////////////////////////
// change password
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// set reset email
export const SET_RESET_EMAIL = "SET_RESET_EMAIL";

// reset password one
export const GENERATE_OTP_LOADING = "GENERATE_OTP_LOADING";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILURE = "GENERATE_OTP_FAILURE";

// reset password two
export const VALIDATE_OTP_LOADING = "VALIDATE_OTP_LOADING";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

// RESET PASSWORD THREE
export const ADDING_NEW_PASSWORD_LOADING = "ADDING_NEW_PASSWORD_LOADING";
export const ADDING_NEW_PASSWORD_SUCCESS = "ADDING_NEW_PASSWORD_SUCCESS";
export const ADDING_NEW_PASSWORD_FAILURE = "ADDING_NEW_PASSWORD_FAILURE";

/////////////////////////////////////////////////////////// join us types

// sign in /////////////////////////////////
export const SIGN_IN_LOADING = "SIGN_IN_LOADING";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

// sign up /////////////////////////////////

// generate otp with phone
export const GENERATE_PHONE_OTP_LOADING = "GENERATE_PHONE_OTP_LOADING";
export const GENERATE_PHONE_OTP_SUCCESS = "GENERATE_PHONE_OTP_SUCCESS";
export const GENERATE_PHONE_OTP_FAILURE = "GENERATE_PHONE_OTP_FAILURE";

// phone sign up
export const PHONE_SIGN_UP_LOADING = "PHONE_SIGN_UP_LOADING";
export const PHONE_SIGN_UP_SUCCESS = "PHONE_SIGN_UP_SUCCESS";
export const PHONE_SIGN_UP_FAILURE = "PHONE_SIGN_UP_FAILURE";

// add user personal info
export const ADD_USER_PERSONAL_INFO_LOADING = "ADD_USER_PERSONAL_INFO_LOADING";
export const ADD_USER_PERSONAL_INFO_SUCCESS = "ADD_USER_PERSONAL_INFO_SUCCESS";
export const ADD_USER_PERSONAL_INFO_FAILURE = "ADD_USER_PERSONAL_INFO_FAILURE";

// generate email otp
export const GENERATE_EMAIL_OTP_LOADING = "GENERATE_EMAIL_OTP_LOADING";
export const GENERATE_EMAIL_OTP_SUCCESS = "GENERATE_EMAIL_OTP_SUCCESS";
export const GENERATE_EMAIL_OTP_FAILURE = "GENERATE_EMAIL_OTP_FAILURE";

// validate email otp
export const VALIDATE_EMAIL_OTP_LOADING = "VALIDATE_EMAIL_OTP_LOADING";
export const VALIDATE_EMAIL_OTP_SUCCESS = "VALIDATE_EMAIL_OTP_SUCCESS";
export const VALIDATE_EMAIL_OTP_FAILURE = "VALIDATE_EMAIL_OTP_FAILURE";

// validate phone otp
export const VALIDATE_PHONE_OTP_LOADING = "VALIDATE_PHONE_OTP_LOADING";
export const VALIDATE_PHONE_OTP_SUCCESS = "VALIDATE_PHONE_OTP_SUCCESS";
export const VALIDATE_PHONE_OTP_FAILURE = "VALIDATE_PHONE_OTP_FAILURE";

// setting the study level status
export const SET_SHOW_ACADEMIC_YEAR = "SET_SHOW_ACADEMIC_YEAR";

///////////////////////// reset password

// generate otp
export const GENERATE_OTP_RESET_PASSWORD_LOADING =
  "GENERATE_OTP_RESET_PASSWORD_LOADING";
export const GENERATE_OTP_RESET_PASSWORD_SUCCESS =
  "GENERATE_OTP_RESET_PASSWORD_SUCCESS";
export const GENERATE_OTP_RESET_PASSWORD_FAILURE =
  "GENERATE_OTP_RESET_PASSWORD_FAILURE";

// validate otp
export const VALIDATE_OTP_RESET_PASSWORD_LOADING =
  "VALIDATE_OTP_RESET_PASSWORD_LOADING";
export const VALIDATE_OTP_RESET_PASSWORD_SUCCESS =
  "VALIDATE_OTP_RESET_PASSWORD_SUCCESS";
export const VALIDATE_OTP_RESET_PASSWORD_FAILURE =
  "VALIDATE_OTP_RESET_PASSWORD_FAILURE";

// Reset password
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
