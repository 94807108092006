import { FC, useState } from "react";

export interface AdditionalFeaturesButtonProps {
  id: number;
  amountAdd: number;
  featureText: string;
  additionalFeatureText?: string;
  setState?: any;
  selected?: boolean;
  setSelected?: any;
}

const AdditionalFeaturesButton: FC<AdditionalFeaturesButtonProps> = ({
  id,
  amountAdd,
  featureText,
  additionalFeatureText,
  setState,
}) => {
  const [selected, setSelected] = useState(false);

  const handleAddAmount = () => {
    setState((prev: number) => prev + amountAdd);
  };

  const handleRemoveAmount = () => {
    setState((prev: number) => prev - amountAdd);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-y-2">
      <button
        type="button"
        onClick={() => {
          if (!selected) {
            handleAddAmount();
          } else {
            handleRemoveAmount();
          }
          setSelected((prev) => !prev);
        }}
        className={`flex items-center justify-center py-1 px-8 border-2 rounded-[15px] border-pro_text_light ${
          selected ? "bg-pro_text_light" : "text-transparent"
        }`}
      >
        <p className="text-[#142644] text-[12px] md:text-[16px] text-center font-Avenir_demi">
          {amountAdd === 0 ? "For Free" : amountAdd}{" "}
          {amountAdd === 0 ? "" : "EGP"} <br /> / {featureText}
        </p>
      </button>
      {additionalFeatureText && (
        <p className="text-[#444444] font-Avenir_regular text-[10px] md:text-[13px] max-w-[192px] text-center">
          {additionalFeatureText}
        </p>
      )}
    </div>
  );
};

export default AdditionalFeaturesButton;
