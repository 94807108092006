import React from "react";
import Button from "../Button";
import { CreateAccountBg } from "assets/icons";

function CreateAccount() {
  return (
    <section
      data-aos="fade-up"
      data-aos-duration="900"
      style={{
        backgroundImage: `url('${CreateAccountBg}')`,
        backgroundPosition: "center",
      }}
      className="w-full min-h-[435px] mt-[50px] bg-pro_text_light flex items-center justify-center"
    >
      <div className="w-full px-[40px] lg:px-[144px]  py-4 lg:py-0 flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-0">
        {/* left side */}
        <div className="lg:w-[50%]">
          <img
            className="animate-[moveDown_1.5s_ease-in-out_infinite]"
            src="images/create-account.png"
            alt=""
          />
        </div>

        {/* right side */}
        <div className="lg:w-[50%] 2xl:px-[140px] flex flex-col gap-[30px] lg:gap-[10px]">
          <p className="font-Avenir_demi text-[14px] lg:text-[24px] text-pro_text_dark text-center lg:text-left">
            Join us today without any hesitation
          </p>
          <p className="font-Avenir_bold text-[22px] lg:text-[32px] text-pro_text_dark text-center lg:text-left">
            Register now for transformative experience awaits you!
          </p>
          <div className="flex items-center justify-center lg:justify-start">
            <Button
              type="button"
              bgColor="#142644"
              fontSize={18}
              px={56}
              py={11}
              textColor="#E3FF66"
              text="Create Account"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateAccount;
