import {
  ADD_EDUCATION_INFO_LOADING,
  ADD_EDUCATION_INFO_SUCCESS,
  ADD_EDUCATION_INFO_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const addEducationInfoAction =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ADD_EDUCATION_INFO_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewUserProfiles/EditFormEducation",
        data,
        "",
        "json"
      );
      dispatch({ type: ADD_EDUCATION_INFO_SUCCESS, payload: response.data });
      callback();
    } catch (err) {
      dispatch({ type: ADD_EDUCATION_INFO_FAILURE, payload: err });
    } finally {
      dispatch({ type: ADD_EDUCATION_INFO_LOADING, payload: false });
    }
  };
