import {
  VALIDATE_OTP_LOADING,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILURE,
} from "redux/types";

const initailState = {
  validateLoading: false,
  validateSuccess: null,
  errors: null,
};

export const validateOtpReducer = (state = initailState, action: any) => {
  switch (action.type) {
    case VALIDATE_OTP_LOADING:
      return { ...state, validateLoading: action.payload };
    case VALIDATE_OTP_SUCCESS:
      return { ...state, validateSuccess: action.payload };
    case VALIDATE_OTP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
