import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addUserPersonalInfoActions } from "redux/actions/JoinUsAction/SignUp/addUserPersonalInfoActions";
import { RootState } from "redux/rootReducer";

interface userPersonalInfoTypes {
  image: File | null;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
}

export const useUserPersonalInfoFormik = (setActivePop: any) => {
  const dispatch: any = useDispatch();

  const { phoneSignUpData } = useSelector(
    (state: RootState) => state.phoneSignUpReducer
  );
  const userId = phoneSignUpData?.userId;

  const formik = useFormik({
    initialValues: {
      image: null,
      firstName: "",
      lastName: "",
      address: "",
      email: "",
    },
    validationSchema: yup.object({
      // image: yup.optional(),
      firstName: yup.string().required("This field is required"),
      lastName: yup.string().required("This field is required"),
      address: yup.string().required("This field is required"),
      email: yup.string().email().required("This field is required"),
    }),

    onSubmit: async (values: userPersonalInfoTypes) => {
      await dispatch(
        addUserPersonalInfoActions(
          {
            FirstName: values.firstName,
            LastName: values.lastName,
            ImageFile: values.image,
            Address: values.address,
            Email: values.email,
          },
          () => {
            setActivePop("userExperience");
          }
        )
      );
    },
  });

  return formik;
};
