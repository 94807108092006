import { Select, Spin } from "antd";
import { DownArrowBlack } from "assets/icons";
import { useDropdown } from "hooks/useDropdown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCitiesAction } from "redux/actions/citiesActions/listCitiesAction";
import { RootState } from "redux/rootReducer";

interface LocationInformationProps {
  formik: any;
}

const LocationInformation = ({ formik }: LocationInformationProps) => {
  const dispatch: any = useDispatch();

  const { countriesLoading, countryDropdown } = useDropdown();
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.cityNameEn,
      value: item.cityId,
    };
  });

  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const locationTypes = [
    { label: "On Site", value: 1 },
    { label: "Remotely", value: 2 },
    { label: "Hybrid", value: 3 },
  ];

  useEffect(() => {
    dispatch(listCitiesAction("1"));
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="w-full flex flex-col items-start px-4 py-4 shadow-[0px_0px_20px_-10px_black] gap-y-3 bg-white rounded-[15px]"
    >
      <p className="text-black font-Avenir_demi text-[20px]">
        Location Information
      </p>
      <div className="w-full flex flex-col items-start gap-y-3">
        <Spin
          wrapperClassName="spin-full-width select-no-border"
          className="!w-full !bg-transparent"
          spinning={countriesLoading}
        >
          <Select
            className="w-full h-[48px] rounded-[15px] border border-[#9AADBF] focus:outline-none placeholder:!text-black placeholder-white/55"
            placeholder="Countries"
            suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
            value={formik.values.CountryId}
            options={countryDropdown}
            onSelect={(value: any) => {
              dispatch(listCitiesAction(value?.toString()));
              formik.setFieldValue("CountryId", value);
            }}
          />
        </Spin>
        <Spin
          wrapperClassName="spin-full-width select-no-border"
          className="!w-full !bg-transparent"
          spinning={citiesLoading}
        >
          <Select
            className="w-full h-[48px] rounded-[15px] border border-[#9AADBF] focus:outline-none placeholder:!text-black placeholder-white/55"
            placeholder="Cities"
            suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
            value={formik.values.CityId}
            options={citiesDropdown}
            onSelect={(value: any) => formik.setFieldValue("CityId", value)}
          />
        </Spin>

        <div className="w-full h-[48px] border border-[#9AADBF] rounded-[10px] flex items-center  pl-[10px]">
          Address: {profileData?.address}
        </div>
        <p className="text-sm">Select Location Type</p>
        <div className="w-full flex flex-row items-center gap-x-2">
          {locationTypes?.map((item: any, i: number) => {
            return (
              <button
                key={i}
                type="button"
                onClick={() =>
                  formik.setFieldValue("JobLocationTypeId", item.value)
                }
                className={`w-1/3 h-[48px] duration-75 border-2 rounded-[15px] border-[#142664] ${
                  item.value === formik.values.JobLocationTypeId
                    ? "bg-pro_text_light"
                    : "bg-white"
                }`}
              >
                <p className="text-black">{item?.label}</p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocationInformation;
