import { Empty, Select, Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listApplicationsAction } from "redux/actions/applicationsActions/listApplicationsAction";
import { RootState } from "redux/rootReducer";
import CompanyApplicationCard from "components/Companies/CompanyApplicationCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getApplicationsByIdAction } from "redux/actions/applicationsActions/getApplicationsByIdAction";
import { listCompanyVacanciesAction } from "redux/actions/vacanciesActions/listCompanyVacanciesAction";
import { DownArrowBlack } from "assets/icons";

const CompanyApplications = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { companyId } = useSelector(
    (state: RootState) => state.addSubscriptionPlanReducer
  );
  const { companyData } = useSelector(
    (state: RootState) => state.getCompanyIdFromUserIdReducer
  );

  const { applicationsLoading, applications } = useSelector(
    (state: RootState) => state.listApplicationsReducer
  );

  const { applicationsByIdLoading, applicationsById } = useSelector(
    (state: RootState) => state.getApplicationsByIdReducer
  );

  const { companyVacanciesLoading, vacancies } = useSelector(
    (state: RootState) => state.listCompanyVacanciesReducer
  );

  useEffect(() => {
    dispatch(listCompanyVacanciesAction());
  }, []);

  useEffect(() => {
    if (searchParams.get("vacancyId")?.trim() === undefined) {
      dispatch(
        listApplicationsAction(
          companyId === null ? companyData?.companyId : companyId
        )
      );
    }

    if (searchParams.get("vacancyId")?.trim() !== undefined) {
      dispatch(getApplicationsByIdAction(searchParams?.get("vacancyId") ?? ""));
    }
  }, [searchParams]);

  return (
    <Spin
      wrapperClassName="spin-full-width spin-full-height"
      spinning={applicationsLoading || applicationsByIdLoading}
    >
      <div className="w-full h-full flex flex-col items-start gap-y-4 pl-8 pt-8">
        <h1 className="font-bold text-2xl">Applications</h1>
        <div className="w-full flex flex-row gap-x-5 items-center">
          {searchParams?.get("vacancyId")?.trim() !== undefined && (
            <button
              onClick={() => setSearchParams("")}
              className="bg-[red] p-3 rounded-md"
            >
              <p className="text-white">Remove Filters</p>
            </button>
          )}
          {searchParams?.get("vacancyId")?.trim() !== undefined && (
            <p className="inline">
              You are now viewing all applications on the vacancy:{" "}
              <p className="inline text-dashboard_primary font-Avenir_bold">
                {applicationsById?.length > 0
                  ? applicationsById[0]?.vacancy?.title
                  : ""}
              </p>
            </p>
          )}
          {searchParams?.get("vacancyId")?.trim() === undefined && (
            <p className="inline">
              You are now viewing:{" "}
              <p className="inline text-dashboard_primary font-Avenir_bold">
                All Applications
              </p>
            </p>
          )}
          {searchParams?.get("vacancyId")?.trim() === undefined && (
            <Spin
              wrapperClassName="spin-full-width"
              spinning={companyVacanciesLoading}
            >
              <Select
                className="select-no-border select-black-text w-[50%] h-[2.5rem] flex flex-col rounded-[15px] text-black justify-center border border-black items-center placeholder:!text-black"
                placeholder="Filter Applications By Vacancy"
                suffixIcon={<img src={DownArrowBlack} />}
                onSelect={(value: number) => {
                  navigate(`/dashboard/applications?vacancyId=${value}`);
                }}
                options={vacancies?.map((item: any) => {
                  return {
                    label: item.title,
                    value: item.vacancyId,
                  };
                })}
              />
            </Spin>
          )}
        </div>
        <div className="w-full h-full flex flex-col items-start gap-y-3">
          {searchParams?.get("vacancyId")?.trim() === undefined &&
            applications?.length === 0 && (
              <Empty description="No Applications Present" />
            )}
          {searchParams?.get("vacancyId")?.trim() === undefined &&
            applications?.map((vacancy: any) => {
              return (
                <CompanyApplicationCard
                  id={vacancy?.newApplicationId}
                  applicationDate={vacancy?.applieddate?.split("T")[0]}
                  applicationStatusId={vacancy?.applicationStatusId}
                />
              );
            })}
          {searchParams?.get("vacancyId")?.trim() !== undefined &&
            applicationsById?.map((item: any) => {
              return (
                <CompanyApplicationCard
                  id={item?.newApplicationId}
                  applicationDate={item?.applieddate?.split("T")[0]}
                  applicationStatusId={
                    item?.applicationStatus?.applicationStatusId
                  }
                />
              );
            })}
          {applicationsById?.length === 0 &&
            searchParams?.get("vacancyId")?.trim() !== undefined && (
              <Empty description="No Applications Present" />
            )}
        </div>
      </div>
    </Spin>
  );
};

export default CompanyApplications;
