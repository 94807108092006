export const ERROR_PATH = "*";
export const HOME_PATH = "/";
export const MATCHED_INTERNSHIPS_PATH = "/matchedInternships";
export const SEARCH_RESULTS_PATH = "/searchResults";
export const PROFILE_PATH = "/profile";
export const APPLICATIONS_PATH = "/applications";
export const JOB_DETAILS_PATH = "/jobDetails";
export const COMPANIES_PATH = "/companies";
export const COMPANY_DETAILS_PATH = "/companydetails/:id";
export const SUB_PLANS = "/subscription-plans";
export const SUBSCRIPTION_FINISH = "/subscription"

export const DOWNLOAD_CV_PATH = `/cv/:id`;

export const COMPANIES_DASHBOARD_PATH = "/dashboard";
export const COMPANIES_HOME_PATH = `${COMPANIES_DASHBOARD_PATH}/home`;
export const COMPANIES_VACANCIES_PATH = `${COMPANIES_DASHBOARD_PATH}/vacancies`;
export const CREATE_VACANCY_PATH = `${COMPANIES_VACANCIES_PATH}/create`;
export const UPDATE_VACANCY_PATH = `${COMPANIES_VACANCIES_PATH}/update`;
export const COMPANY_PROFILE_PATH = `${COMPANIES_DASHBOARD_PATH}/profile`;
export const COMPANY_APPLICATIONS_PATH = `${COMPANIES_DASHBOARD_PATH}/applications`;
export const COMPANY_VIEW_APPLICATION_PATH = `${COMPANIES_DASHBOARD_PATH}/applications/:id`;
