import { PlusOutlined } from "@ant-design/icons";
import VacancyCard, {
  VacancyCardProps,
} from "components/Companies/VacancyCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CREATE_VACANCY_PATH } from "Routes/paths";
import { listCompanyVacanciesAction } from "redux/actions/vacanciesActions/listCompanyVacanciesAction";
import { RootState } from "redux/rootReducer";
import { Empty, Spin } from "antd";
import { useResponsive } from "hooks/useResponsive";

const Vacancies = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { companyVacanciesLoading, vacancies } = useSelector(
    (state: RootState) => state.listCompanyVacanciesReducer
  );

  const { isMax751 } = useResponsive();

  useEffect(() => {
    dispatch(listCompanyVacanciesAction());
  }, []);

  return (
    <Spin
      wrapperClassName="spin-full-width spin-full-height"
      spinning={companyVacanciesLoading}
    >
      <div className="w-full flex flex-col px-10 py-10">
        <div className="w-full flex flex-row items-center justify-between">
          <p className="font-Avenir_demi text-2xl">Vacancies</p>
          {!isMax751 && (
            <button
              onClick={() => navigate(CREATE_VACANCY_PATH)}
              className="bg-dashboard_primary flex items-center justify-center gap-x-3 px-4 py-4 rounded-full"
            >
              <PlusOutlined className="text-white text-2xl font-bold" />
            </button>
          )}
          {isMax751 && (
            <button
              onClick={() => navigate(CREATE_VACANCY_PATH)}
              className="bg-dashboard_primary rounded-lg flex items-center justify-center gap-x-3 px-4 py-4"
            >
              <p className="text-white font-Avenir_demi">Create Vacancy</p>
              <PlusOutlined className="text-white text-2xl font-bold" />
            </button>
          )}
        </div>
        <div className="mt-5 w-full flex flex-col gap-y-4">
          {vacancies?.length === 0 && (
            <Empty description="No Vacancies Present" />
          )}
          {vacancies?.map((item: any) => {
            return (
              <VacancyCard
                id={item.vacancyId}
                city={item.city?.cityNameEn}
                country={item.country?.countryNameEn}
                positionTitle={item.title}
                jobType={item.vacancyLevelId}
                descriptionBrief={item.description}
                deleteCallback={async () => {
                  dispatch(listCompanyVacanciesAction());
                }}
              />
            );
          })}
        </div>
      </div>
    </Spin>
  );
};

export default Vacancies;
