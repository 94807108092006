import {
  GENERATE_PHONE_OTP_LOADING,
  GENERATE_PHONE_OTP_SUCCESS,
  GENERATE_PHONE_OTP_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const generatePhoneOtpActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GENERATE_PHONE_OTP_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/generate-phone-otp",
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: "OTP sent successfully",
        });
        callback();
      }

      dispatch({ type: GENERATE_PHONE_OTP_SUCCESS, payload: response.data });
    } catch (err: any) {
      dispatch({ type: GENERATE_PHONE_OTP_FAILURE, payload: err });

      notification.error({
        message: `Error: ${err.message}`,
      });
    } finally {
      dispatch({ type: GENERATE_PHONE_OTP_LOADING, payload: false });
    }
  };
