import
{
    LIST_CITIES_LOADING,
    LIST_CITIES_SUCCESS,
    LIST_CITIES_FAILURE
}
from "redux/types";

const initialState = {
    citiesLoading: false,
    cities: null,
    errors: null
}

export const listCitiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CITIES_LOADING :
            return {...state, citiesLoading: action.payload};
        case LIST_CITIES_SUCCESS :
            return {...state, cities: action.payload};
        case LIST_CITIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}