import Button from "../Button";
import { Link, useNavigate } from "react-router-dom";
import { PinIcon } from "assets/icons";
import { BASE_IMAGE_URL } from "constants/constants";

interface JobCompanyProps {
  data: any;
}

function JobCompany({ data }: JobCompanyProps) {
  const navigate = useNavigate();
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className=" w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black]"
    >
      <div className=" flex flex-col gap-[10px]">
        {/* company logo pic  */}
        <img
          src={BASE_IMAGE_URL + data?.matchedVacancy?.company?.coverLogo}
          alt="cover-logo"
          className="w-full min-h-[235px] rounded-tr-[15px] rounded-tl-[15px] object-cover object-center"
        />

        <div className="p-[20px] flex flex-col gap-[20px]">
          {/* company name  */}
          <div className="font-Avenir_demi text-[20px] text-pro_text_dark flex flex-col gap-2">
            <p
              onClick={() =>
                navigate(
                  `/companydetails/${data?.matchedVacancy?.company?.companyId}`
                )
              }
              className="cursor-pointer"
            >
              {data?.matchedVacancy?.company?.companyName}
            </p>
            <p className="text-[14px] font-Avenir_regular text-[#808184]">
              {data?.matchedVacancy?.company?.major?.majorNameEn}
            </p>
          </div>

          {/* location  */}
          <div className="flex items-center gap-[10px] text-pro_text_dark">
            <div className="w-[20px] h-[25px]">
              <img src={PinIcon} alt="location" />
            </div>
            <p className="font-Avenir_regular text-[16px]">
              {data?.matchedVacancy?.company?.country?.countryNameEn},{" "}
              {data?.matchedVacancy?.company?.city?.cityNameEn}
            </p>
          </div>

          {/* description */}
          <div>
            <p className="font-Avenir_regular text-[13px] text-[#444444] min-h-[60px] max-h-[60px] line-clamp-3 text-ellipsis">
              {data?.matchedVacancy?.company?.bio}
            </p>
          </div>

          {/* more about button */}
          <Link
            className="w-full flex justify-center"
            to={`/companydetails/${data?.matchedVacancy?.company?.companyId}`}
          >
            <Button
              bgColor="#DCDEE3"
              fontSize={16}
              px={40}
              py={8}
              textColor="#142644"
              text="More About The Company"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default JobCompany;
