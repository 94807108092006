import {
  CloseIcon,
  LogoIcon,
  erroricon,
  passwordhide,
  passwordshow,
  back,
} from "assets/icons";
import { Flex, Input, Spin, Typography } from "antd";
import type { OTPProps } from "antd/es/input/OTP";
import type { GetProp } from "antd";
import { useEffect, useState } from "react";
import { useUserSignUpFormik } from "forms/JoinUsForms/SginUpForms/userSignUpFormik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import { generatePhoneOtpActions } from "redux/actions/JoinUsAction/SignUp/generatePhoneOtpActions";
import { RootState } from "redux/rootReducer";
import { useUserPhoneValidationFormik } from "forms/JoinUsForms/SginUpForms/userPhoneValidationFormik";
import { validatePhoneOtpAction } from "redux/actions/JoinUsAction/SignUp/validatePhoneOtpActions";

interface UserSignUpProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserSignUp = ({ activePop, setActivePop }: UserSignUpProps) => {
  ///////////////////////////////// states
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [showRestForm, setShowRestForm] = useState(false);
  const [disapbleSendCode, setDisapbleSendCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [country, setCountry] = useState("");

  //////////////////////
  const dispatch: any = useDispatch();
  const formik = useUserSignUpFormik(setActivePop, country);
  const phoneFormik = useUserPhoneValidationFormik(
    country,
    setDisapbleSendCode,
    setShowRestForm
  );

  ///////////////////////////////// count down timer handle
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (showOtpSection === true) {
        setTimer((prev: any) => (prev > 0 ? prev - 1 : 0));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [showOtpSection]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  //////////////////////////////  otp values output
  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {
    if (text.length !== 4) {
      formik.setFieldError("otp", "This field is required");
    } else {
      formik.setFieldValue("otp", text);
    }
  };

  const sharedProps: OTPProps = {
    onChange,
  };

  //////////////////////////////  handle otp loading
  const { phoneOtpLoading } = useSelector(
    (state: RootState) => state.generatePhoneOtpReducer
  );

  const { phoneSignUpLoading } = useSelector(
    (state: RootState) => state.phoneSignUpReducer
  );

  const { validatePhoneOtpLoading } = useSelector(
    (state: RootState) => state.validatePhoneOtpReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/************************* logo section  **********************/}
        <div className="relative flex items-center justify-center">
          <div
            className={`${
              showRestForm ? "absolute top-0 left-0 cursor-pointer" : "hidden"
            }`}
            // onClick={() => setShowRestForm(false)}
          >
            {/* <img src={back} alt="back" /> */}
          </div>
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div>
        </div>

        {/* Let's Begin The Journey */}
        <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
          Let's Begin The Journey
        </div>

        {/*********** phone and password login section  ****************/}
        <Spin spinning={phoneSignUpLoading}>
          <form
            onSubmit={formik?.handleSubmit}
            className="flex flex-col gap-[20px] text-white"
          >
            {/****************** phone number  **************************/}
            <Spin spinning={phoneOtpLoading}>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                    }}
                    country={"eg"}
                    onlyCountries={["eg", "ae"]}
                    dropdownStyle={{
                      color: "black",
                    }}
                    countryCodeEditable={false}
                    disabled={disapbleSendCode}
                    onChange={(phone, x, y, valueseparated) => {
                      // the country code
                      const countryCode = valueseparated.split(" ").shift();

                      if (countryCode === "+20") {
                        setCountry("eg");
                      } else if (countryCode === "+971") {
                        setCountry("uae");
                      }
                      const countryCodeLength = countryCode
                        ?.split("")
                        .filter((item: any, idx: number) => idx !== 0)
                        .join("").length;

                      // phone number
                      const phoneNumber = phone.slice(
                        countryCodeLength,
                        phone.length
                      );

                      phoneFormik.setFieldValue("phone", phoneNumber);
                      formik.setFieldValue("phone", phoneNumber);
                    }}
                  />

                  {/* send code button  */}
                  {!disapbleSendCode && (
                    <button
                      onClick={() => {
                        if (!phoneFormik.errors.phone) {
                          dispatch(
                            generatePhoneOtpActions(
                              {
                                phoneNumber:
                                  country === "eg"
                                    ? `0${formik.values.phone}`
                                    : formik.values.phone,
                              },
                              () => {
                                setDisapbleSendCode(true);
                                setShowOtpSection(true);
                              }
                            )
                          );
                        }
                      }}
                      type="button"
                      className={
                        showRestForm
                          ? "w-[100px] h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[12px] font-Avenir_demi rounded-[15px] flex items-center justify-center "
                          : "w-[100px] h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[12px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300 flex items-center justify-center "
                      }
                      // disabled={showRestForm}
                    >
                      Send code
                    </button>
                  )}
                </div>

                {/* errors section  */}
                <div className="mt-[-5px] w-full flex items-center">
                  {phoneFormik.errors.phone && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {phoneFormik.errors.phone}
                    </p>
                  )}{" "}
                </div>
              </div>
            </Spin>

            {/**************** the rest of the from ********************/}
            {/******************** otp section  *********************/}
            {showOtpSection && (
              <div
                data-aos="fade-up"
                data-aos-duration="400"
                className="flex flex-col gap-4"
              >
                <div className="flex items-center justify-center">
                  <Flex gap="middle" align="flex-start" vertical>
                    <Input.OTP length={4} {...sharedProps} />

                    {/* errors section  */}
                    <div className="mt-[-5px] w-full flex items-center justify-center">
                      {formik.errors.otp && (
                        <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                          <img className="w-[22px]" src={erroricon} alt="" />{" "}
                          {formik.errors.otp}
                        </p>
                      )}{" "}
                    </div>
                  </Flex>
                </div>

                {/* resend the verification code */}
                <div className="font-Avenir_regular text-[14px] text-white text-center border-t-[1px] pt-[10px]">
                  You can resend the verification code after
                  <span className="font-Avenir_bold ml-[10px]">
                    {formatTime(timer)}
                  </span>
                </div>

                {/* Resend the code */}
                {timer === 0 && (
                  <Spin spinning={phoneOtpLoading}>
                    <div
                      onClick={() =>
                        dispatch(
                          generatePhoneOtpActions(
                            {
                              phoneNumber: formik.values.phone,
                            },
                            () => {
                              setTimer(60);
                            }
                          )
                        )
                      }
                      className="font-Avenir_bold text-[14px] text-white flex items-center justify-center underline cursor-pointer hover:text-pro_text_dark duration-300"
                    >
                      Resend the code
                    </div>
                  </Spin>
                )}

                <div className="flex items-center justify-center">
                  <Spin spinning={validatePhoneOtpLoading}>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(
                          validatePhoneOtpAction(
                            {
                              phoneNumber: `0${formik.values.phone}`,
                              otp: `${formik.values.otp}`,
                            },
                            () => {
                              setShowRestForm(true);
                              setShowOtpSection(false);
                            }
                          )
                        );
                      }}
                      className="w-[80px] h-[38px] bg-[#DCDEE3] text-pro_text_dark text-[12px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300 flex items-center justify-center "
                    >
                      Confirm
                    </button>
                  </Spin>
                </div>
              </div>
            )}

            {showRestForm && (
              <>
                {" "}
                {/******************** password **************************/}
                <div
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="relative flex flex-col"
                >
                  <div className="relative flex flex-col">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      onChange={formik.handleChange}
                      className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                    />

                    {/* hide and show password icon  */}
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute top-[33%] right-[5%] cursor-pointer"
                    >
                      {showPassword ? (
                        <img src={passwordhide} alt="hide" />
                      ) : (
                        <img src={passwordshow} alt="show" />
                      )}
                    </div>
                  </div>
                  {/* errors section  */}
                  <div className=" w-full flex items-center">
                    {formik.errors.password && (
                      <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                        <img className="w-[22px]" src={erroricon} alt="" />{" "}
                        {formik.errors.password}
                      </p>
                    )}{" "}
                  </div>
                </div>
                {/******************** confirm password ******************/}
                <div
                  data-aos="fade-up"
                  data-aos-duration="400"
                  className="relative flex flex-col"
                >
                  <div className="relative flex flex-col">
                    <input
                      type={showConfirm ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                    />

                    {/* hide and show password icon  */}
                    <div
                      onClick={() => setShowConfirm(!showConfirm)}
                      className="absolute top-[33%] right-[5%] cursor-pointer"
                    >
                      {showConfirm ? (
                        <img src={passwordhide} alt="hide" />
                      ) : (
                        <img src={passwordshow} alt="show" />
                      )}
                    </div>
                  </div>
                  {/* errors section  */}
                  <div className=" w-full flex items-center">
                    {formik.errors.confirmPassword && (
                      <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                        <img className="w-[22px]" src={erroricon} alt="" />{" "}
                        {formik.errors.confirmPassword}
                      </p>
                    )}{" "}
                  </div>
                </div>
                {/**************** Next button ***********************/}
                <button
                  data-aos="fade-up"
                  data-aos-duration="400"
                  type="submit"
                  className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
                >
                  Next
                </button>
              </>
            )}
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserSignUp;
