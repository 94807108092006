import
{
    GET_COMPANY_ID_LOADING,
    GET_COMPANY_ID_SUCCESS,
    GET_COMPANY_ID_FAILURE
}   
from "redux/types";

const initialState = {
    companyIdLoading: false,
    companyData: null,
    errors: null
}

export const getCompanyIdFromUserIdReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_COMPANY_ID_LOADING:
            return {...state, companyIdLoading: action.payload};
        case GET_COMPANY_ID_SUCCESS :
            return {...state, companyData: action.payload};
        case GET_COMPANY_ID_FAILURE:
            return {...state, errors: action.payload};
        default:
            return state;
    }
}