import {
  ADD_SKILLS_LOADING,
  ADD_SKILLS_SUCCESS,
  ADD_SKILLS_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const addSkillsAction =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ADD_SKILLS_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewUserProfiles/EditFormSkills",
        data,
        "",
        "json"
      );
      dispatch({ type: ADD_SKILLS_SUCCESS, payload: response.data });
      callback();
    } catch (err) {
      dispatch({ type: ADD_SKILLS_FAILURE, payload: err });
    } finally {
      dispatch({ type: ADD_SKILLS_LOADING, payload: false });
    }
  };
