import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DefaultUserImage } from "assets/images";
import { RootState } from "redux/rootReducer";
import SignIn from "./SignIn";
import { BASE_IMAGE_URL } from "constants/constants";
import store from "redux/store";
import ChangePassword from "../Password/ChangePassword/ChangePassword";
import JoinUs from "./JoinUs/JoinUs";

interface LoginTypes {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  formik: any;
}

function BurgerMenu({ showMenu, setShowMenu, formik }: LoginTypes) {
  // this social id just to know if the user loged in with google or email
  const socialId =
    store.getState()?.listProfileDataReducer?.profileData?.user?.socicaID;

  const [profileImage, setProfileImage] = useState(null);

  const dispatch = useDispatch();

  const { token, data, role, isCompleted } = useSelector(
    (state: RootState) => state.signInReducer
  );

  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const { dataComplete } = useSelector(
    (state: RootState) => state.completeRegistrationReducer
  );

  useEffect(() => {
    setProfileImage(profileData?.profileImage);
  }, [profileData]);

  return (
    <div
      className={
        showMenu
          ? "md:hidden duration-300 absolute text-white text-[14px] font-Avenir_regular left-0 top-0 w-full h-screen bg-pro_bg p-[30px] flex flex-col gap-[30px]"
          : "absolute top-0 left-[-100%] w-full h-screen duration-300"
      }
    >
      {/* logo section  */}
      <div className="flex items-center justify-between">
        <img src="images/logowhite.png" alt="logo" />

        <img
          onClick={() => setShowMenu(!showMenu)}
          src="images/close.png"
          alt="close"
        />
      </div>
      {dataComplete?.isCompleted || isCompleted ? (
        <div>
          <div className="flex items-center gap-[20px] pb-[20px] border-b-[2px] border-[#D3D3D3]">
            {/* image section  */}
            <div className="w-[50px] h-[50px] rounded-[50%]">
              <img
                className="w-[50px] h-[50px] rounded-full object-cover"
                referrerPolicy="no-referrer"
                src={
                  profileImage && profileImage !== ""
                    ? encodeURI(BASE_IMAGE_URL + profileImage)
                    : !data || data?.picture === ""
                    ? DefaultUserImage
                    : `${encodeURI(data?.picture)}`
                }
                alt="user"
              />
            </div>

            {/* other user settings  */}
            <div className="flex flex-col gap-1">
              {/* user name  */}
              <p>
                {profileData?.firstName
                  ? formik?.values?.FirstName + " " + formik?.values?.LastName
                  : data?.given_name + " " + data?.family_name}
              </p>
              {/* view the user profile  */}
              <Link to={"/profile"}>View Profile</Link>

              {/* password changing  settings */}
              {/* <ChangePassword /> */}
            </div>
          </div>
        </div>
      ) : (
        // <SignIn signInAlert={false} hidden={false} />
        <JoinUs hidden={false} />
      )}
      {/* links section  */}
      <div className="flex flex-col gap-[10px]">
        <Link to={"/matchedInternships"}>Jobs</Link>
        <Link to={"/companies"}>Companies</Link>
        <Link to={"/applications"}>My Applications</Link>
      </div>
      {/* logout button  */}
      <div>
        {data ? (
          <button
            onClick={() => {
              dispatch({ type: "LOG_OUT" });
            }}
            className="w-full h-[40px] flex items-center justify-center gap-[8px] rounded-[15px] bg-[#DCDEE3] text-pro_text_dark font-Avenir_demi text-[14px]"
          >
            <img className="w-[18px] h-[20px]" src="images/logout.png" alt="" />{" "}
            Logout
          </button>
        ) : (
          ""
        )}
      </div>{" "}
    </div>
  );
}

export default BurgerMenu;
