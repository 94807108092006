import { FC } from "react";
import { BackIcon, CloseIcon, LogoIcon } from "assets/icons";
import { CompanyRegistrationModalProps } from "./CompanyRegistrationOne";
import { Input, Spin } from "antd";
import Button from "../Button";
import { useSelector } from "react-redux";
import { RootState } from "~/redux/rootReducer";

const CompanyRegistrationThree: FC<CompanyRegistrationModalProps> = ({
  setActivePop,
  formik,
}) => {
  const { companyMediaLoading } = useSelector(
    (state: RootState) => state.addCompaniesMediaReducer
  );

  return (
    <section className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10">
      <div className="relative max-h-[80vh] lg:max-h-[100vh] lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] overflow-y-auto">
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          {/* <div
            onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div> */}

          <div
            className="absolute top-0 left-0 cursor-pointer"
          >
          </div>
        </div>

        <p className="text-center text-white font-Avenir_bold text-[25px]">
          Fill Social Media Links
        </p>

        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col item-start gap-y-4"
        >
          <Input
            className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] pl-[15px] focus:outline-none placeholder-white/55"
            type="text"
            name="website_link"
            placeholder="Website Link"
            onChange={formik.handleChange}
          />
          <Input
            className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] pl-[15px] focus:outline-none placeholder-white/55"
            type="text"
            name="facebook_link"
            placeholder="Facebook Link"
            onChange={formik.handleChange}
          />
          <Input
            className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] pl-[15px] focus:outline-none placeholder-white/55"
            type="text"
            name="instagram_link"
            placeholder="Instagram Link"
            onChange={formik.handleChange}
          />
          <Input
            className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] pl-[15px] focus:outline-none placeholder-white/55"
            type="text"
            name="x_link"
            placeholder="X Link"
            onChange={formik.handleChange}
          />
          <Input
            className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] pl-[15px] focus:outline-none placeholder-white/55"
            type="text"
            name="linkedin_link"
            placeholder="Linkedin Link"
            onChange={formik.handleChange}
          />
          <Spin
            wrapperClassName="spin-full-width"
            spinning={companyMediaLoading}
          >
            <Button
              bgColor="#FFFFFF"
              className="w-full"
              fontSize={14}
              px={40}
              py={14}
              textColor="#142644"
              type="submit"
              text="Create Account"
            />
          </Spin>
        </form>
      </div>
    </section>
  );
};

export default CompanyRegistrationThree;
