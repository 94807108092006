import
{
    COMPLETE_REGISTRATION_LOADING,
    COMPLETE_REGISTRATION_SUCCESS,
    COMPLETE_REGISTRATION_FAILURE
}
from "redux/types";

const initialState = {
    completeRegistrationLoading: false,
    dataComplete: null,
    errors: null
};

export const completeRegistrationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPLETE_REGISTRATION_LOADING :
            return {...state, addEducationInfoLoading: action.payload};
        case COMPLETE_REGISTRATION_SUCCESS :
            return {...state, dataComplete: action.payload};
        case COMPLETE_REGISTRATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}