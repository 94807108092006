import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { validateOtpResetPasswordActions } from "redux/actions/JoinUsAction/ResetPasswordActions/validateOtpResetPasswordActions";

interface validateOtpTypes {
  otp: string;
}

export const useValidateOtpFormik = (
  setResetPop: any,
  resetPasswordMethod: any,
  phoneNum: any,
  email: any
) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: yup.object({
      otp: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: validateOtpTypes) => {
      await dispatch(
        validateOtpResetPasswordActions(
          {
            forgotTypeId: resetPasswordMethod,
            phoneNumber: phoneNum,
            email: email,
            otp: values.otp,
          },
          () => {
            setResetPop("resetPassword");
          }
        )
      );
    },
  });

  return formik;
};
