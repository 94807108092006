import {
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "redux/types";

const initialState = {
  changePasswordLoading: false,
  changePasswordSuccess: null,
  errors: null,
};

export const changePasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_PASSWORD_LOADING:
      return { ...state, changePasswordLoading: action.payload };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordSuccess: action.payload };
    case CHANGE_PASSWORD_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
