import { FC } from "react";
import CompaniesSideBar from "components/Companies/CompaniesSideBar";
import { useEffect } from "react";
import CompaniesNavbar from "components/Companies/CompaniesNavbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useNavigate } from "react-router-dom";
import { HOME_PATH } from "Routes/paths";
import { getProfileCompletionAction } from "redux/actions/profileCompletionAction/getProfileCompletionAction";
import { Spin } from "antd";
import { getCompanyIdFromUserIdAction } from "redux/actions/getCompanyIdFromUserIdAction";
import { useResponsive } from "hooks/useResponsive";

interface DashboardProps {
  children: React.ReactNode;
}

const Dashboard: FC<DashboardProps> = ({ children }) => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { userId } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );
  const { profileCompletionLoading } = useSelector(
    (state: RootState) => state.getProfileCompletionReducer
  );
  const { companyIdLoading } = useSelector(
    (state: RootState) => state.getCompanyIdFromUserIdReducer
  );

  const { companyProfileLoading } = useSelector(
    (state: RootState) => state.getCompanyProfileReducer
  );

  const { isMax751 } = useResponsive();

  const checkIsEmployer = async () => {
    await dispatch(
      getProfileCompletionAction(userId, () => {
        navigate(HOME_PATH);
      })
    );
  };

  useEffect(() => {
    dispatch(getCompanyIdFromUserIdAction());
  }, []);

  useEffect(() => {
    if (!userId) {
      navigate(HOME_PATH);
    } else {
      checkIsEmployer();
    }
  }, [userId]);

  return (
    <Spin
      wrapperClassName="spin-full-width spin-full-height"
      spinning={
        profileCompletionLoading || companyIdLoading || companyProfileLoading
      }
    >
      <div className="w-full h-full bg-[#e6edf5] flex flex-row overflow-x-hidden">
        {isMax751 && <CompaniesSideBar />}
        <div
          className={`${
            isMax751 ? "ml-[300px]" : ""
          } w-full h-full flex flex-col overflow-x-hidden overflow-y-hidden`}
        >
          <CompaniesNavbar />
          <div className="w-full h-full pt-[80px] overflow-y-scroll">
            {children}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Dashboard;
