import {
  GENERATE_EMAIL_OTP_LOADING,
  GENERATE_EMAIL_OTP_SUCCESS,
  GENERATE_EMAIL_OTP_FAILURE,
} from "redux/types";

const initialState = {
  generateEmailOtpLoading: false,
  generateEmailOtp: null,
  errors: null,
};

export const generateEmailOtpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GENERATE_EMAIL_OTP_LOADING:
      return { ...state, generateEmailOtpLoading: action.payload };
    case GENERATE_EMAIL_OTP_SUCCESS:
      return { ...state, generateEmailOtp: action.payload };
    case GENERATE_EMAIL_OTP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
