import
{
    GET_PROFILE_COMPLETION_LOADING,
    GET_PROFILE_COMPLETION_SUCCESS,
    GET_PROFILE_COMPLETION_FAILURE
}
from "redux/types";

const initialState = {
    profileCompletionLoading: false,
    completion: null,
    role: null,
    errors: null
}

export const getProfileCompletionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PROFILE_COMPLETION_LOADING :
            return {...state, profileCompletionLoading: action.payload};
        case GET_PROFILE_COMPLETION_SUCCESS :
            return {...state, completion: action.payload.complition, role: action.payload.roles[0]};
        case GET_PROFILE_COMPLETION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}