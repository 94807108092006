import
{
    GET_PROFILE_COMPLETION_LOADING,
    GET_PROFILE_COMPLETION_SUCCESS,
    GET_PROFILE_COMPLETION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const getProfileCompletionAction = (userId: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GET_PROFILE_COMPLETION_LOADING, payload: true});
        const response = await createNewItemService(`api/Auth/UserData?userId=${userId}`, {}, "", "json");
        dispatch({type: GET_PROFILE_COMPLETION_SUCCESS, payload: response.data});
        if (response.data?.roles[0] !== 'Employer') {
            callback && callback();
        }
    } catch (err) {
        dispatch({type: GET_PROFILE_COMPLETION_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_PROFILE_COMPLETION_LOADING, payload: false});
    }
}