import React from "react";

function CatchIntern() {
  const jobCategories = [
    {
      category: "Accounting/Finance Jobs",
      subCategories: [
        "Financial Manager Jobs",
        "Internal Audit Jobs",
        "Treasury Manager Jobs",
      ],
    },
    {
      category: "Administration Jobs",
      subCategories: [
        "Secretary Jobs",
        "Office Manager Jobs",
        "Receptionist Jobs",
      ],
    },
    {
      category: "Human Resources Jobs",
      subCategories: [
        "HR Manager Jobs",
        "Recruitment Specialist Jobs",
        "Training Coordinator Jobs",
      ],
    },
    {
      category: "Information Technology Jobs",
      subCategories: [
        "Software Developer Jobs",
        "System Administrator Jobs",
        "IT Support Specialist Jobs",
      ],
    },
    {
      category: "Marketing Jobs",
      subCategories: [
        "Marketing Manager Jobs",
        "Content Creator Jobs",
        "SEO Specialist Jobs",
      ],
    },
    {
      category: "Customer Service Jobs",
      subCategories: [
        "Customer Service Representative Jobs",
        "Call Center Agent Jobs",
        "Technical Support Jobs",
      ],
    },
    {
      category: "Healthcare Jobs",
      subCategories: [
        "Nurse Jobs",
        "Medical Assistant Jobs",
        "Pharmacy Technician Jobs",
      ],
    },
    {
      category: "Education Jobs",
      subCategories: [
        "Teacher Jobs",
        "School Counselor Jobs",
        "Administrative Assistant Jobs",
      ],
    },
    {
      category: "Engineering Jobs",
      subCategories: [
        "Mechanical Engineer Jobs",
        "Civil Engineer Jobs",
        "Electrical Engineer Jobs",
      ],
    },
    {
      category: "Sales Jobs",
      subCategories: [
        "Sales Manager Jobs",
        "Sales Representative Jobs",
        "Account Executive Jobs",
      ],
    },
    {
      category: "Legal Jobs",
      subCategories: [
        "Paralegal Jobs",
        "Legal Assistant Jobs",
        "Attorney Jobs",
      ],
    },
    {
      category: "Hospitality Jobs",
      subCategories: [
        "Hotel Manager Jobs",
        "Event Planner Jobs",
        "Concierge Jobs",
      ],
    },
    {
      category: "Logistics Jobs",
      subCategories: [
        "Logistics Coordinator Jobs",
        "Warehouse Manager Jobs",
        "Supply Chain Analyst Jobs",
      ],
    },
    {
      category: "Manufacturing Jobs",
      subCategories: [
        "Production Supervisor Jobs",
        "Quality Control Inspector Jobs",
        "Machine Operator Jobs",
      ],
    },
    {
      category: "Construction Jobs",
      subCategories: [
        "Project Manager Jobs",
        "Construction Worker Jobs",
        "Safety Officer Jobs",
      ],
    },
    {
      category: "Creative Jobs",
      subCategories: [
        "Graphic Designer Jobs",
        "Photographer Jobs",
        "Video Editor Jobs",
      ],
    },
  ];

  return (
    <section
      data-aos="fade-up"
      data-aos-duration="900"
      id="category"
      className="pt-[180px]"
    >
      {/* the heading of the section */}
      <div className=" w-full flex items-center justify-center">
        <p className="font-Avenir_bold text-[22px] lg:text-[32px] mb-[40px]">
          Catch your Intrn by Category
        </p>
      </div>

      <section className="w-full bg-home_4 bg-cover bg-no-repeat text-pro_text_dark">
        {/* the grid section */}
        <div className="px-[40px] xl:px-[144px] w-full grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[40px]">
          {jobCategories.map((category, i) => {
            return (
              <div key={i} className="flex flex-col items-center">
                <p className="font-Avenir_demi text-[20px]">
                  {category.category}
                </p>
                <p className="font-Avenir_regular text-[#444444] text-[16px]">
                  {category.subCategories[0]}
                </p>
                <p className="font-Avenir_regular text-[#444444] text-[16px]">
                  {category.subCategories[1]}
                </p>
                <p className="font-Avenir_regular text-[#444444] text-[16px]">
                  {category.subCategories[2]}
                </p>
              </div>
            );
          })}
        </div>
      </section>
    </section>
  );
}

export default CatchIntern;
