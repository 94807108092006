import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import CreateVacancyModel from "models/dashboardModels/CreateVacancyModel";
import { createVacancyAction } from "redux/actions/vacanciesActions/createVacancyAction";
import { useNavigate } from "react-router-dom";
import { COMPANIES_VACANCIES_PATH } from "Routes/paths";
import { useEffect } from "react";
import { notification } from "antd";

const useCreateVacancyFormik = () => {
    const navigate = useNavigate();

    const dispatch: any = useDispatch();

    const { companyId } = useSelector((state: RootState) => state.addSubscriptionPlanReducer);
    const { companyData } = useSelector((state: RootState) => state.getCompanyIdFromUserIdReducer);

    const formik = useFormik({
        initialValues: {
            city_id: "",
            country_id: "",
            title: "",
            requirements: "",
            responsibilities: "",
            vacancy_level_id: "",
            deadline: "",
            skills: null,
            description: "",
            area_id: "",
            major_id: "",
            VacancyWorkPlaceId: ""
        },
        validationSchema: yup.object({
            city_id: yup.string().required('please enter this field'),
            country_id: yup.string().required('please enter this field'),
            title: yup.string().required('please enter this field'),
            requirements: yup.string().required('please enter this field'),
            responsibilities: yup.string().required('please enter this field'),
            vacancy_level_id: yup.string().required('please enter this field'),
            deadline: yup.string().required('please enter this field'),
            description: yup.string().required('please enter this field'),
            area_id: yup.string().required('please enter this field'),
            major_id: yup.string().required('please enter this field'),
            VacancyWorkPlaceId: yup.string().required('please enter this field')
        }),
        onSubmit: async (values: CreateVacancyModel) => {
          await dispatch(createVacancyAction({
            companyId: companyId === null ? companyData?.companyId : companyId,
            countryId: values.country_id,
            cityId: values.city_id,
            title: values.title,
            responsbilities: values.responsibilities,
            vacancyLevelId: values.vacancy_level_id,
            requirements: values.requirements,
            deadline: values.deadline,
            skillIds: values.skills,
            description: values.description,
            areaId: values.area_id,
            majorId: values.major_id,
            VacancyWorkPlaceId: values.VacancyWorkPlaceId
          }, () => {
            notification.success({
              message: "Vacancy Created Successfully",
              description: "Your vacancy will now appear for all aplicants"
            });
            navigate(COMPANIES_VACANCIES_PATH);
          }))
        },
      });
  
  return formik;
}

export {
  useCreateVacancyFormik
}