import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUniversitiesAction } from "redux/actions/universitesActions/listUniversitiesAction";
import { RootState } from "redux/rootReducer";
import { listFacultiesAction } from "redux/actions/facultiesActions/listFacultiesAction";
import { listCountriesAction } from "redux/actions/countriesActions/listCountriesAction";
import { listMajorsDropdownAction } from "redux/actions/majorsActions/listMajorsDropdownAction";

const useDropdown = () => {
  const dispatch: any = useDispatch();

  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const { universitiesLoading, universities } = useSelector(
    (state: RootState) => state.listUniversitiesReducer
  );
  const { facultiesLoading, faculties } = useSelector(
    (state: RootState) => state.listFacultiesReducer
  );
  const { countriesLoading, countries } = useSelector(
    (state: RootState) => state.listCountriesReducer
  );
  const { majorsLoading, majors } = useSelector(
    (state: RootState) => state.listMajorsDropdownReducer
  );

  const universitiesDropdown = universities?.map((item: any) => {
    return {
      label: item.universityNameEn,
      value: item.universityId,
    };
  });

  const facultiesDropdown = faculties?.map((item: any) => {
    return {
      label: item.facultyNameEn,
      value: item.facultyId,
    };
  });

  const countryDropdown = countries?.map((item: any) => {
    return {
      label: item.countryNameEn,
      value: item.countryId,
    };
  });

  const majorsDropdown =
    Array.isArray(majors) &&
    majors?.map((item: any) => {
      return {
        label: item?.majorNameEn,
        value: item?.majorId,
      };
    });

  const yearsDropdown = generateYears(1900).map((item: number) => {
    return {
      label: item,
      value: item,
    };
  });

  useEffect(() => {
    dispatch(listUniversitiesAction());
    // dispatch(listFacultiesAction("1"));
    dispatch(listCountriesAction());
    dispatch(listMajorsDropdownAction());
  }, []);

  return {
    universitiesLoading,
    universitiesDropdown,
    facultiesLoading,
    facultiesDropdown,
    countriesLoading,
    countryDropdown,
    majorsLoading,
    majorsDropdown,
    yearsDropdown,
  };
};

export { useDropdown };
