import 
{
    UPDATE_PAYMENT_STATUS_LOADING,
    UPDATE_PAYMENT_STATUS_SUCCESS,
    UPDATE_PAYMENT_STATUS_FAILURE,
}
from "redux/types";

import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";

export const updatePaymentStatusAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: UPDATE_PAYMENT_STATUS_LOADING, payload: true });
    const response = await createNewItemService(
      `/api/PaymentStatus/UpdatePaymentStatus?paymentStatus=${data?.paymentStatus}&userId=${data?.userId}`,
      {},
      "",
      "json"
    );
    dispatch({ type: UPDATE_PAYMENT_STATUS_SUCCESS, payload: response });
  } catch (err) {
    dispatch({ type: UPDATE_PAYMENT_STATUS_FAILURE, payload: err });
  } finally {
    dispatch({ type: UPDATE_PAYMENT_STATUS_LOADING, payload: false });
  }
    
}