import {
  CloseIcon,
  LogoIcon,
  passwordhide,
  passwordshow,
  erroricon,
} from "assets/icons/index";
import { useState } from "react";
import { useUserLoginFormik } from "forms/JoinUsForms/SginInForms/userLoginFormik";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import GenerateOtpResetPassword from "components/Header/JoinUs/ResetPassword/GenerateOtpResetPassword";
import ValidateOtpResetPassword from "components/Header/JoinUs/ResetPassword/ValidateOtpResetPassword";
import ResetPassword from "components/Header/JoinUs/ResetPassword/ResetPassword";
import ResetPasswordMethod from "../ResetPassword/ResetPasswordMethod";

interface UserLoginProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserLogin = ({ activePop, setActivePop }: UserLoginProps) => {
  const formik = useUserLoginFormik(setActivePop);
  const [showPassword, setShowPassword] = useState(false);

  const [resetPop, setResetPop] = useState("");
  const [resetPasswordMethod, setResetPasswordMethod] = useState(0);

  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  const { signInLoading } = useSelector(
    (state: RootState) => state.signInReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div>
        </div>

        {/* Let's Begin The Journey */}
        <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
          Let's Begin The Journey
        </div>

        {/**************** phone and password login section  ****************/}
        <Spin spinning={signInLoading}>
          <form
            onSubmit={formik?.handleSubmit}
            className="flex flex-col gap-[20px] text-white"
          >
            {/******************** phone  **************************/}
            <div className="flex flex-col items-start gap-3">
              <input
                name="phone"
                type="text"
                placeholder="Phone"
                onChange={formik.handleChange}
                className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
              />

              {/* errors section  */}
              <div className="mt-[-10px] w-full flex items-center">
                {formik.errors.phone && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.phone}
                  </p>
                )}
              </div>
            </div>

            {/******************** password **************************/}
            <div className="relative flex flex-col gap-3">
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={formik.handleChange}
                className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
              />

              {/* hide and show password icon  */}
              <div
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-[33%] right-[5%] cursor-pointer"
              >
                {showPassword ? (
                  <img src={passwordhide} alt="hide" />
                ) : (
                  <img src={passwordshow} alt="show" />
                )}
              </div>

              {/* errors section  */}
              <div className="mt-[-10px] w-full flex items-center">
                {formik.errors.password && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.password}
                  </p>
                )}
              </div>
            </div>

            {/**************** Don't have account ********************/}
            <div className="font-Avenir_demi text-[14px] flex items-center justify-end  ">
              <p
                onClick={() => setActivePop("userSignUp")}
                className="hover:text-pro_text_dark duration-300 cursor-pointer"
              >
                Don't have an account?
              </p>
            </div>

            {/**************** Forget Password ? ***********************/}
            <div className="font-Avenir_demi text-[14px] flex items-center justify-end  ">
              <p
                onClick={() => setResetPop("resetPasswordMethod")}
                className="hover:text-pro_text_dark duration-300 cursor-pointer"
              >
                Forgot Password?
              </p>
            </div>

            {/**************** login button ***********************/}
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Login
            </button>
          </form>
        </Spin>
      </div>

      {/* ************************************************************** */}
      {/* ************************************************************** */}
      {/* ************************************************************** */}
      {/* ************************************************************** */}
      {/* ******************** Reset Password form  ******************** */}
      {/* ************************************************************** */}
      {/* ************************************************************** */}
      {/* ************************************************************** */}
      {/* ************************************************************** */}

      {resetPop === "resetPasswordMethod" ? (
        <ResetPasswordMethod
          resetPop={resetPop}
          setResetPop={setResetPop}
          resetPasswordMethod={resetPasswordMethod}
          setResetPasswordMethod={setResetPasswordMethod}
        />
      ) : resetPop === "generateOtpResetPassword" ? (
        <GenerateOtpResetPassword
          resetPop={resetPop}
          setResetPop={setResetPop}
          resetPasswordMethod={resetPasswordMethod}
          setEmail={setEmail}
          setPhoneNum={setPhoneNum}
        />
      ) : resetPop === "validateOtpResetPassword" ? (
        <ValidateOtpResetPassword
          resetPop={resetPop}
          setResetPop={setResetPop}
          email={email}
          phoneNum={phoneNum}
          resetPasswordMethod={resetPasswordMethod}
        />
      ) : resetPop === "resetPassword" ? (
        <ResetPassword
          resetPop={resetPop}
          setResetPop={setResetPop}
          email={email}
          phoneNum={phoneNum}
          resetPasswordMethod={resetPasswordMethod}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default UserLogin;
