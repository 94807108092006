import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addWorkLocationAction } from "redux/actions/userRegistrationActions/addWorkLocationAction";

interface userWorkLocationFormik {
  country: string;
  city: string;
  area: string;
  location_type: string;
}

export const useUserWorkLocationFormik = (setActivePop: any) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      country: "",
      city: "",
      area: "",
      location_type: "",
    },
    validationSchema: yup.object({
      country: yup.string().required("This field is required"),
      city: yup.string().required("This field is required"),
      area: yup.string().required("This field is required"),
      location_type: yup.string().required("This field is required"),
    }),
    onSubmit: async (values: userWorkLocationFormik) => {
      await dispatch(
        addWorkLocationAction(
          {
            countryId: values.country,
            cityId: values.city,
            jobLocationTypeId: values.location_type,
            areaId: values.area,
          },
          () => {
            setActivePop("userFieldsOfWork");
          }
        )
      );
    },
  });

  return formik;
};
