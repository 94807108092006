import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import { DatePicker, Input, Select, Spin, Tooltip } from "antd";
import { useDropdown } from "hooks/useDropdown";
import { DownArrowBlack } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { listCitiesAction } from "redux/actions/citiesActions/listCitiesAction";
import { RootState } from "redux/rootReducer";
import { listSkillsAction } from "redux/actions/skillsAction/listSkillsAction";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useUpdateVacancyFormik } from "forms/dashboardForms/updateVacancyFormik";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { listAreasAction } from "redux/actions/areasActions/listAreasAction";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function createMarkup(html: any) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

const UpdateVacancy = () => {
  const dispatch: any = useDispatch();

  const formik = useUpdateVacancyFormik();

  const { TextArea } = Input;

  const [editorState, setEditorState] = useState<any>(() => {
    const contentBlocks = convertFromHTML(
      formik.values.responsibilities
    ).contentBlocks;
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    return EditorState.createWithContent(contentState);
  });
  const [responsibiliesHtml, setResponsibilitiesHtml] = useState<any>();

  const [editorState2, setEditorState2] = useState<any>(() => {
    const contentBlocks = convertFromHTML(
      formik.values.requirements
    ).contentBlocks;
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    return EditorState.createWithContent(contentState);
  });
  const [jobRequirementsHtml, setJobRequirementsHtml] = useState<any>();

  const { updateVacancyLoading } = useSelector(
    (state: RootState) => state.updateVacancyReducer
  );

  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const { countriesLoading, countryDropdown } = useDropdown();
  const { skillsLoading, skills } = useSelector(
    (state: RootState) => state.listSkillsReducer
  );

  const { areasLoading, areas } = useSelector(
    (state: RootState) => state.listAreasReducer
  );

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.cityNameEn,
      value: item.cityId,
    };
  });

  const skillsDropdown = skills?.map((item: any) => {
    return {
      label: item?.skillNameEn,
      value: item?.skillId,
    };
  });

  const areasDropdown = areas?.map((item: any) => {
    return {
      label: item.areaNameEn,
      value: item.areaId,
    };
  });

  const JOB_TYPES = [
    {
      id: 1,
      label: "Internship",
    },
    {
      id: 2,
      label: "Entry-Level",
    },
  ];

  const JOB_LOCATION = [
    {
      id: 1,
      label: "On-Site",
    },
    {
      id: 2,
      label: "Remote",
    },
    {
      id: 3,
      label: "Hybrid",
    },
  ];

  const [selectedJobType, setSelectedJobType] = useState(
    formik.values.vacancy_level_id
  );
  const [selectedJobLocationType, setSelectedJobLocationType] = useState(formik.values.VacancyWorkPlaceId);


  const { majorsLoading, majorsDropdown } = useDropdown();

  useEffect(() => {
    dispatch(listCitiesAction("1"));
    dispatch(listSkillsAction());
    dispatch(listAreasAction("1"));
  }, []);

  return (
    <div className="w-full h-full flex flex-col gap-y-10 px-10 py-10 mb-10">
      <p className="font-Avenir_demi text-xl">Update Vacancy Form</p>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full h-full flex flex-col gap-y-5"
      >
        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Position Title</label>
          <Input
            placeholder="Position Title"
            name="title"
            className="w-[50%] !h-[2.8rem] !border-black"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          {formik.errors.title && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.title}
            </p>
          )}
        </div>
        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">
            Position Description
          </label>
          <TextArea
            autoSize={{ minRows: 6, maxRows: 10 }}
            placeholder="Position Description"
            name="description"
            className="w-[50%] !border-black overflow-hidden"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          {formik.errors.description && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.description}
            </p>
          )}
        </div>
        <div className="w-full flex flex-row items-center justify-start gap-x-10">
          <div className="w-[40%] flex flex-col items-start justify-center">
            <label className="font-Avenir_regular text-lg">Country</label>
            <Spin
              wrapperClassName="spin-full-width"
              spinning={countriesLoading}
            >
              <Select
                className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
                placeholder="Country"
                suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
                options={countryDropdown}
                value={formik.values.country_id}
                onSelect={(value: any) => {
                  formik.setFieldValue("country_id", value);
                  dispatch(listCitiesAction(value?.toString()));
                }}
              />
            </Spin>
            {formik.errors.country_id && (
              <p className="text-[red] font-Avenir_regular">
                {formik.errors.country_id}
              </p>
            )}
          </div>
          <div className="w-[40%] flex flex-col items-start justify-center">
            <label className="font-Avenir_regular text-lg">City</label>
            <Spin wrapperClassName="spin-full-width" spinning={citiesLoading}>
              <Select
                className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
                placeholder="City"
                suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
                value={formik.values.city_id}
                options={citiesDropdown}
                onSelect={(value: any) => {
                  formik.setFieldValue("city_id", value);
                  dispatch(listAreasAction(value));
                }}
              />
            </Spin>
            {formik.errors.city_id && (
              <p className="text-[red] font-Avenir_regular">
                {formik.errors.city_id}
              </p>
            )}
          </div>
        </div>
        <div className="w-[50%] flex flex-col items-start justify-center">
          <label className="font-Avenir_regular text-lg">Area</label>
          <Spin wrapperClassName="spin-full-width" spinning={areasLoading}>
            <Select
              className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Area"
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              options={areasDropdown}
              value={formik.values.area_id}
              onSelect={(value: any) => {
                formik.setFieldValue("area_id", value);
              }}
            />
          </Spin>
          {formik.errors.area_id && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.area_id}
            </p>
          )}
        </div>
        <div className="w-[50%] flex flex-col items-start justify-center">
          <label className="font-Avenir_regular text-lg">Field</label>
          <Spin wrapperClassName="spin-full-width" spinning={majorsLoading}>
            <Select
              className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Field"
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              value={formik.values.major_id}
              options={
                majorsDropdown ? majorsDropdown : [{ label: "", value: "" }]
              }
              onSelect={(value: any) => {
                formik.setFieldValue("major_id", value);
              }}
            />
          </Spin>
          {formik.errors.major_id && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.major_id}
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">Job Type</label>
          <div className="flex flex-row items-center gap-x-3">
            {JOB_TYPES.map((item: any) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue("vacancy_level_id", item.id);
                    setSelectedJobType(item.id);
                  }}
                  className={`px-6 py-4 flex items-center justify-center border-2 border-dashboard_primary rounded-lg ${
                    selectedJobType === item.id
                      ? "bg-dashboard_primary"
                      : "bg-transparent"
                  }`}
                >
                  <p
                    className={`${
                      selectedJobType === item.id ? "text-white" : "text-black"
                    }`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
          {formik.errors.vacancy_level_id && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.vacancy_level_id}
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">
            Job Location Type
          </label>
          <div className="flex flex-row items-center gap-x-3">
            {JOB_LOCATION.map((item: any) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue("VacancyWorkPlaceId", item.id);
                    setSelectedJobLocationType(item.id);
                  }}
                  className={`px-6 py-4 flex items-center justify-center border-2 border-dashboard_primary rounded-lg ${
                    selectedJobLocationType === item.id
                      ? "bg-dashboard_primary"
                      : "bg-transparent"
                  }`}
                >
                  <p
                    className={`${
                      selectedJobLocationType === item.id
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
          {formik.errors.vacancy_level_id && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.vacancy_level_id}
            </p>
          )}
        </div>
        <div className="w-[50%] flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">Skills Required</label>
          <Spin wrapperClassName="spin-full-width" spinning={skillsLoading}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                (option?.label ?? "").includes(input)
              }
              suffixIcon={<img src={DownArrowBlack} alt="down-arrow-icon" />}
              mode="multiple"
              value={formik.values.skills}
              onChange={(value: any) => {
                formik.setFieldValue("skills", value);
              }}
              options={skillsDropdown}
              className="w-full !min-h-[48px] border !border-black !rounded-[15px] !caret-black !text-black placeholder:!text-gray-400"
              placeholder="Search For Skills..."
            />
          </Spin>
          {formik.errors.skills && (
            <p className="text-[red] font-Avenir_regular">
              please enter this field
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start">
          <div className="w-full flex flex-row items-center gap-x-3">
            <label className="font-Avenir_regular text-lg">
              Responsibilities
            </label>
            <Tooltip
              className="hover:cursor-pointer"
              title="It's recommended to use bullet points to describe the job's responsibilities"
            >
              <InfoCircleOutlined className="text-dashboard_gray" />
            </Tooltip>
          </div>
          <div className="w-[80%] shadow-lg">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
            />
          </div>
          {formik.errors.responsibilities && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.responsibilities}
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start">
          <div className="w-full flex flex-row items-center gap-x-3">
            <label className="font-Avenir_regular text-lg">
              Job Requirements
            </label>
            <Tooltip
              className="hover:cursor-pointer"
              title="It's recommended to use bullet points to describe the job's requirements"
            >
              <InfoCircleOutlined className="text-dashboard_gray" />
            </Tooltip>
          </div>
          <div className="w-[80%] shadow-lg">
            <Editor
              editorState={editorState2}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState2}
            />
          </div>
          {formik.errors.requirements && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.requirements}
            </p>
          )}
        </div>

        <div className="w-[50%] flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">
            Registration Deadline
          </label>
          <DatePicker
            className="!h-[48px] rounded-[15px] bg-transparent w-full !border-black !text-black hover:!bg-transparent"
            placeholder="Registration Deadline"
            allowClear={false}
            value={dayjs(formik.values.deadline)}
            onChange={(date, dateString) => {
              formik.setFieldValue("deadline", dateString);
            }}
          />
          {formik.errors.deadline && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.deadline}
            </p>
          )}
        </div>

        <div className="w-full flex items-center justify-center">
          <Spin className="spin-full-width" spinning={updateVacancyLoading}>
            <button
              type="button"
              onClick={() => {
                if (editorState) {
                  formik.setFieldValue(
                    "responsibilities",
                    convertToHTML(editorState?.getCurrentContent())
                  );
                }

                if (editorState2) {
                  formik.setFieldValue(
                    "requirements",
                    convertToHTML(editorState2?.getCurrentContent())
                  );
                }

                formik.submitForm();
              }}
              className="bg-dashboard_primary w-full rounded-lg px-6 py-3 mb-10"
            >
              <p className="text-white">Update Vacancy</p>
            </button>
          </Spin>
        </div>
      </form>
    </div>
  );
};

export default UpdateVacancy;
