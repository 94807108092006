import
{
    ADD_USER_INFO_LOADING,
    ADD_USER_INFO_SUCCESS,
    ADD_USER_INFO_FAILURE
}
from "redux/types";

const initialState = {
    addUserInfoLoading: false,
    userProfileId: null,
    success: null,
    errors: null
}

export const addUserInfoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_USER_INFO_LOADING :
            return {...state, addUserInfoLoading: action.payload};
        case ADD_USER_INFO_SUCCESS :
            return {...state, success: action.payload, userProfileId: action.payload.userProfileId};
        case ADD_USER_INFO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}