import CryptoJS from "crypto-js";

const generateSignature = (concatenatedString: string) => {
  // Your merchant secured hash as key (replace with your actual key)
  const merchantHashKey = process.env.REACT_APP_MERCHANT_SECURE_HASH!;

  // Calculate HMAC using SHA256
  const hmac = CryptoJS.HmacSHA256(concatenatedString, merchantHashKey);

  // Convert HMAC bytes to hex
  return hmac.toString(CryptoJS.enc.Hex);
};

export const generatePaymentLink = (data: any) => {
  const signature = generateSignature(
    data.totalAmount + data.userId + "1" + data.items[0].itemPrice
  );

  const requestbody = `
    {
        "currency": "EGP",
        "amount": "${data.totalAmount}",
        "paymentMethod": "",
        "language": "ar-eg",
        "order": {
        "merchantOrderId": "${data.userId}",
        "items": [
        {
        "code": "${data.items[0].itemCode}",
        "name": "${data.items[0].itemName}",
        "quantity": 1,
        "description": "",
        "price": "${data.items[0].itemPrice}"
        }
        ],
        "orderSummary": ""
        },
        "customProperties": [
        {
        "serviceCode": "E-Commerce",
        "accountId": "11111"
        }
        ],
        "shipping": {
        "address": {
        "city": "city name",
        "country": "Country name",
        "street": "st name",
        "apartment": "3",
        "floor": "3",
        "building": "2",
        "postalCode": "12345"
        }
        },
        "customFields": [
        {
        "fieldKey": "CONTACT_INFO",
        "fieldParams": {
        "customerName":"Contact",
        "customerMobile": "01000000000"
        }
        }
        ],
        "signature": "${signature}"
       }
    `;

  const base64Encoded = btoa(requestbody);

  return `${process.env.REACT_APP_PAYMENT_LINK_PREFIX}${base64Encoded}`;
};
