import {
  UPLOAD_CV_LOADING,
  UPLOAD_CV_SUCCESS,
  UPLOAD_CV_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import store from "redux/store";

export const uploadCvAction =
  (file: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPLOAD_CV_LOADING, payload: true });
      const response = await createNewItemService(
        `api/UploadCV/upload-cv`,
        {
          UserId: store.getState()?.listProfileDataReducer?.profileData?.userId,
          CVFile: file,
        },
        "",
        "formData"
      );
      dispatch({ type: UPLOAD_CV_SUCCESS, payload: response.data });
      callback();
    } catch (err) {
      dispatch({ type: UPLOAD_CV_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPLOAD_CV_LOADING, payload: false });
    }
  };
