import { BellOutlined } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { FC, useEffect, useState } from "react";
import { DefaultUserImage } from "assets/images";
import {
  BlackCloseIcon,
  CloseIcon,
  CompanyProfileIcon,
  HamburgerMenuIcon,
  RedLogoutIcon,
} from "assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { COMPANY_PROFILE_PATH } from "Routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { useResponsive } from "hooks/useResponsive";
import {
  BASE_IMAGE_URL,
  SIDE_BAR_ITEMS,
  SidebarItemsProps,
} from "constants/constants";
import { getCompanyProfileAction } from "redux/actions/companyActions/getCompanyProfileAction";
import { RootState } from "redux/rootReducer";

interface CompaniesNavbarProps {}

const CompaniesNavbar: FC<CompaniesNavbarProps> = ({}) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const dispatch: any = useDispatch();

  const { isMax751 } = useResponsive();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState("");

  const { companyData } = useSelector(
    (state: RootState) => state.getCompanyIdFromUserIdReducer
  );
  const { companyId } = useSelector(
    (state: RootState) => state.addSubscriptionPlanReducer
  );

  useEffect(() => {
    if (window.location.pathname.includes("home")) {
      setSelectedItem("Home");
      return;
    }
    if (window.location.pathname.includes("vacancies")) {
      setSelectedItem("Vacancies");
      return;
    }
    if (window.location.pathname.includes("profile")) {
      setSelectedItem("Profile");
      return;
    }
    if (window.location.pathname.includes("applications")) {
      setSelectedItem("Applications");
      return;
    }
  }, [pathname]);

  return (
    <>
      <div
        style={{
          width: isMax751 ? "calc(100% - 300px)" : "calc(100%)",
        }}
        className="z-10 fixed top-0 right-0 min-h-[80px] h-[80px] bg-dashboard_primary flex flex-row-reverse items-center justify-between px-6"
      >
        {!isMax751 && (
          <button
            onClick={() => setDrawerOpen(true)}
            className="w-[2rem] h-[2rem]"
          >
            <img src={HamburgerMenuIcon} />
          </button>
        )}
        {isMax751 && (
          <button>
            <Badge dot>
              <BellOutlined className="text-dashboard_secondary text-xl" />
            </Badge>
          </button>
        )}
        <div className="flex items-center gap-x-3">
          <button
            type="button"
            className="bg-dashboard_secondary rounded-lg px-5 py-3 flex items-center justify-center"
          >
            <p className="text-black font-Avenir_demi">Upgrade Membership</p>
          </button>
          <Popover
            content={
              <div className="w-full flex flex-col items-start bg-white gap-y-3 py-3">
                <div
                  onClick={() => navigate(COMPANY_PROFILE_PATH)}
                  className={`w-[10rem] h-[2.3rem] flex flex-row px-3 items-center justify-start gap-x-2 hover:bg-[--blue-opacity-10] hover:cursor-pointer rounded-sm`}
                >
                  <img src={CompanyProfileIcon} alt="profile-icon" />
                  <p className="text-dashboard_gray">My Profile</p>
                </div>
                <div
                  onClick={() => {
                    dispatch({ type: "LOG_OUT" });
                  }}
                  className={`w-[10rem] h-[2.3rem] flex flex-row px-3 items-center justify-start gap-x-2 hover:bg-[--blue-opacity-10] hover:cursor-pointer rounded-sm`}
                >
                  <img src={RedLogoutIcon} alt="profile-icon" />
                  <p className="text-dashboard_gray">Logout</p>
                </div>
              </div>
            }
          >
            <img
              src={
                companyData?.profileLogo
                  ? encodeURI(BASE_IMAGE_URL + companyData?.profileLogo)
                  : DefaultUserImage
              }
              alt="user-icon"
              className="w-[2.8rem] h-[2.8rem] rounded-full"
            />
          </Popover>
        </div>
      </div>
      <div
        className={`${
          drawerOpen ? "w-full h-full" : "w-[0px] h-full"
        } absolute top-0 left-0 z-10 transition-all duration-100 bg-dashboard_primary flex flex-col items-start`}
      >
        {drawerOpen && (
          <div className="w-full flex flex-col items-start gap-y-8 px-6 py-10 duration-100">
            <button
              className="w-[1.5rem] h-[1.5rem]"
              onClick={() => setDrawerOpen(false)}
            >
              <img src={CloseIcon} alt="close-icon" className="w-full h-full" />
            </button>
            <div className="w-full h-full flex flex-col items-center gap-y-3">
              {SIDE_BAR_ITEMS.map((item: SidebarItemsProps) => {
                return (
                  <button
                    onClick={async () => {
                      if (item.name === "Home") {
                        await dispatch(
                          getCompanyProfileAction(
                            companyId === null
                              ? companyData?.companyId
                              : companyId
                          )
                        );
                      } else if (item.name === "Profile") {
                        await dispatch(
                          getCompanyProfileAction(
                            companyId === null
                              ? companyData?.companyId
                              : companyId
                          )
                        );
                      }
                      navigate(item.route);
                      setDrawerOpen(false);
                    }}
                    className={`w-[90%] py-3 px-3 rounded-lg flex flex-row items-center justify-start gap-x-3 ${
                      selectedItem === item.name
                        ? "bg-dashboard_secondary"
                        : "bg-transparent"
                    }`}
                  >
                    <img
                      className="w-[1.3rem] h-[1.3rem]"
                      src={
                        selectedItem === item.name
                          ? item.selectedIcon
                          : item.icon
                      }
                      alt="dashboard-icon"
                    />
                    <p
                      className={`${
                        selectedItem === item.name ? "text-black" : "text-white"
                      }`}
                    >
                      {item.name}
                    </p>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompaniesNavbar;
