import
{
    APPLY_TO_JOB_LOADING,
    APPLY_TO_JOB_SUCCESS,
    APPLY_TO_JOB_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const applyToJobAction = (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: APPLY_TO_JOB_LOADING, payload: true});
        const response = await createNewItemService(`api/applications/apply?userId=${data?.userId}&vacancyId=${data?.vacancyId}`, {}, "", "json");
        if (response.status !== 200) {
            throw Error('err');
        }
        dispatch({type: APPLY_TO_JOB_SUCCESS, payload: response.data});
        callback();
    } catch (err) {
        
        dispatch({type: APPLY_TO_JOB_FAILURE, payload: err});
        notification.error({
            message: 'Already Applied For This Job',
            description: 'Sorry, but you have already applied for this job'
        });
    } finally {
        dispatch({type: APPLY_TO_JOB_LOADING, payload: false});
    }
}