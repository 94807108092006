import {
  UPLOAD_CV_LOADING,
  UPLOAD_CV_SUCCESS,
  UPLOAD_CV_FAILURE,
} from "redux/types";

const initialState = {
  uploadCvLoading: null,
  uploadCvSucces: null,
  uploadCvFailure: null,
};

export const uploadCvReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPLOAD_CV_LOADING:
      return { ...state, uploadCvLoading: action.payload };
    case UPLOAD_CV_SUCCESS:
      return { ...state, uploadCvSuccess: action.payload };
    case UPLOAD_CV_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
