import { combineReducers } from "redux";
import { googleLoginReducer } from "redux/reducers/googleLoginReducer";
import { setNavbarBackground } from "redux/reducers/setNavbarBackground";
import { listUniversitiesReducer } from "redux/reducers/universitiesReducers/listUniversitiesReducer";
import { listFacultiesReducer } from "redux/reducers/facultiesReducers/listFacultiesReducer";
import { listCountriesReducer } from "redux/reducers/countriesReducers/listCountriesReducer";
import { listCitiesReducer } from "redux/reducers/citiesReducers/listCitiesReducer";
import { registerUserInfoReducer } from "redux/reducers/registerUserInfoReducer/registerUserInfoReducer";
import { listMajorsReducer } from "redux/reducers/majorsReducers/listMajorsReducer";
import { listSkillsReducer } from "redux/reducers/skillsReducers/listSkillsReducer";
import { addSubscriptionPlanReducer } from "redux/reducers/companiesReducers/addSubscriptionPlanReducer";
import { addCompanyNameImageReducer } from "redux/reducers/companiesReducers/addCompanyNameImageReducer";
import { addCompaniesInfoReducer } from "redux/reducers/companiesReducers/addCompaniesInfoReducer";
import { addCompaniesMediaReducer } from "redux/reducers/companiesReducers/addCompaniesMediaReducer";
import { getProfileCompletionReducer } from "redux/reducers/profileCompletionReducer/getProfileCompletionReducer";
import { addUserInfoReducer } from "redux/reducers/userRegistrationReducers/addUserInfoReducer";
import { addEducationInfoReducer } from "redux/reducers/userRegistrationReducers/addEducationInfoReducer";
import { addExperienceReducer } from "redux/reducers/userRegistrationReducers/addExperienceReducer";
import { addWorkLocationReducer } from "redux/reducers/userRegistrationReducers/addWorkLocationReducer";
import { addMajorsReducer } from "redux/reducers/userRegistrationReducers/addMajorsReducer";
import { addSkillsReducer } from "redux/reducers/userRegistrationReducers/addSkillReducer";
import { completeRegistrationReducer } from "redux/reducers/completeRegistrationReducer";
import { MatchedInternsReducer } from "./reducers/matchedInterns/matchedInternsReducer";
import { JobDetailsReducer } from "./reducers/getJobById/getJobByIdReducer";
import { createVacancyReducer } from "redux/reducers/vacanciesReducers/createVacancyReducer";
import { listCompanyVacanciesReducer } from "redux/reducers/vacanciesReducers/listCompanyVacanciesReducer";
import { getVacancyReducer } from "redux/reducers/vacanciesReducers/getVacancyReducer";
import { updateVacancyReducer } from "redux/reducers/vacanciesReducers/updateVacancyReducer";
import { getCompanyReducer } from "redux/reducers/companiesReducers/getCompanyReducer";
import { getCompanyIdFromUserIdReducer } from "redux/reducers/getCompanyIdFromUserIdReducer";
import { getCompanyProfileReducer } from "redux/reducers/companiesReducers/getCompanyProfileReducer";
import { updateCompanyProfileReducer } from "redux/reducers/companiesReducers/updateCompanyProfileReducer";
import { deleteVacancyReducer } from "redux/reducers/vacanciesReducers/deleteVacancyReducer";
import { listApplicationsReducer } from "redux/reducers/applicationsReducers/listApplicationsReducer";
import { applyToJobReducer } from "redux/reducers/applicationsReducers/applyToJobReducer";
import { listMyApplicationsReducer } from "redux/reducers/applicationsReducers/listMyApplicationsReducer";
import { listCompaniesReducer } from "redux/reducers/companiesReducers/listCompaniesReducer";
import { listCompanyDetailsReducer } from "redux/reducers/companiesReducers/listCompanyDetailsReducer";
import { listProfileDataReducer } from "redux/reducers/profileDataReducers/listProfileDataReducer";
import { updateUserProfileReducer } from "redux/reducers/profileDataReducers/updateUserProfileReducer";
import { listSingleApplicationReducer } from "redux/reducers/applicationsReducers/listSingleApplcationReducer";
import { updateApplicationStatusReducer } from "redux/reducers/applicationsReducers/updateApplicationStatusReducer";
import { getApplicationsByIdReducer } from "redux/reducers/applicationsReducers/getApplicationsByIdReducer";
import { searchDataReducer } from "redux/reducers/Search/searchReducer";
import { listMajorsDropdownReducer } from "redux/reducers/majorsReducers/listMajorsDropdownReducer";
import { listAreasReducer } from "redux/reducers/areasReducers/listAreasReducer";
import { listGetAllVacanciesReducer } from "./reducers/GetAllVacancies/getAllVacanciesReducer";
import { listGetAllVacanciesByCompanyIdReducer } from "./reducers/GetAllVacancyByCompanyId/getAllVacanciesByCompanyIdReducer";
import { updatePaymentStatusReducer } from "redux/reducers/paymentReducers/updatePaymentStatusReducer";
import { addTransactionReducer } from "redux/reducers/paymentReducers/addTransactionReducer";
import { getUserCvReducer } from "./reducers/CvProcessReducers/getUserCvReducer";
import { updateCvReducer } from "redux/reducers/CvProcessReducers/updateCvReducer";
import { uploadCvReducer } from "redux/reducers/CvProcessReducers/uploadCvReducer";
import { listCountriesSearchReducer } from "redux/reducers/Search/listCountriesSearchReducer";
import { listAreaSearchReducer } from "redux/reducers/Search/listAreaSearchReducer";
import { changePasswordReducer } from "redux/reducers/PasswordReducers/changePasswordReducer";
import { generateOtpWithEmailReducer } from "redux/reducers/PasswordReducers/ResetPasswordReducers/generateOtpWithEmailReducer";
import { setResetPasswordEmail } from "redux/reducers/PasswordReducers/ResetPasswordReducers/resetEmailReducer";
import { validateOtpReducer } from "redux/reducers/PasswordReducers/ResetPasswordReducers/validateOtpReducer";
import { addingNewPasswordReducer } from "redux/reducers/PasswordReducers/ResetPasswordReducers/addingNewPasswordReducer";
import { signInReducer } from "redux/reducers/JoinUsReducer/SignIn/signInReducer";
import { generatePhoneOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/generatePhoneOtpReducer";
import { phoneSignUpReducer } from "redux/reducers/JoinUsReducer/SignUp/phoneSignUpReducer";
import { addUserPersonalInfoReducer } from "redux/reducers/JoinUsReducer/SignUp/addUserPersonalInfoReducer";
import { generateEmailOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/generateEmailOtpReducer";
import { validateEmailOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/validateEmailOtpReducer";
import { validatePhoneOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/validatePhoneOtpReducer";
import { generateOtpResetPassword } from "redux/reducers/JoinUsReducer/ResetPassword/generateOtpResetPassword";
import { validateOtpResetPasswordReducer } from "redux/reducers/JoinUsReducer/ResetPassword/validateOtpResetPasswordReducer";
import { resetPasswordReducer } from "redux/reducers/JoinUsReducer/ResetPassword/resetPasswordReducer";
import { getCompanyJobsReducer } from "redux/reducers/companiesReducers/getCompanyJobsReducer";

export const appReducer = combineReducers({
  googleLoginReducer,
  setNavbarBackground,
  listUniversitiesReducer,
  listFacultiesReducer,
  listCountriesReducer,
  listCitiesReducer,
  registerUserInfoReducer,
  listMajorsReducer,
  listSkillsReducer,
  addSubscriptionPlanReducer,
  addCompanyNameImageReducer,
  addCompaniesInfoReducer,
  addCompaniesMediaReducer,
  getProfileCompletionReducer,
  addUserInfoReducer,
  addEducationInfoReducer,
  addExperienceReducer,
  addWorkLocationReducer,
  addMajorsReducer,
  addSkillsReducer,
  completeRegistrationReducer,
  MatchedInternsReducer,
  JobDetailsReducer,
  createVacancyReducer,
  listCompanyVacanciesReducer,
  getVacancyReducer,
  updateVacancyReducer,
  getCompanyReducer,
  getCompanyIdFromUserIdReducer,
  getCompanyProfileReducer,
  updateCompanyProfileReducer,
  deleteVacancyReducer,
  listApplicationsReducer,
  applyToJobReducer,
  listMyApplicationsReducer,
  listCompaniesReducer,
  listCompanyDetailsReducer,
  listProfileDataReducer,
  updateUserProfileReducer,
  listSingleApplicationReducer,
  updateApplicationStatusReducer,
  getApplicationsByIdReducer,
  searchDataReducer,
  listMajorsDropdownReducer,
  listAreasReducer,
  listGetAllVacanciesReducer,
  listGetAllVacanciesByCompanyIdReducer,
  updatePaymentStatusReducer,
  addTransactionReducer,
  getUserCvReducer,
  updateCvReducer,
  uploadCvReducer,
  listCountriesSearchReducer,
  listAreaSearchReducer,
  changePasswordReducer,
  generateOtpWithEmailReducer,
  setResetPasswordEmail,
  validateOtpReducer,
  addingNewPasswordReducer,
  signInReducer,
  generatePhoneOtpReducer,
  phoneSignUpReducer,
  addUserPersonalInfoReducer,
  generateEmailOtpReducer,
  validateEmailOtpReducer,
  validatePhoneOtpReducer,
  generateOtpResetPassword,
  validateOtpResetPasswordReducer,
  resetPasswordReducer,
  getCompanyJobsReducer,
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(
      {
        ...state,
        signInReducer: undefined,
        completeRegistrationReducer: undefined,
        getUserCvReducer: undefined,
        listProfileDataReducer: undefined,
        updateUserProfileReducer: undefined,
      },
      action
    );
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
