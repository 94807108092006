import
{
    LIST_COMPANY_DETAILS_LOADING,
    LIST_COMPANY_DETAILS_SUCCESS,
    LIST_COMPANY_DETAILS_FAILURE
}
from "redux/types";

const initialState = {
    detailsLoading: false,
    companyDetails: null,
    errors: null
}

export const listCompanyDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_COMPANY_DETAILS_LOADING :
            return {...state, detailsLoading: action.payload};
        case LIST_COMPANY_DETAILS_SUCCESS :
            return {...state, companyDetails: action.payload};
        case LIST_COMPANY_DETAILS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}