import { FC, useEffect } from "react";
import { CheckIcon } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { addSubscriptionPlanAction } from "redux/actions/companyActions/addSubscriptionPlanAction";
import { Spin } from "antd";
import { generatePaymentLink } from "utils";
import store from "redux/store";

export type pricingIntervalType = {
  totalAmount: string;
  per: number;
  value: "Year" | "Month";
  uniqueIdentifier: string;
  discountAppliedText?: string;
};

export interface SubscribtionCardProps {
  id: number;
  planName: string;
  planDescription: string;
  pricingIntervals: pricingIntervalType[];
  features: string[];
  setActivePop?: any;
}

export const SubscriptionCard: FC<SubscribtionCardProps> = ({
  id,
  planName,
  planDescription,
  pricingIntervals,
  features,
  setActivePop,
}) => {
  const { addSubPlanLoading } = useSelector(
    (state: RootState) => state.addSubscriptionPlanReducer
  );

  const { completion } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  const dispatch: any = useDispatch();

  return (
    <div className="w-full max-h-[535px] overflow-y-auto min-h-[400px] bg-white rounded-[15px] shadow-shadow_main flex flex-col items-start gap-y-4 py-4 px-3">
      <div className="w-full flex flex-row items-center gap-x-4">
        <p className="font-Avenir_demi text-[#142644] text-[16px] md:text-[20px]">
          {planName}
        </p>
        <p className="font-Avenir_demi text-[#444444] text-[12px] md:text-[14px]">
          {planDescription}
        </p>
      </div>
      <div className="w-full flex items-start justify-center gap-x-4">
        {pricingIntervals.map((item: pricingIntervalType) => {
          return (
            <div className="w-[30%] flex flex-col justify-start">
              <Spin spinning={addSubPlanLoading}>
                <button
                  onClick={() => {
                    dispatch(
                      addSubscriptionPlanAction(
                        item?.uniqueIdentifier,
                        (data: any) => {
                          const link = generatePaymentLink({
                            totalAmount: `${item.totalAmount.replace(
                              /,/g,
                              ""
                            )}`,
                            userId:
                              store.getState()?.googleLoginReducer?.userId,
                            items: [
                              {
                                itemCode: "4125760947",
                                itemName: "Product 1",
                                itemPrice: `${item.totalAmount.replace(
                                  /,/g,
                                  ""
                                )}`,
                              },
                            ],
                          });
                          window.location.href = link;
                        }
                      )
                    );
                  }}
                  className="w-full py-2 bg-[#DCDEE3] rounded-[15px]"
                >
                  <p className="text-[#142644] font-Avenir_demi text-[12px] md:text-[16px] text-center">
                    {item.totalAmount} EGP <br /> /{item.per} {item.value}
                  </p>
                </button>
              </Spin>
              {item.discountAppliedText && (
                <p className="text-center text-[#444444] font-Avenir_demi text-[10px] md:text-[14px]">
                  {item.discountAppliedText}
                </p>
              )}
            </div>
          );
        })}
      </div>
      <div className="w-full flex flex-col items-start gap-y-6">
        {features.map((item: string) => {
          return (
            <div className="w-full flex items-center gap-x-2">
              <img src={CheckIcon} alt="check-icon" />
              <p className="text-[#142644] font-Avenir_demi text-[12px] md:text-[16px]">
                {item}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionCard;
