import Header from "components/Header";
import Footer from "components/Footer";
import SubscriptionCard from "components/SubscriptionCard/SubscriptionCard";
import { useEffect, useRef, useState } from "react";
import { CheckIcon } from "assets/icons";
import {
  cvUnlocksFeatures,
  enhancedVisibility,
  goldPlan,
  multiPostDiscounts,
  payPerPostFeatures,
  positionLevel,
  renewalOrExtension,
  sliverPlan,
} from "constants/constants";
import AdditionalFeaturesButton, {
  AdditionalFeaturesButtonProps,
} from "components/AdditionalFeaturesButton/AdditionalFeaturesButton";
import CompanyRegistrationOne from "components/Companies/CompanyRegistrationOne";
import CompanyRegistrationTwo from "components/Companies/CompanyRegistrationTwo";
import CompanyRegistrationThree from "components/Companies/CompanyRegistrationThree";
import { useCompanyRegistrationOneFormik } from "forms/companyRegistrationOneFormik";
import { useCompanyRegistrationTwoFormik } from "forms/companyRegistrationTwoFormik";
import { useCompanyRegistrationThreeFormik } from "forms/companyRegistrationThreeFormik";
import { useDispatch, useSelector } from "react-redux";
import { addSubscriptionPlanAction } from "redux/actions/companyActions/addSubscriptionPlanAction";
import { generatePaymentLink } from "utils";
import store from "redux/store";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import CvUnlocks from "components/PayPerPostButtons/CvUnlocks";
import AdditionalPosts from "components/PayPerPostButtons/AdditionalPosts";
import RenewalOrExtension from "components/PayPerPostButtons/RenewalOrExtension";

const SubPlans = () => {
  const element = useRef<any>();

  const dispatch: any = useDispatch();

  const [totalPrice, setTotalPrice] = useState<number>(500);

  const [activePop, setActivePop] = useState("");

  const [cvUnlocks, setCvUnlocks] = useState(-1);
  const [additionalPosts, setAdditionalPosts] = useState(-1);
  const [renewalOrExtensionState, setRenewalOrExtensionState] = useState(-1);

  const companyRegistrationOneFormik =
    useCompanyRegistrationOneFormik(setActivePop);

  const companyRegistrationTwoFormik =
    useCompanyRegistrationTwoFormik(setActivePop);

  const companyRegistrationThreeFormik =
    useCompanyRegistrationThreeFormik(setActivePop);

  const { addSubPlanLoading } = useSelector(
    (state: RootState) => state.addSubscriptionPlanReducer
  );

  return (
    <div className="w-full flex flex-col items-center">
      <Header />

      {activePop === "companyOne" && (
        <CompanyRegistrationOne
          formik={companyRegistrationOneFormik}
          setActivePop={setActivePop}
        />
      )}

      {activePop === "companyTwo" && (
        <CompanyRegistrationTwo
          formik={companyRegistrationTwoFormik}
          setActivePop={setActivePop}
        />
      )}

      {activePop === "companyThree" && (
        <CompanyRegistrationThree
          formik={companyRegistrationThreeFormik}
          setActivePop={setActivePop}
        />
      )}

      <div className="w-full pt-[105px] h-full flex flex-col items-center">
        <h1 className="font-Avenir_bold text-[20px] sm:text-[24px] md:text-[32px] lg:text-[42px] text-[#142644] text-balance">
          Find the Perfect Plan for You
        </h1>
        <div className="mt-[30px] flex flex-col md:flex-row gap-x-[13px]">
          <p className="font-Avenir_demi text-[16px] sm:text-[18px] md:text-[24px] text-[#142644]">
            You want to pay per post?
          </p>
          <button
            onClick={() => {
              element?.current.scrollIntoView();
            }}
            className="rounded-[15px] px-16 py-3 bg-[#DCDEE3]"
          >
            <p className="font-Avenir_demi text-[#142644] text-[14px]">
              Check Now
            </p>
          </button>
        </div>
        <div className="w-full mt-[45px] flex flex-col lg:flex-row gap-x-[32px] gap-y-[32px] lg:items-start items-center justify-center">
          <div className="w-[95%] sm:w-[80%] md:w-[60%] lg:w-[40%]">
            <SubscriptionCard {...sliverPlan} setActivePop={setActivePop} />
          </div>
          <div className="w-[95%] sm:w-[80%] md:w-[60%] lg:w-[40%]">
            <SubscriptionCard {...goldPlan} setActivePop={setActivePop} />
          </div>
        </div>

        <div
          ref={element}
          className="mt-[64px] flex flex-col px-3 py-3 rounded-[15px] w-full md:w-[83%]"
        >
          <div className="w-full flex flex-col lg:flex-row justify-between gap-x-6">
            <div className="w-[95%] lg:w-1/2 flex flex-col gap-y-3 shadow-shadow_main p-4 rounded-[15px]">
              <p className="text-[#142644] text-[16px] md:text-[20px] font-Avenir_demi">
                Pay Per Post
              </p>
              <p className="font-Avenir_bold text-[16px] md:text-[20px] text-[#142644]">
                500 EGP Per Post
              </p>
              <div className="w-full flex flex-col items-start gap-y-4">
                {payPerPostFeatures.map((item: string) => {
                  return (
                    <div className="w-full flex flex-row gap-x-2 items-center">
                      <img src={CheckIcon} alt="check-icon" />
                      <p className="font-Avenir_demi text-[12px] md:text-[16px] text-[#142644]">
                        {item}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-10 w-[95%] lg:w-1/2 flex flex-col items-center md:items-start gap-y-3">
              <p className="font-Avenir_demi text-[#142644] text-balance text-[16px] md:text-[20px]">
                You can also add more with extra fees
              </p>
              <div className="w-full flex flex-col md:flex-row items-center justify-between">
                <p className="font-Avenir_demi text-[#142644] text-[16px]">
                  CV Unlocks
                </p>
                <div className="flex flex-row items-center gap-x-3">
                  {cvUnlocksFeatures.map(
                    (item: AdditionalFeaturesButtonProps, idx: number) => {
                      return (
                        <CvUnlocks
                          {...item}
                          value={idx}
                          siblingAmount={
                            idx !== 0
                              ? cvUnlocksFeatures.at(idx - 1)?.amountAdd
                              : cvUnlocksFeatures.at(idx + 1)?.amountAdd
                          }
                          setState={setTotalPrice}
                          selected={cvUnlocks}
                          setSelected={setCvUnlocks}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row items-center justify-between">
                <p className="font-Avenir_demi text-[#142644] text-[16px]">
                  Multi-Post Discounts
                </p>
                <div className="pt-5 flex flex-row items-start gap-x-3">
                  {multiPostDiscounts.map(
                    (item: AdditionalFeaturesButtonProps, idx: any) => {
                      return (
                        <AdditionalPosts
                          {...item}
                          value={idx}
                          siblingAmount={
                            idx !== 0
                              ? multiPostDiscounts.at(idx - 1)?.amountAdd
                              : multiPostDiscounts.at(idx + 1)?.amountAdd
                          }
                          setState={setTotalPrice}
                          selected={additionalPosts}
                          setSelected={setAdditionalPosts}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row items-center justify-between">
                <p className="font-Avenir_demi text-[#142644] text-[16px]">
                  Renewal or Extension
                </p>
                <div className="flex flex-row items-center gap-x-3">
                  {renewalOrExtension.map(
                    (item: AdditionalFeaturesButtonProps, idx: number) => {
                      return (
                        <RenewalOrExtension
                          {...item}
                          value={idx}
                          setState={setTotalPrice}
                          selected={renewalOrExtensionState}
                          setSelected={setRenewalOrExtensionState}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row items-center justify-between">
                <p className="font-Avenir_demi text-[#142644] text-[16px]">
                  Position Level
                </p>
                <div className="flex flex-row items-center gap-x-3">
                  {positionLevel.map((item: AdditionalFeaturesButtonProps) => {
                    return (
                      <AdditionalFeaturesButton
                        {...item}
                        setState={setTotalPrice}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row items-center md:items-start justify-between">
                <p className="pt-10 font-Avenir_demi text-[#142644] text-[16px]">
                  Enhanced Visibility
                </p>
                <div className="pt-5 flex flex-row items-start gap-x-3">
                  {enhancedVisibility.map(
                    (item: AdditionalFeaturesButtonProps) => {
                      return (
                        <AdditionalFeaturesButton
                          {...item}
                          setState={setTotalPrice}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 w-full bg-pro_bg rounded-[15px] flex flex-row items-center justify-between px-4 py-3">
            <div className="flex flex-row items-center gap-x-3">
              <p className="font-Avenir_regular font-extralight text-white text-[16px] md:text-[20px] inline">
                Pay Per Post Total Price{" "}
                <p className="font-Avenir_bold text-white text-[20px] inline">
                  {totalPrice} EGP
                </p>
              </p>
            </div>
            <Spin spinning={addSubPlanLoading}>
              <button
                onClick={() => {
                  dispatch(
                    addSubscriptionPlanAction(
                      "5cx6to4Fjg81yJs9ipWUX5gD38CuFT9U",
                      (data: any) => {
                        const link = generatePaymentLink({
                          totalAmount: `${totalPrice}`,
                          userId: store.getState()?.googleLoginReducer?.userId,
                          items: [
                            {
                              itemCode: "4125760947",
                              itemName: "Product 1",
                              itemPrice: `${totalPrice}`,
                            },
                          ],
                        });
                        window.location.href = link;
                      }
                    )
                  );
                }}
                type="button"
                className="rounded-[15px] bg-white px-6 py-2"
              >
                <p className="font-Avenir_bold text-black text-[16px]">
                  Subscribe
                </p>
              </button>
            </Spin>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default SubPlans;
