import
{
    LIST_SKILLS_LOADING,
    LIST_SKILLS_SUCCESS,
    LIST_SKILLS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listSkillsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_SKILLS_LOADING, payload: true});
        const response = await listItemsService('api/Skills/GetAllSkills');
        dispatch({type: LIST_SKILLS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_SKILLS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_SKILLS_LOADING, payload: false});
    }
}