import { ReactNode } from "react";

interface buttonValue {
  px?: number;
  py?: number;
  textColor?: string;
  bgColor?: string;
  fontSize?: number;
  text?: string;
  children?: ReactNode;
  onClick?: () => void;
  type?: "button" | "submit";
  className?: string;
}

function Button({
  px,
  py,
  textColor,
  bgColor,
  fontSize,
  text,
  children,
  onClick,
  type,
  className
}: buttonValue) {
  return (
    <button
      onClick={onClick}
      type={type ?? "button"}
      style={{
        paddingLeft: `${px}px`,
        paddingRight: `${px}px`,
        paddingTop: `${py}px`,
        paddingBottom: `${py}px`,
        color: `${textColor}`,
        backgroundColor: `${bgColor}`,
        fontSize: `${fontSize}px`,
      }}
      className={`font-Avenir_demi text-[16px] rounded-[15px] flex items-center justify-center gap-[5px] ${className}`}
    >
      {children}
      {text}
    </button>
  );
}

export default Button;
