import {
  ADD_SUB_PLAN_LOADING,
  ADD_SUB_PLAN_SUCCESS,
  ADD_SUB_PLAN_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import store from "redux/store";
import { notification } from "antd";

export const addSubscriptionPlanAction =
  (planId: string, callback: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ADD_SUB_PLAN_LOADING, payload: true });
      const response = await createNewItemService(
        "api/Companies/AddCompanySubscriptionPlan",
        {
          userId: store.getState()?.signInReducer?.userId,
          UniqeIdentifier: planId,
        },
        "",
        "json"
      );
      dispatch({ type: ADD_SUB_PLAN_SUCCESS, payload: response.data });
      callback(response.data);
    } catch (err) {
      dispatch({ type: ADD_SUB_PLAN_FAILURE, payload: err });
      notification.error({
        message: "An Error Occurred",
        description: "You are already subscribed to a plan",
        onClose: () => {
          window.location.pathname = "/";
        },
      });
    } finally {
      dispatch({ type: ADD_SUB_PLAN_LOADING, payload: false });
    }
  };
