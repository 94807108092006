import Footer from "components/Footer";
import Header from "components/Header";
import Company from "components/Companies/Company";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCompaniesAction } from "redux/actions/companyActions/listCompaniesAction";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";

function Companies() {
  const { companiesLoading, companies } = useSelector(
    (state: RootState) => state.listCompaniesReducer
  );

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(listCompaniesAction());
  }, []);

  return (
    <>
      <Header />

      <main className="px-[20px] 2xl:px-[144px] min-h-[60%]">
        {/* the main header section */}
        <section className="pt-[100px] flex flex-col items-center text-pro_text_dark">
          <div className="flex flex-col items-center gap-[30px]">
            <p className="font-Avenir_bold text-[22px] xl:text-[32px] text-center xl:text-left">
              Explore Our Partners
            </p>
          </div>
        </section>

        {/* the jobs grid  */}
        <section className="mt-[50px] min-h-[500px] w-full flex items-center justify-center">
          <Spin
            wrapperClassName="spin-full-width spin-full-height"
            spinning={companiesLoading}
          >
            <div className="w-full p-[10px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-[30px] justify-items-center ">
              {companies?.map((item: any, i: number) => {
                return (
                  <Company
                    key={i}
                    id={item.companyId}
                    companyName={item.companyName}
                    companyField={item.bio}
                    jobCount={item.jobCount}
                    coverImage={item?.coverLogo}
                  />
                );
              })}
            </div>
          </Spin>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Companies;
