import {
  GENERATE_EMAIL_OTP_LOADING,
  GENERATE_EMAIL_OTP_SUCCESS,
  GENERATE_EMAIL_OTP_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const generateEmailOtpActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GENERATE_EMAIL_OTP_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/generate-otp-Register",
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: "Code sent successfuly",
        });
        callback();
      } else {
        notification.error({
          message: "Somethin went wrong",
        });
      }

      dispatch({ type: GENERATE_EMAIL_OTP_SUCCESS, payload: response });
    } catch (err: any) {
      dispatch({ type: GENERATE_EMAIL_OTP_FAILURE, payload: err });
      notification.error({
        message: `${err?.message}`,
      });
    } finally {
      dispatch({ type: GENERATE_EMAIL_OTP_LOADING, payload: false });
    }
  };
