import
{
    ADD_COMPANY_MEDIA_LOADING,
    ADD_COMPANY_MEDIA_SUCCESS,
    ADD_COMPANY_MEDIA_FAILURE
}
from "redux/types";

const initialState = {
    companyMediaLoading: false,
    success: null,
    errors: null
}

export const addCompaniesMediaReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_COMPANY_MEDIA_LOADING :
            return {...state, companyMediaLoading: action.payload};
        case ADD_COMPANY_MEDIA_SUCCESS :
            return {...state, success: action.payload};
        case ADD_COMPANY_MEDIA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}