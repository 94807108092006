import {
  UPDATE_CV_LOADING,
  UPDATE_CV_SUCCESS,
  UPDATE_CV_FAILURE,
} from "redux/types";

const initialState = {
  updateCvLoading: false,
  updateCvSuccess: null,
  errors: null,
};

export const updateCvReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CV_LOADING:
      return { ...state, updateCvLoading: action.payload };
    case UPDATE_CV_SUCCESS:
      return { ...state, updateCvSuccess: action.payload };
    case UPDATE_CV_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
