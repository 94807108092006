import
{
    DELETE_VACANCY_LOADING,
    DELETE_VACANCY_SUCCESS,
    DELETE_VACANCY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { deleteItemService } from "services/deleteItemService";

export const deleteVacancyAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_VACANCY_LOADING, payload: true});
        const response = await deleteItemService('api/Vacancies/DeleteVacancyBy', id);
        dispatch({type: DELETE_VACANCY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: DELETE_VACANCY_FAILURE, payload: err});
    } finally {
        dispatch({type: DELETE_VACANCY_LOADING, payload: false});
    }
}