import { Select, Spin, notification } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listSingleApplicationAction } from "redux/actions/applicationsActions/listSingleApplicationAction";
import { useNavigate, useParams } from "react-router-dom";
import { updateApplicationStatusAction } from "redux/actions/applicationsActions/updateApplicationStatusAction";
import { DownArrowBlack } from "assets/icons";
import { APPLICATION_STATUS_DROPDOWN } from "constants/constants";
import { listProfileDataAction } from "redux/actions/profileDataActions/listProfileDataAction";
import Button from "components/Button";

const ViewApplication = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const { applicationLoading, application } = useSelector(
    (state: RootState) => state.listSingleApplicationReducer
  );

  const { updateLoading } = useSelector(
    (state: RootState) => state.updateApplicationStatusReducer
  );

  const { profileDataLoading, profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  useEffect(() => {
    // change application status here to viewed
    dispatch(
      listSingleApplicationAction(id ?? "", async (data: any) => {
        if (data?.applicationStatusId === 1) {
          // 4 corresponds to the viewed status
          await dispatch(updateApplicationStatusAction(id ?? "", "4"));
        }
        await dispatch(listProfileDataAction(() => {}, data?.userId));
      })
    );
  }, [id]);

  return (
    <Spin
      wrapperClassName="spin-full-width spin-full-height"
      spinning={profileDataLoading || applicationLoading || updateLoading}
    >
      <div className="pt-10 px-8 w-full h-full flex flex-col items-start gap-y-8">
        <h2 className="text-black text-2xl font-bold">
          Application Information
        </h2>
        <div className="w-full flex flex-col items-start gap-y-4">
          <div className="w-full sm:w-[90%] md:w-[70%] lg:w-[50%] flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Application Status</p>
            <Spin
              wrapperClassName="spin-full-width"
              spinning={applicationLoading}
            >
              <Select
                className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
                placeholder="Appliaction Status"
                suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
                options={APPLICATION_STATUS_DROPDOWN}
                value={application?.applicationStatus?.applicationStatusId}
                onSelect={(value: any) => {
                  dispatch(
                    updateApplicationStatusAction(id ?? "", value, () => {
                      notification.success({
                        message: "Application Status Updated",
                        description: `Applicant ${profileData?.firstName} ${
                          profileData?.lastName
                        } has been ${
                          value === 2
                            ? "Accepted"
                            : value === 3
                            ? "Rejected"
                            : "Shortlisted"
                        }`,
                      });
                      dispatch(listSingleApplicationAction(id ?? ""));
                    })
                  );
                }}
              />
            </Spin>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Applied Date</p>
            <p>{application?.applieddate?.split("T")[0]}</p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Application Status</p>
            <p>{application?.applicationStatus?.nameEn}</p>
          </div>
        </div>

        <h2 className="text-black text-2xl font-bold">Student Information</h2>
        <div className="w-full flex flex-col items-start gap-y-8 pb-[5%]">
          <Button
            type="button"
            onClick={() => navigate(`/cv/${profileData?.userProfileId}`)}
            bgColor="#142644"
            fontSize={16}
            px={40}
            py={8}
            textColor="#E3FF66"
            text="Download CV"
          />
          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Full Name</p>
            <p>
              {profileData?.firstName} {profileData?.lastName}
            </p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Email</p>
            <p>{profileData?.user?.email}</p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">University</p>
            <p>{profileData?.university?.universityNameEn}</p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Faculty</p>
            <p>{profileData?.faculty?.facultyNameEn}</p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Country</p>
            <p>{profileData?.country?.countryNameEn}</p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">City</p>
            <p>{profileData?.city?.cityNameEn}</p>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Fields Of Work</p>
            <div className="w-full flex flex-row flex-wrap gap-x-2 gap-y-2">
              {profileData?.user?.userMajors?.map((item: any) => {
                return (
                  <div className="p-3 flex items-center justify-center bg-dashboard_primary rounded-md">
                    <p className="text-white">{item?.major?.majorNameEn}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-xl">Skills</p>
            <div className="w-full flex flex-row flex-wrap gap-x-2 gap-y-2">
              {profileData?.user?.userSkills?.map((item: any) => {
                return (
                  <div className="p-3 flex items-center justify-center bg-dashboard_primary rounded-md">
                    <p className="text-white">{item?.skill?.skillNameEn}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ViewApplication;
