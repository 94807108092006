import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordActions } from "redux/actions/JoinUsAction/ResetPasswordActions/resetPasswordActions";
import { RootState } from "~/redux/rootReducer";

interface resetPasswordTypes {
  password: string;
  confirmPassword: string;
}

export const useResetPasswordFormik = (
  setResetPop: any,
  resetPasswordMethod: any,
  phoneNum: any,
  email: any
) => {
  const dispatch: any = useDispatch();
  const { resetToken } = useSelector(
    (state: RootState) => state.validateOtpResetPasswordReducer
  );

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .min(8, "password must be at least 8 characters long")
        .matches(
          new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*-_?&#]{8,}$/
          ),
          "Must contain lowercase, uppercase letter, one number, one special character"
        )
        .required("This field is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password must be at least 8 characters long")
        .oneOf([yup.ref("password")], "Passwords dosen't match")
        .required("This field is required"),
    }),
    onSubmit: async (values: resetPasswordTypes) => {
      await dispatch(
        resetPasswordActions(
          {
            forgotTypeId: resetPasswordMethod,
            email: email,
            phoneNumber: phoneNum,
            newPassword: values.confirmPassword,
            resetToken: resetToken,
          },
          () => {
            setResetPop("");
          }
        )
      );
    },
  });

  return formik;
};
