import
{
    ADD_NAME_IMAGE_LOADING,
    ADD_NAME_IMAGE_SUCCESS,
    ADD_NAME_IMAGE_FAILURE
}
from "redux/types";

const initialState = {
    addNameImageLoading: false,
    success: null,
    errors: null
}

export const addCompanyNameImageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_NAME_IMAGE_LOADING :
            return {...state, addNameImageLoading: action.payload};
        case ADD_NAME_IMAGE_SUCCESS :
            return {...state, success: action.payload};
        case ADD_NAME_IMAGE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}