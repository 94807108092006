import
{
    ADD_COMPANY_INFO_LOADING, 
    ADD_COMPANY_INFO_SUCCESS, 
    ADD_COMPANY_INFO_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const addCompanyInfoAction = (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: ADD_COMPANY_INFO_LOADING, payload: true});
        const response = await createNewItemService(`api/Companies/AddCompanyInfo?userId=${data?.userId}&companyId=${data?.companyId}`, data, "", "json");
        dispatch({type: ADD_COMPANY_INFO_SUCCESS, payload: response.data});
        callback();
    }
    catch (err) {
        dispatch({type: ADD_COMPANY_INFO_FAILURE, payload: err});
    }
    finally {
        dispatch({type: ADD_COMPANY_INFO_LOADING, payload: false});
    }
}