import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import UpdateCompanyProfileModel from "models/dashboardModels/UpdateCompanyProfileModel";
import { updateCompanyProfileAction } from "redux/actions/companyActions/updateCompanyProfileAction";
import { notification } from "antd";

const useUpdateCompanyProfileFormik = () => {
  const dispatch: any = useDispatch();

  const { companyProfileData } = useSelector(
    (state: RootState) => state.getCompanyProfileReducer
  );

  const formik = useFormik({
    initialValues: {
      bio: companyProfileData?.bio,
      companyName: companyProfileData?.companyName,
      countOfEmployees: companyProfileData?.countOfEmployees,
      countryId: companyProfileData?.countryId,
      cityId: companyProfileData?.cityId,
      majorId: companyProfileData?.majorId,
      coverLogo: companyProfileData?.coverLogo,
      profileLogo: companyProfileData?.profileLogo,
      foundationYear: companyProfileData?.foundationYear,
      facebookLink: companyProfileData?.facebookLink,
      instagramLink: companyProfileData?.instagramLink,
      linkedInLink: companyProfileData?.linkedInLink,
      webSite: companyProfileData?.webSite,
      xLink: companyProfileData?.xLink,
    },
    validationSchema: yup.object({
      bio: yup.string().required("please enter this field"),
      companyName: yup.string().required("please enter this field"),
      countOfEmployees: yup.string().required("please enter this field"),
      majorId: yup.string().required("please enter this field"),
      countryId: yup.string().required("please enter this field"),
      cityId: yup.string().required("please enter this field"),
      // coverLogo: yup.string().required('please enter this field'),
      // profileLogo: yup.string().required('please enter this field'),
      foundationYear: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: UpdateCompanyProfileModel) => {
      const formObject: any = {
        userId: companyProfileData?.userId,
        bio: values.bio,
        companyName: values.companyName,
        countOfEmployees: values.countOfEmployees,
        countryId: values.countryId,
        cityId: values.cityId,
        majorId: values.majorId,
        foundationYear: values.foundationYear,
        facebookLink: values.facebookLink,
        instagramLink: values.instagramLink,
        linkedInLink: values.linkedInLink,
        webSite: values.webSite,
        xLink: values.xLink,
      };

      if (typeof values.profileLogo !== "string") {
        formObject.profileLogo = values.profileLogo;
      }

      if (typeof values.coverLogo !== "string") {
        formObject.coverLogo = values.coverLogo;
      }

      await dispatch(
        updateCompanyProfileAction(
          companyProfileData?.companyId,
          formObject,
          () => {
            notification.success({
              message: "Company Data Updated Successfully",
              description: "Your company data has been updated successfully",
            });
          }
        )
      );
    },
  });

  return formik;
};

export { useUpdateCompanyProfileFormik };
