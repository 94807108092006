import {
  PHONE_SIGN_UP_LOADING,
  PHONE_SIGN_UP_SUCCESS,
  PHONE_SIGN_UP_FAILURE,
  SIGN_IN_SUCCESS,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const phoneSignUpActions =
  (data: any, setActivePop?: any, callback?: any) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: PHONE_SIGN_UP_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/phone-signup",
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: " success",
        });
        if (!response.data.isCompleted && response.data?.complition !== 100) {
          if (response.data?.complition === 20) {
            setActivePop && setActivePop("userPersonalinfo");
          } else if (response.data?.complition === 30) {
            setActivePop && setActivePop("userExperience");
          } else if (response.data?.complition === 45) {
            setActivePop && setActivePop("userAcademicInfo");
          } else if (response.data?.complition === 60) {
            setActivePop && setActivePop("userWorkLocation");
          } else if (response.data?.complition === 75) {
            setActivePop && setActivePop("userFieldsOfWork");
          } else if (response.data?.complition === 90) {
            setActivePop && setActivePop("userSkills");
          }
        } else {
          callback();
        }
      } else {
        notification.error({
          message: "Something went wrong",
        });
      }

      dispatch({ type: PHONE_SIGN_UP_SUCCESS, payload: response.data });
      dispatch({ type: SIGN_IN_SUCCESS, payload: response.data });
    } catch (err: any) {
      dispatch({ type: PHONE_SIGN_UP_FAILURE, payload: err });

      notification.error({
        message: `${err?.message}`,
      });
    } finally {
      dispatch({ type: PHONE_SIGN_UP_LOADING, payload: false });
    }
  };
