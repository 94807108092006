import
{
    LIST_MAJORS_LOADING,
    LIST_MAJORS_SUCCESS,
    LIST_MAJORS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listMajorsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_MAJORS_LOADING, payload: true});
        const response = await listItemsService('api/Majors/GetAllMajorsAsyncGroupedByCategory');
        dispatch({type: LIST_MAJORS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_MAJORS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_MAJORS_LOADING, payload: false});
    }
}