import { FC, useEffect, useState } from "react";
import {
  CloseIcon,
  DefaultCompanyUserIcon,
  DefaultCoverImage,
  LogoIcon,
} from "assets/icons";
import { Input, Spin } from "antd";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { getCompanyIdFromUserIdAction } from "redux/actions/getCompanyIdFromUserIdAction";

export interface CompanyRegistrationModalProps {
  setActivePop: any;
  formik: any;
}

const CompanyRegistrationOne: FC<CompanyRegistrationModalProps> = ({
  setActivePop,
  formik,
}) => {
  const dispatch: any = useDispatch();

  const [profileImage, setProfileImage] = useState<any>(null);
  const [coverImage, setCoverImage] = useState<any>(null);

  const { data } = useSelector((state: RootState) => state.googleLoginReducer);
  const { addNameImageLoading } = useSelector(
    (state: RootState) => state.addCompanyNameImageReducer
  );

  const { companyIdLoading } = useSelector(
    (state: RootState) => state.getCompanyIdFromUserIdReducer
  );

  const { Password } = Input;

  useEffect(() => {
    dispatch(getCompanyIdFromUserIdAction());
  }, []);

  return (
    <section className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10">
      <Spin
        wrapperClassName="spin-full-width spin-center"
        spinning={companyIdLoading}
      >
        <div className="relative w-[90%] max-h-[80vh] lg:max-h-[90vh] lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] overflow-y-auto scroll">
          <div className="relative flex items-center justify-center">
            {/* logo */}
            <div>
              <img src={LogoIcon} alt="logo" />
            </div>

            {/* close button */}
            {/* <div
              onClick={() => setActivePop("")}
              className="absolute top-0 right-0 cursor-pointer"
            >
              <img src={CloseIcon} alt="logo" />
            </div> */}
          </div>

          <p className="text-center text-white font-Avenir_bold text-[24px]">
            Fill Company Name & Logos
          </p>

          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col items-center justify-center gap-[20px] text-white"
          >
            <div className="flex justify-center">
              {!profileImage ? (
                <img src={DefaultCompanyUserIcon} alt="profileImage" />
              ) : (
                <img
                  className="w-[120px] h-[120px] rounded-full object-cover"
                  src={URL.createObjectURL(profileImage)}
                  alt="profile"
                />
              )}
            </div>
            <div className="w-full flex justify-center font-semibold text-maincolor">
              <input
                type="file"
                id="custom-input"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    setProfileImage(file);
                    formik.setFieldValue("profile_image", file);
                  }
                }}
                hidden
              />
              <label
                htmlFor={"custom-input"}
                className="font-Avenir_demi text-[16px] cursor-pointer"
              >
                Upload Company Logo
              </label>
            </div>

            <div className="w-full flex flex-col items-center justify-center gap-[20px] text-white">
              <div className="w-full flex justify-center">
                {!coverImage ? (
                  <img
                    className="w-full h-[120px] object-cover rounded-[15px]"
                    src={DefaultCoverImage}
                    alt="coverImage"
                  />
                ) : (
                  <img
                    className="w-full h-[120px] rounded-[15px] object-cover"
                    src={URL.createObjectURL(coverImage)}
                    alt="profile"
                  />
                )}
              </div>
              <div className="w-full flex justify-center font-semibold text-maincolor">
                <input
                  type="file"
                  id="custom-input-2"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setCoverImage(file);
                      formik.setFieldValue("cover_image", file);
                    }
                  }}
                  hidden
                />
                <label
                  htmlFor={"custom-input-2"}
                  className="font-Avenir_demi text-[16px] cursor-pointer"
                >
                  Upload Cover Image
                </label>
              </div>
            </div>

            <div className="w-full flex flex-col gap-y-4">
              <div className="w-full flex flex-col items-start">
                <Input
                  className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[2px] pl-[15px] focus:outline-none placeholder-white/55"
                  type="text"
                  name="company_name"
                  placeholder="Company Name"
                  onChange={formik.handleChange}
                />
                {formik.errors.company_name && (
                  <p className="text-[red]">{formik.errors.company_name}</p>
                )}
              </div>
            </div>
            <Spin
              wrapperClassName="spin-full-width"
              spinning={addNameImageLoading}
            >
              <Button
                bgColor="#FFFFFF"
                className="w-full"
                fontSize={14}
                px={40}
                py={14}
                textColor="#142644"
                type="submit"
                text="Next"
              />
            </Spin>
          </form>
        </div>
      </Spin>
    </section>
  );
};

export default CompanyRegistrationOne;
