import {
  GET_VACANCY_LOADING,
  GET_VACANCY_SUCCESS,
  GET_VACANCY_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const getVacancyAction = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_VACANCY_LOADING, payload: true });
    const response = await listSingleItemService(
      `api/Vacancies/GetVacancyBy${id}`,
      ""
    );
    dispatch({ type: GET_VACANCY_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_VACANCY_FAILURE, payload: err });
  } finally {
    dispatch({ type: GET_VACANCY_LOADING, payload: false });
  }
};
