import {
  GET_USER_CV_LOADING,
  GET_USER_CV_SUCCESS,
  GET_USER_CV_FAILURE,
} from "redux/types";

const initailState = {
  getUserCvloading: null,
  getUserCvSuccess: null,
  errors: null,
};

export const getUserCvReducer = (state = initailState, action: any) => {
  switch (action.type) {
    case GET_USER_CV_LOADING:
      return { ...state, getUserCvloading: action.payload };
    case GET_USER_CV_SUCCESS:
      return { ...state, getUserCvSuccess: action.payload };
    case GET_USER_CV_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
