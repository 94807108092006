import { Outlet } from "react-router-dom";
import Dashboard from "./Dashboard";

const DashboardParent = () => {
  return (
    <Dashboard>
      <Outlet />
    </Dashboard>
  );
};

export default DashboardParent;
