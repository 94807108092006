import React, { useState } from "react";
import { CloseIcon, erroricon } from "assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useGenerateOtpResetPasswordFormik } from "forms/JoinUsForms/ResetPassword/generateOtpFormik";

interface GenerateOtpResetPasswordProps {
  resetPop: string;
  setResetPop: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setPhoneNum: React.Dispatch<React.SetStateAction<string>>;
  resetPasswordMethod: number;
}

const GenerateOtpResetPassword = ({
  resetPop,
  setResetPop,
  resetPasswordMethod,
  setPhoneNum,
  setEmail,
}: GenerateOtpResetPasswordProps) => {
  const [country, setCountry] = useState("");

  const formik = useGenerateOtpResetPasswordFormik(
    resetPasswordMethod,
    country,
    setResetPop,
    setPhoneNum,
    setEmail
  );

  const { generateOtpLoading } = useSelector(
    (state: RootState) => state.generateOtpResetPassword
  );
  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/75 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] text-pro_text_dark">
        {/*************************** top title section  **************/}
        <div className="relative flex items-center justify-between">
          <div></div>
          {/* Reset Password */}
          <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
            Reset Password
          </div>

          {/* close button */}
          <div className="cursor-pointer" onClick={() => setResetPop("")}>
            <img src={CloseIcon} alt="logo" />
          </div>
        </div>

        {/*************************** instructions ***************************/}
        {resetPasswordMethod === 1 ? (
          <div className="font-Avenir_regular text-[16px] text-white flex items-center justify-center">
            We will send a verification code to your phone
          </div>
        ) : (
          <div className="font-Avenir_regular text-[16px] text-white flex items-center justify-center">
            We will send a verification code to your email
          </div>
        )}

        {/*************************** Phone number section  **************/}
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-[20px] text-white"
        >
          {resetPasswordMethod === 1 ? (
            //////////////////////////////////////////////  phone
            //  <Spin spinning={phoneOtpLoading}>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col items-center gap-3">
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "48px",
                    borderRadius: "10px",
                  }}
                  country={"eg"}
                  onlyCountries={["eg", "ae"]}
                  dropdownStyle={{
                    color: "black",
                  }}
                  countryCodeEditable={false}
                  // disabled={disapbleSendCode}
                  onChange={(phone, x, y, valueseparated) => {
                    // the country code
                    const countryCode = valueseparated.split(" ").shift();

                    if (countryCode === "+20") {
                      setCountry("eg");
                    } else if (countryCode === "+971") {
                      setCountry("uae");
                    }
                    const countryCodeLength = countryCode
                      ?.split("")
                      .filter((item: any, idx: number) => idx !== 0)
                      .join("").length;

                    // phone number
                    const phoneNumber = phone.slice(
                      countryCodeLength,
                      phone.length
                    );

                    formik.setFieldValue("phone", phoneNumber);
                  }}
                />
                {/* errors section  */}
                <div className=" w-full flex items-center">
                  {formik.errors.phone && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.phone}
                    </p>
                  )}
                </div>
              </div>

              {/* errors section  */}
              <div className="mt-[-5px] w-full flex items-center">
                {/* {phoneFormik.errors.phone && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {phoneFormik.errors.phone}
                    </p>
                  )} */}
              </div>
            </div>
          ) : (
            ///////////////////////////////////////////// email
            <div className="flex flex-col items-center gap-3">
              <div className="w-full flex items-center gap-3">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                  // readOnly={emailReadOnly}
                />
              </div>

              {/* errors section  */}
              <div className="mt-[-10px] w-full flex items-center">
                {formik.errors.email && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.email}
                  </p>
                )}
              </div>
            </div>
          )}

          {/* </Spin> */}

          {/* next  button  */}
          <Spin spinning={generateOtpLoading}>
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Send Code
            </button>
          </Spin>
        </form>
      </div>
    </section>
  );
};

export default GenerateOtpResetPassword;
