import
{
    COMPANY_VACANCIES_LOADING,
    COMPANY_VACANCIES_SUCCESS,
    COMPANY_VACANCIES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

import store from "redux/store";

export const listCompanyVacanciesAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: COMPANY_VACANCIES_LOADING, payload: true});
        const response = await createNewItemService(`api/Vacancies/SearchVacancies`, {
            companyId: store.getState()?.addSubscriptionPlanReducer?.companyId === null ? store.getState()?.getCompanyIdFromUserIdReducer?.companyData?.companyId : store.getState()?.addSubscriptionPlanReducer?.companyId
        }, "", "json");
        dispatch({type: COMPANY_VACANCIES_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: COMPANY_VACANCIES_FAILURE, payload: err});
    } finally {
        dispatch({type: COMPANY_VACANCIES_LOADING, payload: false});
    }
}