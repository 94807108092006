import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { phoneSignUpActions } from "redux/actions/JoinUsAction/SignUp/phoneSignUpActions";

interface userSignUpTypes {
  phone: string;
  otp: string;
  password: string;
  confirmPassword: string;
}

export const useUserSignUpFormik = (setActivePop: any, country: string) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      phone: yup.string().required("This field is required"),
      otp: yup.string().required("This field is required"),
      password: yup
        .string()
        .min(8, "password must be at least 8 characters long")
        .matches(
          new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*-_?&#]{8,}$/
          ),
          "Must contain lowercase, uppercase, number, and one special character"
        )
        .required("This field is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password must be at least 8 characters long")
        .oneOf([yup.ref("password")], "Passwords dosen't match")
        .required("This field is required"),
    }),

    onSubmit: async (values: userSignUpTypes) => {
      await dispatch(
        phoneSignUpActions(
          {
            phoneNumber: country === "eg" ? `0${values.phone}` : values.phone,
            otp: values.otp,
            password: values.confirmPassword,
            userRole: "User",
          },
          setActivePop,
          () => {
            setActivePop("userPersonalinfo");
          }
        )
      );
    },
  });

  return formik;
};
