import {
  COMPLETE_REGISTRATION_LOADING,
  COMPLETE_REGISTRATION_SUCCESS,
  COMPLETE_REGISTRATION_FAILURE,
} from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const completeRegistrationAction =
  (callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: COMPLETE_REGISTRATION_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/complete",
        {},
        "",
        "json"
      );
      dispatch({ type: COMPLETE_REGISTRATION_SUCCESS, payload: response.data });
      callback();
    } catch (err) {
      dispatch({ type: COMPLETE_REGISTRATION_FAILURE, payload: err });
    } finally {
      dispatch({ type: COMPLETE_REGISTRATION_LOADING, payload: false });
    }
  };
