import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { MatchedInternsAction } from "redux/actions/matchedInterns/matchedInternsActions";

const useMatchedInterns = () => {
  const dispatch: any = useDispatch();

  const { userId } = useSelector((state: RootState) => state.signInReducer);

  const { matchedInterns, matchedInternsLoading } = useSelector(
    (state: RootState) => state.MatchedInternsReducer
  );

  useEffect(() => {
    dispatch(MatchedInternsAction(userId));
  }, []);

  return {
    matchedInterns,
    matchedInternsLoading,
  };
};

export { useMatchedInterns };
