import
{
    GET_VACANCY_LOADING,
    GET_VACANCY_SUCCESS,
    GET_VACANCY_FAILURE
}
from "redux/types";

const initialState = {
    getVacancyLoading: false,
    vacancy: null,
    errors: null
};

export const getVacancyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_VACANCY_LOADING :
            return {...state, getVacancyLoading: action.payload};
        case GET_VACANCY_SUCCESS :
            return {...state, vacancy: action.payload};
        case GET_VACANCY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}