import { SIGN_IN_LOADING, SIGN_IN_SUCCESS, SIGN_IN_FAILURE } from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const signInActions =
  (
    data: any,
    setActivePop?: React.Dispatch<React.SetStateAction<string>>,
    callback?: any
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: SIGN_IN_LOADING, payload: true });
      const response = await createNewItemService(
        "api/NewAuth/LoginWithPhone",
        data,
        "",
        "json"
      );

      dispatch({ type: SIGN_IN_SUCCESS, payload: response.data });
      notification.success({
        message: "Welcome!",
      });

      if (!response.data.isCompleted && response.data?.complition !== 100) {
        if (response.data?.complition === 20) {
          setActivePop && setActivePop("userPersonalinfo");
        } else if (response.data?.complition === 30) {
          setActivePop && setActivePop("userExperience");
        } else if (response.data?.complition === 45) {
          setActivePop && setActivePop("userAcademicInfo");
        } else if (response.data?.complition === 60) {
          setActivePop && setActivePop("userWorkLocation");
        } else if (response.data?.complition === 75) {
          setActivePop && setActivePop("userFieldsOfWork");
        } else if (response.data?.complition === 90) {
          setActivePop && setActivePop("userSkills");
        }
      } else {
        callback();
      }
    } catch (err: any) {
      dispatch({ type: SIGN_IN_FAILURE, payload: err });

      notification.error({
        message: `${err?.message}`,
      });
    } finally {
      dispatch({ type: SIGN_IN_LOADING, payload: false });
    }
  };
