import {
  GENERATE_PHONE_OTP_LOADING,
  GENERATE_PHONE_OTP_SUCCESS,
  GENERATE_PHONE_OTP_FAILURE,
} from "redux/types";

const initailState = {
  phoneOtpLoading: false,
  phoneOtp: null,
  errors: null,
};

export const generatePhoneOtpReducer = (state = initailState, action: any) => {
  switch (action.type) {
    case GENERATE_PHONE_OTP_LOADING:
      return { ...state, phoneOtpLoading: action.payload };
    case GENERATE_PHONE_OTP_SUCCESS:
      return { ...state, phoneOtp: action.payload };
    case GENERATE_PHONE_OTP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
