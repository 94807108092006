import {
  LIST_MATCHED_INTERNS_LOADING,
  LIST_MATCHED_INTERNS_SUCCESS,
  LIST_MATCHED_INTERNS_FAILURE,
} from "redux/types";

const initialState = {
  matchedInternsLoading: false,
  matchedInterns: [],
  errors: null,
};

export const MatchedInternsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LIST_MATCHED_INTERNS_LOADING:
      return { ...state, matchedInternsLoading: action.payload };
    case LIST_MATCHED_INTERNS_SUCCESS:
      return { ...state, matchedInterns: action.payload?.data};
    case LIST_MATCHED_INTERNS_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
