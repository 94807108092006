import {
  BlackApplicationIcon,
  BlackCompanyProfileIcon,
  BlackDashboardIcon,
  BlueApplicationIcon,
  BlueCompanyProfileIcon,
  BlueDashboardIcon,
  VacancyBlackIcon,
  VacancyBlueIcon,
  VacancyWhiteIcon,
  WhiteApplicationIcon,
  WhiteCompanyProfileIcon,
  WhiteDashboardIcon,
} from "assets/icons";
import { SubscribtionCardProps } from "components/SubscriptionCard/SubscriptionCard";
import { AdditionalFeaturesButtonProps } from "components/AdditionalFeaturesButton/AdditionalFeaturesButton";

export type SidebarItemsProps = {
  name: string;
  route: string;
  icon: any;
  highlightedIcon: any;
  selectedIcon: any;
};

export const SIDE_BAR_ITEMS: SidebarItemsProps[] = [
  {
    name: "Home",
    route: "/dashboard/home",
    icon: WhiteDashboardIcon,
    highlightedIcon: BlueDashboardIcon,
    selectedIcon: BlackDashboardIcon,
  },
  {
    name: "Profile",
    route: "/dashboard/profile",
    icon: WhiteCompanyProfileIcon,
    highlightedIcon: BlueCompanyProfileIcon,
    selectedIcon: BlackCompanyProfileIcon,
  },
  {
    name: "Vacancies",
    route: "/dashboard/vacancies",
    icon: VacancyWhiteIcon,
    highlightedIcon: VacancyBlueIcon,
    selectedIcon: VacancyBlackIcon,
  },
  {
    name: "Applications",
    route: "/dashboard/applications",
    icon: WhiteApplicationIcon,
    highlightedIcon: BlueApplicationIcon,
    selectedIcon: BlackApplicationIcon,
  },
];

export const sliverPlan: SubscribtionCardProps = {
  id: 2,
  planName: "Silver Plan",
  planDescription: "Ideal for small businesses with occasional intern needs",
  pricingIntervals: [
    {
      totalAmount: "15,000",
      per: 1,
      value: "Year",
      discountAppliedText: "1,250 EGP / month, 20% discount applied",
      uniqueIdentifier: "8c4BNxABHh2c6IiuikaPSqrVjKMXd8TP",
    },
    {
      totalAmount: "4,500",
      per: 3,
      value: "Month",
      discountAppliedText: "1,500 EGP / month, 10% discount applied",
      uniqueIdentifier: "2EbKhnZvKuCttzFIe5D4qIJH4QpSExuO",
    },
    {
      totalAmount: "2,000",
      per: 1,
      value: "Month",
      uniqueIdentifier: "87IqNodrWF4tR1tfQkpxGZCND5Wpzfy9",
    },
  ],
  features: [
    "Unlimited job postings: Companies can post as many internship opportunities as needed.",
    "100 CV unlocks per month: Access to view and unlock 100 student CVs every month.",
    "Basic insights report: Receive basic analytics and insights on job posting performance and applicant engagement.",
    "Premium support: Dedicated support for any assistance or inquiries.",
  ],
};

export const goldPlan: SubscribtionCardProps = {
  id: 3,
  planName: "Gold Plan",
  planDescription:
    "Designed for businesses with regular intern recruitment needs.",
  pricingIntervals: [
    {
      totalAmount: "35,000",
      per: 1,
      value: "Year",
      discountAppliedText: "2,917 EGP / month, 22% discount applied",
      uniqueIdentifier: "j7pQzWorBVkd28P2CPgQHUahHli427l4",
    },
    {
      totalAmount: "9,000",
      per: 3,
      value: "Month",
      discountAppliedText: "3,000 EGP / month, 20% discount applied",
      uniqueIdentifier: "xRKAgo2TT6KC1b8JCEEwOl7NOCrBiC43",
    },
    {
      totalAmount: "4,500",
      per: 1,
      value: "Month",
      uniqueIdentifier: "5cx6to4Fjg81yJs9ipWUX5gD38CuFT9U",
    },
  ],
  features: [
    "Unlimited job postings: Post an unlimited number of internship opportunities.",
    "200 CV unlocks per month: Access to view and unlock 200 student CVs every month",
    "Advanced insights report: Detailed analytics and insights to track job posting performance and applicant behavior.",
    "Featured logo for brand visibility: Prominently display the company's logo to enhance brand visibility.",
    "Premium support: Priority support for any queries or assistance needed.",
  ],
};

export const payPerPostFeatures: string[] = [
  "20 CV unlocks",
  "3 Job Postings with no additional charge",
  "Essential visibility features at no extra cost, ensuring that job listings reach a wide audience of potential candidates.",
  "Employer dashboard which has Job Posting Management , Application Management, CV Unlock Management , Analytics and Insights, Account and Billing, Support and Assistance",
];

export const cvUnlocksFeatures: AdditionalFeaturesButtonProps[] = [
  {
    id: 1,
    amountAdd: 100,
    featureText: "35 CV unlocks",
  },
  {
    id: 2,
    amountAdd: 200,
    featureText: "50 CV unlocks",
  },
];

export const multiPostDiscounts: AdditionalFeaturesButtonProps[] = [
  {
    id: 3,
    amountAdd: 100,
    featureText: "5 Job Postings",
  },
  {
    id: 4,
    amountAdd: 500,
    featureText: "5 Job Postings",
    additionalFeatureText: "Charge with a 200 EGP discount",
  },
];

export const renewalOrExtension: AdditionalFeaturesButtonProps[] = [
  {
    id: 5,
    amountAdd: 50,
    featureText: "1-month extension",
  },
  {
    id: 6,
    amountAdd: 100,
    featureText: "additional month",
  },
];

export const positionLevel: AdditionalFeaturesButtonProps[] = [
  {
    id: 7,
    amountAdd: 0,
    featureText: "Listings for internship",
  },
  {
    id: 8,
    amountAdd: 100,
    featureText: "For job postings",
  },
];

export const enhancedVisibility: AdditionalFeaturesButtonProps[] = [
  {
    id: 9,
    amountAdd: 200,
    featureText: "Standard Features",
    additionalFeatureText:
      "Highlighted placement o priority listing, for increased exposure to qualified candidates.",
  },
  {
    id: 10,
    amountAdd: 400,
    featureText: "Premium Features",
    additionalFeatureText:
      "Maximizing the reach and impact of job listings to attract top talent",
  },
];

export const EMPLOYEE_COUNT_DROPDOWN = ["0-10", "10-50", "50-100", "100+"].map(
  (item: string) => {
    return {
      label: item,
      value: item,
    };
  }
);

export const BASE_IMAGE_URL = `${process.env.REACT_APP_BACKEND_URL}Images/`;
export const BASE_PDF_URL = `${process.env.REACT_APP_BACKEND_URL}UsersCVs/`;

export const APPLICATION_STATUS_DROPDOWN = [
  {
    label: "Applied",
    value: 1,
    disabled: true,
  },
  {
    label: "Accept",
    value: 2,
  },
  {
    label: "Reject",
    value: 3,
  },
  {
    label: "Viewed",
    value: 4,
    disabled: true,
  },
  {
    label: "Shortlist",
    value: 5,
  },
];

export const careerLevelsSearch = [
  { id: 1, name: "Internship" },
  { id: 2, name: "Entry-Level" },
];

export const workplacesSearch = [
  { id: 1, name: "On-Site" },
  { id: 2, name: "Remotely" },
  { id: 3, name: "Hybrid" },
];

export const academicYears = [
  { yearName: "1st-Year" },
  { yearName: "2nd-Year" },
  { yearName: "3rd-Year" },
  { yearName: "4th-Year" },
  { yearName: "5th-Year" },
  { yearName: "6th-Year" },
  { yearName: "7th-Year" },
];
