import React from "react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import store from "redux/store";

interface ProfileButtonsTypes {
  showCv: boolean;
  setShowCv: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProfileButtons({ showCv, setShowCv }: ProfileButtonsTypes) {
  // this social id just to know if the user loged in with google or email
  const socialId =
    store.getState()?.listProfileDataReducer?.profileData?.user?.socicaID;

  const { updateLoading } = useSelector(
    (state: RootState) => state.updateUserProfileReducer
  );

  return (
    <section
      data-aos="fade-up"
      data-aos-duration="600"
      className="w-full flex items-center flex-wrap justify-center lg:justify-end gap-[10px] mb-[50px]"
    >
      {/* changing password button  */}
      {/* {socialId ? (
        ""
      ) : (
        <Button
          type="button"
          bgColor="#DCDEE3"
          fontSize={16}
          px={40}
          py={8}
          textColor="#142644"
          text="Change Password"
        >
          <img
            className="w-[23px]"
            src={"images/Change-Password.png"}
            alt="Change-Password"
          />
        </Button>
      )} */}

      {/* download cv button  */}
      <Button
        type="button"
        onClick={() => setShowCv(!showCv)}
        bgColor="#DCDEE3"
        fontSize={16}
        px={40}
        py={8}
        textColor="#142644"
        text="Download CV"
      />

      {/* confirming the profile edit button  */}
      <Spin spinning={updateLoading}>
        <Button
          type="submit"
          bgColor="#142644"
          fontSize={16}
          px={40}
          py={8}
          textColor="#E3FF66"
          text="Confirm Profile Editing"
        />
      </Spin>
    </section>
  );
}

export default ProfileButtons;
