import
{
    SET_NAVBAR_BACKGROUND
}
from "redux/types";

const initialState = {
    set: false
}

export const setNavbarBackground = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_NAVBAR_BACKGROUND :
            return {...state, set: action.payload};
        default:
            return state;
    }
}