/* eslint-disable @typescript-eslint/no-unused-vars */
import { notification } from "antd";
import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: apiURL,
});

const checkAuth = (notificationParam: any) => {
  // store.dispatch({ type: LOG_OUT });
  if (window.location.pathname === "/login") {
    notificationParam.message = "Authentication Fail";
    notificationParam.description = "Please check user name or password";
  } else if (window.location.pathname.includes("/posts/create")) {
    notificationParam.message = "Cannot create post without login";
    notificationParam.description = "Please login to your account";
  } else {
    notificationParam.message = "Something Went Wrong";
  }
};

// errors Handling
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.status !== 200) {
      // notification.error({
      //   message: error?.response?.data?.message,
      // });
      throw new Error(error?.response?.data?.message);
    }
  }
);
export default instance;
