import { Input, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import {
  DefaultCompanyUserIcon,
  DefaultCoverImage,
  DownArrowBlack,
} from "assets/icons";
import { useUpdateCompanyProfileFormik } from "forms/dashboardForms/updateCompanyProfileFormik";
import { BASE_IMAGE_URL, EMPLOYEE_COUNT_DROPDOWN } from "constants/constants";
import { getCompanyProfileAction } from "redux/actions/companyActions/getCompanyProfileAction";
import { useDropdown } from "hooks/useDropdown";
import { listCitiesAction } from "redux/actions/citiesActions/listCitiesAction";

const CompanyProfile = () => {
  const dispatch: any = useDispatch();

  const [profileImage, setProfileImage] = useState<any>(null);
  const [coverImage, setCoverImage] = useState<any>(null);

  const formik = useUpdateCompanyProfileFormik();

  const { companyProfileLoading } = useSelector(
    (state: RootState) => state.getCompanyProfileReducer
  );

  const { companyData } = useSelector(
    (state: RootState) => state.getCompanyIdFromUserIdReducer
  );
  const { companyId } = useSelector(
    (state: RootState) => state.addSubscriptionPlanReducer
  );

  const { updateLoading } = useSelector(
    (state: RootState) => state.updateCompanyProfileReducer
  );

  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.cityNameEn,
      value: item.cityId,
    };
  });

  const { majorsLoading, majorsDropdown, countriesLoading, countryDropdown } =
    useDropdown();

  useEffect(() => {
    dispatch(
      getCompanyProfileAction(
        companyId === null ? companyData?.companyId : companyId,
        (data: any) => {
          formik.setFieldValue("profileLogo", data?.profileLogo);
          formik.setFieldValue("bio", data?.bio);
          formik.setFieldValue("countryId", data?.countryId);
          formik.setFieldValue("cityId", data?.cityId);
          formik.setFieldValue("companyName", data?.companyName);
          formik.setFieldValue("countOfEmployees", data?.countOfEmployees);
          formik.setFieldValue("majorId", data?.majorId);
          formik.setFieldValue("coverLogo", data?.coverLogo);
          formik.setFieldValue("foundationYear", data?.foundationYear);
          formik.setFieldValue("facebookLink", data?.facebookLink);
          formik.setFieldValue("instagramLink", data?.instagramLink);
          formik.setFieldValue("linkedInLink", data?.linkedInLink);
          formik.setFieldValue("webSite", data?.webSite);
          formik.setFieldValue("xLink", data?.xLink);
        }
      )
    );
  }, []);

  useEffect(() => {
    dispatch(listCitiesAction("1"));
  }, []);

  return (
    <Spin
      wrapperClassName="spin-full-width spin-full-height"
      spinning={companyProfileLoading}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="w-full h-full flex flex-col items-start justify-start pt-5 px-8 gap-y-4 mb-[100px]"
      >
        <div className="w-full flex flex-col items-start gap-y-3">
          <p className="text-xl font-bold">1. Company Name & Images</p>

          <div className="flex justify-center">
            {!formik.values.profileLogo && !profileImage ? (
              <img src={DefaultCompanyUserIcon} alt="profileImage" />
            ) : (
              <img
                className="w-[120px] h-[120px] rounded-full object-cover"
                src={
                  !formik.values.profileLogo || profileImage !== null
                    ? URL.createObjectURL(profileImage)
                    : BASE_IMAGE_URL + formik.values.profileLogo
                }
                alt="profile"
              />
            )}
          </div>
          <div className="w-full flex font-semibold text-maincolor">
            <input
              type="file"
              id="custom-input"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setProfileImage(file);
                  formik.setFieldValue("profileLogo", file);
                }
              }}
              hidden
            />
            <label
              htmlFor={"custom-input"}
              className="text-[14px] cursor-pointer"
            >
              Upload Company Logo
            </label>
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center gap-[20px] gap-y-3 text-white">
          <div className="w-full flex">
            {!formik.values.coverLogo && !coverImage ? (
              <img
                className="w-[15rem] h-[120px] object-cover rounded-[15px]"
                src={DefaultCoverImage}
                alt="coverImage"
              />
            ) : (
              <img
                className="w-[15rem] h-[120px] rounded-[15px] object-cover"
                src={
                  !formik.values.coverLogo || coverImage !== null
                    ? URL.createObjectURL(coverImage)
                    : BASE_IMAGE_URL + formik.values.coverLogo
                }
                alt="profile"
              />
            )}
          </div>
          <div className="w-full flex font-semibold text-maincolor">
            <input
              type="file"
              id="custom-input-2"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setCoverImage(file);
                  formik.setFieldValue("coverLogo", file);
                }
              }}
              hidden
            />
            <label
              htmlFor={"custom-input-2"}
              className="text-[14px] cursor-pointer text-black"
            >
              Upload Cover Image
            </label>
          </div>
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Company Name</label>
          <Input
            placeholder="Company Name"
            name="companyName"
            value={formik.values.companyName}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.companyName && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.companyName}
            </p>
          )}
        </div>

        <p className="text-xl font-bold">2. Company Information</p>

        <div className="w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Bio</label>
          <textarea
            placeholder="Bio"
            name="bio"
            value={formik.values.bio}
            className="scroll rounded-[15px] w-[28rem] !bg-transparent min-h-[150px] border-black border p-[15px] !text-black overflow-y-auto !caret-black placeholder:!text-[#6b7280] focus:outline-none resize-none"
            onChange={formik.handleChange}
          ></textarea>
          {formik.errors.bio && (
            <p className="text-[red]">{formik.errors.bio}</p>
          )}
        </div>

        <div className="w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Country</label>
          <Spin wrapperClassName="spin-full-width" spinning={countriesLoading}>
            <Select
              className="black-border w-[50%] h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Country"
              options={countryDropdown}
              value={formik.values.countryId}
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              onChange={(value: any) => {
                formik.setFieldValue("countryId", value);
                dispatch(listCitiesAction(value));
              }}
            />
          </Spin>
          {formik.errors.countryId && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.countryId}
            </p>
          )}
        </div>

        <div className="w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">City</label>
          <Spin wrapperClassName="spin-full-width" spinning={citiesLoading}>
            <Select
              className="black-border w-[50%] h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="City"
              options={citiesDropdown}
              value={formik.values.cityId}
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              onChange={(value: any) => {
                formik.setFieldValue("cityId", value);
              }}
            />
          </Spin>
          {formik.errors.cityId && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.cityId}
            </p>
          )}
        </div>

        <div className="w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Company Field</label>
          <Spin wrapperClassName="spin-full-width" spinning={majorsLoading}>
            <Select
              className="black-border w-[50%] h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Company Field"
              options={
                !majorsDropdown ? [{ label: "", value: "" }] : majorsDropdown
              }
              value={formik.values.majorId}
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              onChange={(value: any) => {
                formik.setFieldValue("majorId", value);
              }}
            />
          </Spin>
          {formik.errors.majorId && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.majorId}
            </p>
          )}
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">
            Number of Employees
          </label>
          <Select
            className="select-no-border w-[50%] h-[48px] rounded-[15px] bg-pro_bg border border-black focus:outline-none placeholder-white/55"
            placeholder="Number of Employees"
            options={EMPLOYEE_COUNT_DROPDOWN}
            suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
            value={formik.values.countOfEmployees}
            onChange={(value: any) => {
              formik.setFieldValue("countOfEmployees", value);
            }}
          />
          {formik.errors.countOfEmployees && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.countOfEmployees}
            </p>
          )}
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Foundation Year</label>
          <Input
            placeholder="Foundation Year"
            name="foundationYear"
            value={formik.values.foundationYear}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.foundationYear && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.foundationYear}
            </p>
          )}
        </div>

        <p className="text-xl font-bold">3. Social Media Links</p>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Website Link</label>
          <Input
            placeholder="Website Link"
            name="webSite"
            value={formik.values.webSite}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.webSite && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.webSite}
            </p>
          )}
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Facebook Link</label>
          <Input
            placeholder="Facebook Link"
            name="facebookLink"
            value={formik.values.facebookLink}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.facebookLink && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.facebookLink}
            </p>
          )}
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Instagram Link</label>
          <Input
            placeholder="Instagram Link"
            name="instagramLink"
            value={formik.values.instagramLink}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.instagramLink && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.instagramLink}
            </p>
          )}
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">X Link</label>
          <Input
            placeholder="X Link"
            name="xLink"
            value={formik.values.xLink}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.xLink && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.xLink}
            </p>
          )}
        </div>

        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Linkedin Link</label>
          <Input
            placeholder="Linkedin Link"
            name="linkedInLink"
            value={formik.values.linkedInLink}
            className="w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.errors.linkedInLink && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.linkedInLink}
            </p>
          )}
        </div>

        <div className="w-full flex items-center justify-center">
          <Spin className="spin-full-width" spinning={updateLoading}>
            <button
              type="submit"
              className="bg-dashboard_primary w-full rounded-lg px-6 py-3 mb-10"
            >
              <p className="text-white">Update Profile</p>
            </button>
          </Spin>
        </div>
      </form>
    </Spin>
  );
};

export default CompanyProfile;
