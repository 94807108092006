import React, { useState } from "react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { MessageIcon, PhoneIcon, PinIcon } from "assets/icons";
import CvDownloader from "./CvDownloader";
import { useNavigate } from "react-router-dom";

interface cvTypes {
  showCv: boolean;
  setShowCv: React.Dispatch<React.SetStateAction<boolean>>;
}

function Cv({ showCv, setShowCv }: cvTypes) {
  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const navigate = useNavigate();

  return (
    <section className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10">
      <div className="relative w-[630px] p-[30px] bg-white rounded-[15px] flex flex-col gap-[25px] text-pro_text_dark">
        <div className="flex flex-col gap-[25px] bg-home_5 bg-cover">
          {/* close button */}
          <div
            onClick={() => setShowCv(!showCv)}
            className="absolute top-[4%] right-[6%] font-Avenir_bold cursor-pointer"
          >
            X
          </div>
          {/* logo */}
          <div className="w-[100px] h-[33px]">
            <img src="images/logo.png" alt="logo" />
          </div>

          {/* Name and description */}
          <div className="w-[80%] flex flex-col gap-[5px]">
            <p className="font-Avenir_bold text-[24px]">
              {profileData?.firstName} {profileData?.lastName}
            </p>
            <p className="font-Avenir_regular text-[13px] text-[#444444]">
              {profileData?.description}
            </p>
          </div>

          {/* contact info */}
          <div className="flex items-center gap-[20px]">
            <div className="flex flex-col items-center gap-[20px] bg-pro_text_light p-[8px] rounded-[25px]">
              <img src={PhoneIcon} alt="phone" />
              <img src={MessageIcon} alt="mail" />
              <img src={PinIcon} alt="location" />
            </div>
            <div className="font-Avenir_demi text-[14px] flex flex-col gap-[20px]">
              <p>{profileData?.user?.phoneNumber}</p>
              <p>{profileData?.user?.email}</p>
              <p>{profileData?.address}</p>
            </div>
          </div>

          {/* Education  */}
          <div className="flex flex-col gap-[5px]">
            <p className="font-Avenir_bold text-[21px]">Education</p>
            <p className="font-Avenir_demi text-[16px]">
              {profileData?.university?.universityNameEn}
            </p>
            <p className="font-Avenir_regular text-[16px]">
              {profileData?.faculty?.facultyNameEn}
            </p>
          </div>

          {/* Skills  */}
          <div className="font-Avenir_demi text-[16px] flex flex-col gap-[5px] max-h-[100px] overflow-auto scroll">
            <p className="font-Avenir_bold text-[21px]">Skills</p>
            <div className="w-full flex flex-col items-start">
              {profileData?.user?.userSkills?.map((item: any) => {
                return <p>- {item?.skill?.skillNameEn}</p>;
              })}
            </div>
          </div>
        </div>

        {/* download cv button  */}
        <div className="absolute bottom-0 right-[-37%]">
          <Button
            type="button"
            onClick={() => navigate(`/cv/${profileData?.userProfileId}`)}
            bgColor="#142644"
            fontSize={16}
            px={40}
            py={8}
            textColor="#E3FF66"
            text="Download as PDF"
          />
        </div>
      </div>
    </section>
  );
}

export default Cv;
